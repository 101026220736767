import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Router, RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AppState } from './app.state';
import { FacebookModule } from 'ngx-facebook';
import { LiveChatComponent } from './shared/components/live-chat/live-chat.component';
import { ScrollTopComponent } from './shared/components/scroll-to-top/scroll-to-top.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as Sentry from '@sentry/angular-ivy';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, LiveChatComponent, ScrollTopComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    NgxsModule.forRoot(AppState),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    CarouselModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot(),
    FacebookModule.forRoot(),
    MatSelectCountryModule.forRoot('fr'),
    MatTooltipModule,
    MatIconModule,
  ],
  exports: [TranslateModule, GooglePlaceModule],
  bootstrap: [AppComponent],
  providers: [
    GoogleAnalyticsService,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
