export enum Category {
  TRICK = 'TRICK',
  DID_YOU_KNOW = 'DID_YOU_KNOW',
  SCAM = 'SCAM',
  STORYTELLING = 'STORYTELLING',
  GOOD_PLANS = 'GOOD_DEAL',
  ADVICE = 'ADVICE',
}

export enum UsefulInformationType {
  NORMAL = 'NORMAL',
  HELP = 'HELP',
}

export enum MissionCategory {
  INTEGRATION = 'INTEGRATION',
  CAREER = 'CAREER',
  PERSONALIZED = 'PERSONALIZED',
  ADMINISTRATIVES = 'ADMINISTRATIVES',
  CONTRIBUTOR = 'CONTRIBUTOR',
  CAMPUS_FRANCE_VISA = 'CAMPUS_FRANCE_VISA',
}

export enum SubCategoryInformation {
  SHOPPING = 'SHOPPING',
  TRIP = 'TRIP',
  INVESTMENT = 'INVESTMENT',
  LIFESTYLE = 'LIFESTYLE',
  HEALTH = 'HEALTH',
  WELL_BEING = 'WELL_BEING',
}

export enum referenceService {
  INTEGRATION = 'integration-integration',
  CAREER = 'Carrière',
  HOUSING = 'recherche-de-logement-housing',
  AUTOMATIC_AGENDA = 'agenda-automatique-online',
  ESPACE_PI = 'espace-pi-online',
}

export enum ModuleCategory {
  INTEGRATION = 'INTEGRATION',
  CAREER = 'CAREER',
  PERSONALIZED = 'PERSONALIZED',
  ADMINISTRATIVES = 'ADMINISTRATIVES',
  HOUSING = 'HOUSING',
  CAMPUS_FRANCE_VISA = 'CAMPUS_FRANCE_VISA',
  ONLINE = 'ONLINE',
}

export enum SubscriptionState {
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum RemunerationState {
  UPCOMING = 'UPCOMING',
  CANCELLED = 'CANCELLED',
  RECEIVED = 'RECEIVED',
}

export enum SubscriptionType {
  ONLINE = 'online',
  PACK = 'pack',
  MODULE = 'module',
}

export enum PaymentType {
  PAYPAL = 'PAYPAL',
  CB = 'BANC_ACCOUNT',
  BANC_CHECK = 'BANC_CHECK',
  OM = 'ORANGE_MONEY',
  MOMO = 'MTN_MONEY',
}

export enum ModuleSubCategory {
  BASIC_INTEGRATION = 'BASIC_INTEGRATION',
  PREMIUM_INTEGRATION = 'PREMIUM_INTEGRATION',
  BASIC_CAREER = 'BASIC_CAREER',
  PREMIUM_CAREER = 'PREMIUM_CAREER',
  ESPACE_PI = 'ESPACE_PI',
  AUTOMATIC_AGENDA = 'AUTOMATIC_AGENDA',
  CAMPUS_FRANCE_VISA_BASIC = 'CAMPUS_FRANCE_VISA_BASIC',
  ADMINISTRATIVES_INTEGRATION = 'ADMINISTRATIVES_INTEGRATION',
  HOUSING_METROPOLITAIN_BASIC = 'HOUSING_METROPOLITAIN_BASIC',
  HOUSING_METROPOLITAIN_PREMIUM = 'HOUSING_METROPOLITAIN_PREMIUM',
  HOUSING_PREMIUM = 'HOUSING_PREMIUM',
  HOUSING_BASIC = 'HOUSING_BASIC',
}

export enum Civility {
  SIR = 'SIR',
  MADAM = 'MADAM',
}

export enum FaqCategory {
  PAYMENT = 'FAQ_PAYMENT',
  REFUNDED = 'FAQ_REFUNDED',
  HOUSING = 'FAQ_HOUSING',
  CAMPUS_FRANCE_VISA = 'FAQ_CAMPUS_FRANCE_VISA',
  INTEGRATION = 'FAQ_INTEGRATION',
  CAREER = 'FAQ_CAREER',
  MENTOR = 'FAQ_MENTOR',
  COLLABORATOR = 'FAQ_COLLABORATOR',
  PARTNER = 'FAQ_PARTNER',
  OTHERS = 'FAQ_OTHERS',
  ESPACE_PI = 'FAQ_ESPACE_PI',
  AUTOMATIC_AGENDA = 'FAQ_AUTOMATIC_AGENDA',
}

export enum ProfessionalInfo {
  AGRI_FOOD = 'AGROALIMENTAIRE',
  BANK_ASSURANCE = 'BANQUE / ASSURANCE',
  FINANCE = 'FINANCE',
  DATA = 'DATA',
  WOOD_PAPER_CARTON_PRINTING = 'BOIS / PAPIER / CARTON / IMPRIMERIE',
  BTP_CONSTRUCTION_MATERIAL = 'BTP / MATÉRIAUX DE CONSTRUCTION',
  CHEMISTRY_PARACHEMISTRY = 'CHIMIE / PARACHIMIE',
  COMMERCE_NEGOTIATION_DISTRIBUTION = 'COMMERCE / NÉGOCE / DISTRIBUTION',
  EDITION_COMMUNICATION_MULTIMEDIA = 'ÉDITION / COMMUNICATION / MULTIMÉDIA',
  ELECTRONIC_ELECTRICITY = 'ÉLECTRONIQUE / ÉLECTRICITÉ',
  STUDIES_AND_ADVISES = 'ÉTUDES ET CONSEILS',
  PHARMACEUTIC_INDUSTRY = 'INDUSTRIE PHARMACEUTIQUE',
  TELECOMS = 'TÉLÉCOMS',
  DEVELOPER_DEVOPS_CLOUD = 'DÉVELOPPEUR / DEVOPS / CLOUD',
  LAW = 'DROIT',
  ECONOMY = 'ÉCONOMIE',
  MACHINES_AUTOMOBILE_EQUIPMENTS = 'MACHINES ET ÉQUIPEMENTS / AUTOMOBILE',
  METALLURGY_METAL_WORKING = 'MÉTALLURGIE / TRAVAIL DU MÉTAL',
  PLASTIC_CAOUTCHOUC = 'PLASTIQUE / CAOUTCHOUC',
  BUSINESS_SERVICES = 'SERVICES AUX ENTREPRISES',
  TEXTILE_CLOTHING_FOOTWEAR = 'TEXTILE / HABILLEMENT / CHAUSSURE',
  TRANSPORTS_LOGISTIC = 'TRANSPORTS / LOGISTIQUE',
}

export enum AcademicInfo {
  AGRI_FOOD = 'AGROALIMENTAIRE',
  BANK_ASSURANCE = 'BANQUE / ASSURANCE',
  FINANCE = 'FINANCE',
  DATA = 'DATA',
  WOOD_PAPER_CARTON_PRINTING = 'BOIS / PAPIER / CARTON / IMPRIMERIE',
  BTP_CONSTRUCTION_MATERIAL = 'BTP / MATÉRIAUX DE CONSTRUCTION',
  CHEMISTRY_PARACHEMISTRY = 'CHIMIE / PARACHIMIE',
  COMMERCE_NEGOTIATION_DISTRIBUTION = 'COMMERCE / NÉGOCE / DISTRIBUTION',
  EDITION_COMMUNICATION_MULTIMEDIA = 'ÉDITION / COMMUNICATION / MULTIMÉDIA',
  ELECTRONIC_ELECTRICITY = 'ÉLECTRONIQUE / ÉLECTRICITÉ',
  STUDIES_AND_ADVISES = 'ÉTUDES ET CONSEILS',
  PHARMACEUTIC_INDUSTRY = 'INDUSTRIE PHARMACEUTIQUE',
  TELECOMS = 'TÉLÉCOMS',
  DEVELOPER_DEVOPS_CLOUD = 'DÉVELOPPEUR / DEVOPS / CLOUD',
  LAW = 'DROIT',
  ECONOMY = 'ÉCONOMIE',
  MACHINES_AUTOMOBILE_EQUIPMENTS = 'MACHINES ET ÉQUIPEMENTS / AUTOMOBILE',
  METALLURGY_METAL_WORKING = 'MÉTALLURGIE / TRAVAIL DU MÉTAL',
  PLASTIC_CAOUTCHOUC = 'PLASTIQUE / CAOUTCHOUC',
  BUSINESS_SERVICES = 'SERVICES AUX ENTREPRISES',
  TEXTILE_CLOTHING_FOOTWEAR = 'TEXTILE / HABILLEMENT / CHAUSSURE',
  TRANSPORTS_LOGISTIC = 'TRANSPORTS / LOGISTIQUE',
}

export enum LoginProfile {
  CLIENT = 'Client',
  MENTOR = 'Mentor',
  BUSINESS_PROVIDER = "Apporteur d'affaires",
  HOUSING_RESEARCHER = 'Chasseur de logements',
}

export enum Language {
  FRENCH = 'Français',
  ENGLISH = 'ANGLAIS',
  CHINESE = 'CHINOIS',
  GERMANY = 'ALLEMAND',
}

// export enum ProductTagSlugs {
//   INTEGRATION = 'PRODUCT_INTEGRATION',
//   CAREER = 'PRODUCT_CAREER',
//   PERSONALIZED = 'PRODUCT_PERSONALIZED',
//   ADMINISTRATIVES = 'PRODUCT_ADMINISTRATIVES',
//   HOUSING = 'PRODUCT_HOUSING',
//   CAMPUS_FRANCE_VISA = 'PRODUCT_CAMPUS_FRANCE_VISA',
//   ONLINE = 'PRODUCT_ONLINE',
//   OTHERS = 'PRODUCT_OTHERS',
//   AUTOMATIC_AGENDA = 'PRODUCT_AUTOMATIC_AGENDA',
//   ESPACE_PI = 'PRODUCT_ESPACE_PI',
// }

export enum Gender {
  M = 'M',
  F = 'F',
  NOT_DEFINED = 'NOT_DEFINED',
}

export enum Languages {
  FRENCH = 'FRENCH',
  ENGLISH = 'ENGLISH',
  CHINESE = 'CHINESE',
  SPANISH = 'SPANISH',
  GERMAN = 'GERMAN',
}

export enum ProductType {
  INTEGRATION = 'INTEGRATION',
  HOUSING = 'HOUSING',
  CAMPUS_FRANCE = 'CAMPUS_FRANCE',
  HOUSING_CERTIFICATE = 'HOUSING_CERTIFICATE',
  CAREER = 'CAREER',
  AUTOMATIC_AGENDA = 'AUTOMATIC_AGENDA',
  ESPACE_PI = 'ESPACE_PI',
}
