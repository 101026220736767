<div class="container-fluid containerServiceComponent">
  <div class="container mt-5 py-5 our-services-component">
    <p class="head-title">{{ 'NosServices' | translate }}</p>
    <div *ngIf="loading" class="my-5 py-5">
      <mat-spinner class="mx-auto"></mat-spinner>
    </div>
    <div
      *ngIf="!loading"
      class="container-fluid pt-4 px-0 position-relative"
      fxLayout="column"
      fxLayoutAlign="space-between center"
    >
      <!-- start section prev button -->
      <img
        *ngIf="slideIndex > 0"
        src="assets/img/prev.png"
        class="prevIcon"
        alt="Prev"
        (click)="matgroup.selectedIndex = slideIndex - 1"
      />
      <!-- end section prev button -->
      <!-- start section next button -->
      <img
        *ngIf="slideIndex < productInfoList.length - 1"
        src="assets/img/next.png"
        class="nextIcon"
        alt="Next"
        (click)="matgroup.selectedIndex = slideIndex + 1"
      />
      <!-- end section next button -->
      <mat-tab-group
        class="container-fluid px-0"
        #matgroup
        [@.disabled]="true"
        mat-align-tabs="center"
        dynamicHeight
        [selectedIndex]="2"
        (selectedTabChange)="tabChanged($event)"
      >
        <div *ngFor="let item of productInfoList; let i = index" [attr.data-index]="i">
          <mat-tab label="{{ item.title }} ">
            <div class="container-fluid px-1 px-md-5 pt-5 pb-4">
              <div class="row px-4 pt-5">
                <div class="col-md-6">
                  <p class="fw-bold slideTitle text-60 lh-1">{{ item.title }}</p>
                  <p class="slideText pt-4">
                    {{ item.description || '' }}
                  </p>
                  <a routerLink="/services/{{ item?.referenceId || '' }}">
                    <button class="btn-pilink text-white px-2 py-3" type="button">
                      {{ 'ReadMore' | translate }}
                    </button>
                  </a>
                </div>
                <div class="col-md-6 position-relative">
                  <img
                    src="{{ item?.imageUrl || '' }}"
                    [ngClass]="{
                      'w-50': item.imageUrl.includes('slide2'),
                      'w-100': item.imageUrl.includes('slide2')
                    }"
                    class="imgSlide3 d-md-block d-none mx-auto"
                    alt="Intégration"
                  />
                </div>
              </div>
            </div>
          </mat-tab>
        </div>
      </mat-tab-group>
    </div>
    <p class="slide3TextFooter1 mt-5 fs-6 px-5">
      {{ 'TextDetailsPricing' | translate }}
      <a class="slide3TextFooter2 text-decoration-underline" [routerLink]="['/rates']">{{
        'LinkDetailsPricing' | translate
      }}</a>
    </p>
  </div>
</div>
