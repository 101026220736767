import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Category, UsefulInformationType } from '../../../enum/enum';
import { ToastrService } from 'ngx-toastr';
import { UsefulInformation } from 'src/app/core/models/useFulInformation';
import { UseFulInformationService } from 'src/app/core/services/useFulInformation.service';

@Component({
  selector: 'app-trick',
  templateUrl: './trick.component.html',
  styleUrls: ['./trick.component.scss'],
})
export class TrickComponent implements OnInit {
  p: number = 1;
  requestDone = false;
  searchTrick: string = '';
  trickList: UsefulInformation[] = [];

  constructor(
    private useFulInformationService: UseFulInformationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let queryParamsTRICK = new HttpParams();
    queryParamsTRICK = queryParamsTRICK.append('category', Category.TRICK);
    this.useFulInformationService
      .getFreeUsefulInformation(UsefulInformationType.NORMAL, Category.TRICK)
      .subscribe({
        next: (res) => this.getUseFulInformationTRICK(res),
        error: (err) => this.errorMessage(err),
        complete: () => {
          this.requestDone = true;
        },
      });
  }

  getUseFulInformationTRICK = (res: any) => {
    this.trickList = res;
  };

  onSearchTrickChange(event: any): void {
    this.useFulInformationService
      .getUsefulInformation(UsefulInformationType.NORMAL, Category.TRICK, event.target.value)
      .subscribe({
        next: (res) => this.getUseFulInformationTRICK(res),
        error: (e) => this.errorMessage(e),
      });
  }

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else if (err.status == 404) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        this.toastr.error(
          err.error.errors
            .map((x: any) => {
              if (typeof x == 'object') {
                return x?.message;
              } else {
                return x;
              }
            })
            .join(', '),
          'Erreur'
        );
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
      }
    }
  };

  onShowClick(elt: UsefulInformation) {
    let data = document.getElementById('information-' + elt.id);
    let btnLess = document.getElementById('btn-less-' + elt.id) || document.createElement('button');
    let btnMore = document.getElementById('btn-more-' + elt.id) || document.createElement('button');
    if (data?.classList.contains('show')) {
      data.classList.remove('show');
      btnLess.style.display = 'none';
      btnMore.style.display = 'block';
    } else {
      data?.classList.add('show');
      btnLess.style.display = 'block';
      btnMore.style.display = 'none';
    }
  }
}
