export class Helper {
  constructor() {}

  getRandomColor(array: any[], output: any[]) {
    array.forEach(() => {
      let color = '#';
      let letters = '0123456789ABCDEF';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      output.push(color);
    });
  }
}

export const discount = (value: any): any => {
  return value * 0.8;
};

export const removeDiscount = (value: number): number => {
  // le prix de base est déjà réduit de 20% donc,
  // pour obtenir l'ancien prix non réduit il faut faire multiplier par 1.25
  return value * 1.25;
};

export function Required(target: object, propertyKey: string) {
  Object.defineProperty(target, propertyKey, {
    get() {
      throw new Error(`Attribute ${propertyKey} is required`);
    },
    set(value) {
      Object.defineProperty(target, propertyKey, {
        value,
        writable: true,
        configurable: true,
      });
    },
    configurable: true,
  });
}
