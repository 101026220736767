import { BlogState } from "./core/components/blog/blog.state";
import { FaqState } from "./core/components/faq/faq.state";
import { RatesState } from "./core/components/rates/rates.state";
import { ProfileState } from "./user/components/common/account/account.state";
import { UserFilesState } from "./user/components/common/files/file.state";

export const AppState = [
  RatesState,
  FaqState,
  BlogState,
  ProfileState,
  UserFilesState
]
