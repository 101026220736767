import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-become-mentor-page',
  templateUrl: './become-mentor.component.html',
  styleUrls: ['./become-mentor.component.scss']
})
export class BecomeMentorComponent implements OnInit {

  userIsAuth: boolean = false;
  pageTitle: string = 'Devenir Mentor | PILINK';

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({keywords: 'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France'});
    this.meta.addTag({description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`});
    if(localStorage.getItem('access_token')){
      this.userIsAuth = true;
    }
  }

}
