export const environment = {
  name: 'production',
  production: true,
  apiUrl: 'https://api.v2.pi-link.fr',
  logger: true,
  stripePublishableKey:
    'pk_live_51LkA77Fzyr8oulmVLQEVoUlNSNbDW1cyku7PEDL29KoAbHUD81EAmiQ5rN5hZa14x3AdZpOMAmA9kGDwmvi4gvNV00FKxuVORu',
  supabaseUrl: 'https://mwuwxihpaombmrrstujq.supabase.co',
  supabaseKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im13dXd4aWhwYW9tYm1ycnN0dWpxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODc5NTYwMzIsImV4cCI6MjAwMzUzMjAzMn0.wsVVcnX-9uB-tMmU7c2Biob0jXoiS5M4MJLkqeoVe6w',
  googleAnalytics: 'G-GR6MELXR2S',
  sentryDsn:
    'https://a354373c92e88822ed1e674cedddf0d7:2da36771c4f2854206ba06c1d96fce73@o4505942321332224.ingest.sentry.io/4505942321397760',
};
