import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import AOS from 'aos';
import { GoogleAnalyticsService } from './google-analytics.service';
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  imgs: any[] = [];

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private _$gaService: GoogleAnalyticsService
  ) {
    this.translateService.addLangs(['fr', 'en']);
    this.translateService.setDefaultLang('fr');
  }

  handleRouteEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(
          '-'
        );

        this.titleService.setTitle(title);
        console.log('Sending event');
        this._$gaService.logPageViewEvent(
          title,
          event.urlAfterRedirects,
          this.document.location.href
        );
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }

  ngOnInit(): void {
    this._$gaService.init();
    this.handleRouteEvents();
    AOS.init();
    this.pload(
      '../assets/img/african-business.jpg',
      '../assets/img/bgAbout.jpg',
      '../assets/img/blog_page_1.jpg',
      '../assets/img/blog_page_2.jpg',
      '../assets/img/blog_page_header.jpg',
      '../assets/img/blog1.jpg',
      '../assets/img/blog2.jpg',
      '../assets/img/blog3.jpg',
      '../assets/img/blog4.jpg',
      '../assets/img/blog5.jpg',
      '../assets/img/caucasian-man-black-suit-office.jpg',
      '../assets/img/check-list.png',
      '../assets/img/communication.png',
      '../assets/img/eiffel-tower.png',
      '../assets/img/happy-woman.jpg',
      '../assets/img/header-blog.jpg',
      '../assets/img/header-home.jpg',
      '../assets/img/header.jpg',
      '../assets/img/heroEspacePi.png',
      '../assets/img/homeTrustPerson1.png',
      '../assets/img/homeTrustPerson2.png',
      '../assets/img/homeTrustPerson3.png',
      '../assets/img/homeTrustPerson4.png',
      '../assets/img/homeTrustPerson5.png',
      '../assets/img/kyc 2.png',
      '../assets/img/landing.png',
      '../assets/img/logement.png',
      '../assets/img/logo-pilink.png',
      '../assets/img/mentor.jpg',
      '../assets/img/pilink-footer.png',
      '../assets/img/pilink-logo.svg',
      '../assets/img/registrationAccompany.png',
      '../assets/img/registrationHouseHunter.png',
      '../assets/img/registrationBusinessContributor.png',
      '../assets/img/registrationMentor.png',
      '../assets/img/registrationVisitor.png',
      '../assets/img/chasseur-immo.jpg'
    );
  }

  pload(...args: any[]): void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
    }
  }
}
