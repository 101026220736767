<div class="container-fluid containerServiceComponent">
  <div class="container mt-5 py-5 our-services-component">
    <p class="head-title">{{ 'Retours Clients' | translate }}</p>
    <div class="mt-5">
      <carousel [showIndicators]="true" [isAnimated]="true">
        <slide
          class="content d-flex flex-column justify-content-center pt-5 pb-5"
          *ngFor="let item of feedbackInfoList"
        >
          <div class="container-fluid" style="width: 70%">
            <div class="d-flex flex-column justify-content-center">
              <div>
                <div class="d-flex justify-content-center">
                  <ngx-avatar [size]="80" [src]="'assets/img/man-avatar.png'"></ngx-avatar>
                </div>

                <div class="d-flex justify-content-center mt-3">
                  <div class="star-rating">
                    <i class="fa fa-star fa-xs" *ngFor="let star of evaluateStars(item.rate)"></i>
                    <i class="fa fa-star-half fa-xs" *ngIf="hasHalfStar(item.rate)"></i>
                  </div>
                </div>
                <div class="row pl-5 pr-5">
                  <p class="slideText pt-4">
                    {{ item.remarks || '' }}
                  </p>
                </div>

                <div>
                  <p class="fw-bold slideTitle lh-1">{{ item.name }}</p>
                  <p class="slideTitle text-30 lh-1">{{ item.role }}</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</div>
