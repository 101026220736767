import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Faq } from '../models/faq';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient, private translate: TranslateService) {}

  getFaqs(search?: string) {
    const queryParams = new HttpParams();
    if (search) queryParams.append('search', search);
    return this.http.get<Faq[]>(`${this.apiURL}/faq`, { params: queryParams });
  }
}
