<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div class="tabcontent" *ngIf="requestDone">
  <div
    class="pb-3 {{ indexOfelement === trickList.length - 1 ? 'blurField' : '' }}"
    *ngFor="let information of trickList; let indexOfelement = index"
  >
    <p class="service">{{ information.title }}</p>
    <p
      [attr.id]="'information-' + information.id"
      class="service-about {{ indexOfelement === trickList.length - 1 ? 'blurField' : '' }}"
      [class.hideText]="content.scrollHeight > 75"
      #content
    >
      {{ information.description }}
    </p>
    <button
      [attr.id]="'btn-more-' + information.id"
      *ngIf="content.scrollHeight > 75 && indexOfelement < trickList.length - 1"
      class="show-button rounded"
      (click)="onShowClick(information)"
    >
      Afficher plus
      <i class="ms-2 bi bi-chevron-down" style="color: inherit"></i>
    </button>
    <button
      [attr.id]="'btn-less-' + information.id"
      *ngIf="content.scrollHeight > 75 && indexOfelement < trickList.length - 1"
      class="show-button rounded"
      style="display: none"
      (click)="onShowClick(information)"
    >
      Afficher moins
      <i class="ms-2 bi bi-chevron-up" style="color: inherit"></i>
    </button>
  </div>
  <!-- <div class="pt-4" *ngIf="trickList.length > 0 && requestDone">
    <pagination-controls
      class="custom-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div> -->
  <div class="text-center p-5" *ngIf="trickList.length === 0 && requestDone">
    <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
    <p class="mb-0 fs-6">{{ 'noResultsText' | translate }}</p>
  </div>
</div>
