import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  apiURL = environment.apiUrl;
  //apiURL: string = 'http://localhost:3000/api';

  constructor(private http: HttpClient, private translate: TranslateService) {}

  /**
   * elle permet envoyer un mail de reinitialisation
   * @param email adresse email de l'utilisateur
   * @returns
   */
  sendMail(email: string) {
    return this.http.post(`${this.apiURL}/auth/forgot-password`, { email });
  }

  /**
   * cette fonction permet de confirmer ou pas si le code recu par mail est correct
   * @param code represente le code envoye a l'utilisateur
   * @returns
   */
  sendCode(object: any) {
    let api = `${this.apiURL}/auth/verify-password-reset-code/user`;
    if (this.translate?.currentLang) {
      api = `${this.apiURL}/auth/verify-password-reset-code/user?lang=${
        this.translate.currentLang ?? this.translate.defaultLang
      }`;
    }
    return this.http.post(api, object);
  }

  /**
   * Cette fonction permet d'enregistrer le nouveau mot de passe
   * @param codePassword contient le code et le nouveau password
   * @returns
   */
  resetPassword(codePassword: any) {
    let api = `${this.apiURL}/auth/reset-password/user`;
    if (this.translate?.currentLang) {
      api = `${this.apiURL}/auth/reset-password/user?lang=${
        this.translate.currentLang ?? this.translate.defaultLang
      }`;
    }
    return this.http.post(api, codePassword);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
