import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { ProductInfo } from 'src/app/core/models/productInfo';
import { RatesService } from 'src/app/core/services/rates.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServicesComponent implements OnInit {
  productInfoList: ProductInfo[] = [];
  loading = true;
  slideIndex = 2;

  constructor(private toastr: ToastrService, private rateService: RatesService) {}

  ngOnInit(): void {
    this.rateService.getAlProductInfo().subscribe({
      next: (infos) => {
        this.productInfoList = infos;
        this.loading = false;
      },
      error: (e) => this.errorMessage(e),
    });
  }

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        this.toastr.error(
          err.error.errors
            .map((x: any) => {
              if (typeof x == 'object') {
                return x?.message;
              } else {
                return x;
              }
            })
            .join(', '),
          'Erreur'
        );
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
      }
    }
  };

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.slideIndex = tabChangeEvent.index;
  }
}
