import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogPageComponent } from './components/blog-page/blog-page.component';
import { EspacePiComponent } from './components/espace-pi/espace-pi.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { RatesComponent } from './components/rates/rates.component';
import { FaqComponent } from './components/faq/faq.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { CoreComponent } from './core.component';
import { BecomeMentorComponent } from './components/become-mentor/become-mentor.component';
import { NotFoundComponent } from '../shared/components/not-found/not-found.component';
import { GoodPlansComponent } from './components/espace-pi/good-plans/good-plans.component';
import { ScamComponent } from './components/espace-pi/scam/scam.component';
import { FeedbackComponent } from './components/espace-pi/feedback/feedback.component';
import { DidYouKnowComponent } from './components/espace-pi/did-you-know/did-you-know.component';
import { TabsMentionsComponent } from '../shared/components/tabs-mentions/tabs-mentions.component';
import { AuthDeactivateGuard } from '../authentication/guards/auth-deactivate.guard';
import { HelpComponent } from './components/espace-pi/help/help.component';
import { TrickComponent } from './components/espace-pi/trick/trick.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, canActivate: [AuthDeactivateGuard] },
      { path: 'blog', component: BlogComponent },
      { path: 'blogpage/:id', component: BlogPageComponent },
      {
        path: 'espace-pi',
        component: EspacePiComponent,
        canActivate: [AuthDeactivateGuard],
        children: [
          { path: '', redirectTo: 'did-you-know', pathMatch: 'full' },
          { path: 'did-you-know', component: DidYouKnowComponent },
          { path: 'good-plans', component: GoodPlansComponent },
          { path: 'scam', component: ScamComponent },
          { path: 'feedback', component: FeedbackComponent },
          { path: 'trick', component: TrickComponent },
          { path: 'help', component: HelpComponent },
        ],
      },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'forgotPassword', component: ForgotPasswordComponent },
      { path: 'reset-password/:code', component: ResetPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'rates', component: RatesComponent },
      { path: 'legal-notices', component: TabsMentionsComponent },
      { path: 'cgv-cgu', component: TabsMentionsComponent },
      { path: 'charte-rgpd', component: TabsMentionsComponent },
      { path: 'become-mentor', component: BecomeMentorComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'services', component: OurServicesComponent },
      { path: 'services/:labelIndexTab', component: OurServicesComponent },
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
