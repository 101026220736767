<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div class="tabcontent" *ngIf="requestDone">
  <div
    class="pb-3 {{ indexOfelement === scamList.length - 1 ? 'blurField' : '' }}"
    *ngFor="
      let information of scamList | paginate : { itemsPerPage: 5, currentPage: p };
      let indexOfelement = index
    "
  >
    <p class="service">{{ information.title }}</p>
    <div
      [attr.id]="'information-' + information.id"
      class="service-about"
      [class.hideText]="content.scrollHeight > 75"
      #content
    >
      <span>{{ information.description }}</span
      ><br />
      <ng-template [ngIf]="information?.antiscam || information?.usefulLink">
        <p *ngIf="information?.antiscam && information?.antiscam !== ''">
          <span class="fw-bold">Anti Scam : </span>
          <span>{{ information.antiscam }}</span>
        </p>
        <ul class="ps-0" *ngIf="information?.usefulLink && (information?.usefulLink)!.length > 0">
          <ng-container>
            <span class="fw-bold text-decoration-underline">Liens utiles : </span>
          </ng-container>
          <li class="ms-5" *ngFor="let link of information.usefulLink">
            <a [href]="link" target="_blank">{{ link }}</a>
          </li>
        </ul>
      </ng-template>
    </div>
    <button
      [attr.id]="'btn-more-' + information.id"
      *ngIf="content.scrollHeight > 75 && indexOfelement < scamList.length - 1"
      class="show-button rounded"
      (click)="onShowClick(information)"
    >
      Afficher plus
      <i class="ms-2 bi bi-chevron-down" style="color: inherit"></i>
    </button>
    <button
      [attr.id]="'btn-less-' + information.id"
      *ngIf="content.scrollHeight > 75 && indexOfelement < scamList.length - 1"
      class="show-button rounded"
      style="display: none"
      (click)="onShowClick(information)"
    >
      Afficher moins
      <i class="ms-2 bi bi-chevron-up" style="color: inherit"></i>
    </button>
    <br />
  </div>
  <!-- <div class="pt-4" *ngIf="scamList.length > 0 && requestDone">
    <pagination-controls
      class="custom-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div> -->
  <div class="text-center p-5" *ngIf="scamList.length === 0 && requestDone">
    <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
    <p class="mb-0 fs-6">{{ 'noResultsText' | translate }}</p>
  </div>
</div>
