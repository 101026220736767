import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

import { UsefulInformation } from '../models/useFulInformation';
import { Category, UsefulInformationType } from '../enum/enum';

@Injectable({
  providedIn: 'root',
})
export class UseFulInformationService {
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient, private translate: TranslateService) {}
  getFreeUsefulInformation(type: UsefulInformationType, category?: Category) {
    return this.http.get<UsefulInformation[]>(`${this.apiURL}/useful-information/free`, {
      params: category ? { type, category } : { type },
    });
  }

  getUsefulInformation(type: UsefulInformationType, category?: Category, search?: string) {
    const params: any = {};
    if (category) params.category = category;
    params.search = search ?? '';
    params.type = type;

    return this.http.get<UsefulInformation[]>(`${this.apiURL}/useful-information`, {
      params,
    });
  }
}
