<div class="container-fluid tab-policies" fxLayout="column" fxLayoutAlign="space-between center">
  <mat-tab-group class="container-fluid" [@.disabled]="true" mat-align-tabs="center" dynamicHeight
                 [selectedIndex]="indexTabs">
    <mat-tab label="{{ 'LegalNotices' | translate }}">
      <app-legal-mentions></app-legal-mentions>
    </mat-tab>
    <mat-tab label="CGU">
      <app-cgu></app-cgu>
    </mat-tab>
    <mat-tab label="CGV">
      <app-cgv></app-cgv>
    </mat-tab>
    <mat-tab label="{{ 'rgpdCharter' | translate }}">
      <app-charte-rgpd></app-charte-rgpd>
    </mat-tab>
  </mat-tab-group>
  <div class="lets-go-policy">
    <div class="container text-center">
      <p class="text-go text-60 text-white">
        Vous souhaitez obtenir plus de détails concernant nos politiques de confidentialité ?
      </p>
      <p class="go-condition mb-5 text-white">
        Cliquez sur le bouton contactez-nous et laissez votre message
      </p>
      <div class="space-go">
        <a [routerLink]="'/contact-us'" class="btn d-block rounded-0">{{ 'ContactezNous' | translate }}</a>
      </div>
    </div>
  </div>
</div>
