import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private _router: Router) {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e: any) => {
        gtag('js', new Date());
        gtag('config', environment.googleAnalytics);
      });
  }

  init() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const gtagEl = document.createElement('script');
    const gtagBody = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    `);
    gtagEl.appendChild(gtagBody);
    document.body.appendChild(gtagEl);
  }

  logPageViewEvent(pageTitle: string, pagePath: string, pageLocation: string) {
    gtag('event', 'page_view', {
      page_title: pageTitle,
      page_path: pagePath,
      page_location: pageLocation,
    });

    console.log('gtag event captured...');
  }
}
