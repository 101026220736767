import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  pageTitle: string = 'Accueil | PILINK';

  descriptionMentor = `Vous avez une bonne connaissance de votre ville, vous êtes disponible, et vous aimeriez aider vos pairs à
  mieux s'intégrer à leur vie académique, sociale et professionnelle tout en étant rémunéré ? Devenez Mentor
  PILINK.`;

  titre = 'Commençons dès maintenant !';

  sousTitre = 'Connectez-vous et faites-vous accompagner';

  libelleButon = 'Commencer';

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({keywords: 'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France'});
    this.meta.addTag({description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`});
  }
}
