import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { RatesService } from '../../services/rates.service';
import { getAllProducts as getAllProducts, getAllPacks } from './rates.action';
import { Product } from '../../models/product';
import { Pack } from '../../models/pack';

export class RatesStateModel {
  products: Product[] | undefined;
  packs: Pack[] | undefined;
}

@State<RatesStateModel>({
  name: 'rateState',
  defaults: {
    products: [],
    packs: [],
  },
})
@Injectable()
export class RatesState {
  constructor(private rateService: RatesService) {}

  @Selector()
  static selectProductsData(state: RatesStateModel) {
    return state.products;
  }

  @Selector()
  static selectPacksData(state: RatesStateModel) {
    return state.packs;
  }

  @Action(getAllProducts)
  getProductsFromState(ctx: StateContext<RatesStateModel>) {
    return this.rateService.getAllProducts().pipe(
      tap((productsData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          products: productsData,
        });
      })
    );
  }

  @Action(getAllPacks)
  getPacksFromState(ctx: StateContext<RatesStateModel>) {
    return this.rateService.getAllPacks().pipe(
      tap((packsData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          packs: packsData,
        });
      })
    );
  }
}
