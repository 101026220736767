import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Faq } from '../../models/faq';
import { FaqService } from '../../services/faq.service';
import { Store, Select } from '@ngxs/store';
import { FaqState } from './faq.state';
import { getAllFaqs } from './faq.action';
import { Title, Meta } from '@angular/platform-browser';
import { FaqCategory } from '../../enum/enum';
import { map } from 'lodash';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  pageTitle: string = 'FAQ | PILINK';
  search: string = '';
  FaqList: Faq[] = [];
  FaqStaticList: Faq[] = [];
  requestDone = false;
  p: number = 1;
  categorySelect = 'All';
  sortCategory = 'Filtrer par catégorie';
  categoryList = [
    { key: FaqCategory.COLLABORATOR, name: 'Plateforme' },
    { key: FaqCategory.PAYMENT, name: 'Paiement' },
    { key: FaqCategory.REFUNDED, name: 'Remboursement' },
    { key: FaqCategory.HOUSING, name: 'Service Logement' },
    { key: FaqCategory.CAMPUS_FRANCE_VISA, name: 'Service Campus France/Visa' },
    { key: FaqCategory.INTEGRATION, name: 'Service Intégration' },
    { key: FaqCategory.CAREER, name: 'Service Carrière' },
    { key: FaqCategory.MENTOR, name: 'Mentor' },
    { key: FaqCategory.PARTNER, name: 'Partenariat' },
    { key: FaqCategory.ESPACE_PI, name: 'Espace PI' },
    { key: FaqCategory.AUTOMATIC_AGENDA, name: 'Agenda automatique' },
    { key: FaqCategory.OTHERS, name: 'Autres' },
  ];

  @Select(FaqState.selectFaqData) faqs$: Observable<Faq[]> | undefined;

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private faqService: FaqService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({
      description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`,
    });
    this.store.dispatch(new getAllFaqs()).subscribe(() => {
      this.faqs$?.subscribe((response) => {
        this.getAll(response);
      });
    });

    console.log(this.categoryList);
  }

  checkItem(value: any) {
    this.categorySelect = value;
    if (this.categorySelect === 'All') {
      this.sortCategory = 'Tous';
      this.FaqList = this.FaqStaticList;
    } else {
      this.FaqList = this.filterFaqs(this.FaqStaticList, value);
      this.sortCategory =
        this.categoryList.find((x) => x.key == this.categorySelect)?.name || 'Filtrer par...';
    }
  }

  onSearchChange(event: any): void {
    this.faqService.getFaqs(event.target.value).subscribe({
      next: (data) => this.getAll(data),
      error: (e) => this.errorMessage(e),
    });
  }

  getAll = (data: Faq[]) => {
    this.FaqStaticList = data;
    if (this.categorySelect && this.categorySelect !== 'All') {
      this.FaqList = this.filterFaqs(this.FaqStaticList, this.categorySelect);
    } else {
      this.FaqList = data;
    }
    this.requestDone = true;
  };

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        this.toastr.error(
          err.error.errors
            .map((x: any) => {
              if (typeof x == 'object') {
                return x?.message;
              } else {
                return x;
              }
            })
            .join(', '),
          'Erreur'
        );
      } else {
        this.toastr.error(err.error?.message, 'Erreur');
      }
    }
  };

  private filterFaqs(faqs: Faq[], slug: string) {
    return this.FaqStaticList.filter((faq) => map(faq.tags, (tag) => tag.slug).includes(slug));
  }
}
