<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div class="tabcontent" *ngIf="requestDone">
  <div
    class="pb-3 {{ indexOfelement === tipList.length - 1 ? 'blurField' : '' }}"
    *ngFor="let information of tipList; let indexOfelement = index"
  >
    <div class="info row">
      <div class="img-side col-4">
        <img src="{{ information?.imageUrl }}" alt="Img" *ngIf="requestDone; else noImageFound" />
        <ng-template #noImageFound>
          <img src="assets/img/sorry.svg" alt="Fallbackimage" style="width: 8rem" />
        </ng-template>
      </div>
      <div class="text-side col-8">
        <p class="service">
          {{ information.title }}
        </p>
        <div
          [attr.id]="'information-' + information.id"
          class="service-about"
          [class.hideText]="content.scrollHeight > 75"
          #content
        >
          <span>{{ information.description }}</span>
          <!-- <ng-template [ngIf]="information?.usefulLinks">
            <ul class="ps-0" *ngIf="information?.usefulLinks && information?.usefulLinks!.length > 0">
              <ng-container>
                <span class="fw-bold">Liens utiles : </span>
              </ng-container>
              <li class="ms-5" *ngFor="let link of information.usefulLinks">
                <a [href]="link" target="_blank">{{ link }}</a>
              </li>
            </ul>
          </ng-template> -->
        </div>
        <button
          [attr.id]="'btn-more-' + information.id"
          *ngIf="content.scrollHeight > 75 && indexOfelement < tipList.length - 1"
          class="show-button rounded"
          (click)="onShowClick(information)"
        >
          Afficher plus
          <i class="ms-2 bi bi-chevron-down" style="color: inherit"></i>
        </button>
        <button
          [attr.id]="'btn-less-' + information.id"
          *ngIf="content.scrollHeight > 75 && indexOfelement < tipList.length - 1"
          class="show-button rounded"
          style="display: none"
          (click)="onShowClick(information)"
        >
          Afficher moins
          <i class="ms-2 bi bi-chevron-up" style="color: inherit"></i>
        </button>
      </div>
    </div>
    <div class="d-flex py-2 pe-lg-6">
      <a [href]="information.usefulLink" class="btn btn-login">{{ "J'en profite" | translate }} </a>
    </div>
    <br />
  </div>
  <!-- <div class="pt-4" *ngIf="tipList.length > 0 && requestDone">
    <pagination-controls
      class="custom-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div> -->
  <div class="text-center p-5" *ngIf="tipList.length === 0 && requestDone">
    <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
    <p class="mb-0 fs-6">{{ 'noResultsText' | translate }}</p>
  </div>
</div>
