import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Blog } from '../../models/blog';
import { BlogService } from '../../services/blog.service';
import { Store, Select } from '@ngxs/store';
import { BlogState } from './blog.state';
import { getAllBlogElements } from './blog.action';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  pageTitle: string = 'Blog | PILINK';
  search: string = '';
  BlogList: Blog[] = [];
  monthNames = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ];
  requestDone = false;
  @Select(BlogState.selectBlogData) blogList$: Observable<Blog[]> | undefined;

  constructor(
    private store: Store,
    private blogService: BlogService,
    private toastr: ToastrService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({ description: `Blog elements` });

    this.store.dispatch(new getAllBlogElements()).subscribe(() => {
      this.blogList$?.subscribe({ next: this.finishFetchingBlogs });
    });
  }

  formatDate(dateString?: string) {
    if (dateString) {
      let date = new Date(dateString);
      return `${date.getDate()} ${this.monthNames[date.getMonth()]} ${date.getFullYear()}`;
    } else {
      return '';
    }
  }

  onSearchChange(event: any): void {
    this.blogService.getBlogs(event.target.value).subscribe({
      next: (res) => this.finishFetchingBlogs(res),
      error: (e) => this.errorMessage(e),
    });
  }

  finishFetchingBlogs = (blogs: Blog[]) => {
    this.BlogList = blogs;
    this.requestDone = true;
  };

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        this.toastr.error(
          err.error.errors
            .map((x: any) => {
              if (typeof x == 'object') {
                return x?.message;
              } else {
                return x;
              }
            })
            .join(', '),
          'Erreur'
        );
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
      }
    }
  };
}
