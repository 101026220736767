import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabs-mentions',
  templateUrl: './tabs-mentions.component.html',
  styleUrls: ['./tabs-mentions.component.scss']
})
export class TabsMentionsComponent implements OnInit {

  pageTitle: string = 'PILINK | Politique de sécurité';
  indexTabs: number | undefined;

  constructor(private router: Router, private title: Title, private meta: Meta) { }

  ngOnInit(): void {

    this.title.setTitle(this.pageTitle);
    this.meta.addTag({keywords: 'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France'});
    this.meta.addTag({description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`});

    if ((this.router.url).indexOf( "/legal-notices" ) === 0) {
      this.indexTabs = 0;
    } else if((this.router.url).indexOf( "/cgv-cgu" ) === 0) {
      this.indexTabs = 1;
    }
    else {
      this.indexTabs = 0;
    }

  }

}
