import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { getAllProducts, getAllPacks } from './rates.action';
import { RatesState } from './rates.state';
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { discount, removeDiscount } from '../../helper';
import { Product } from '../../models/product';
import { Pack } from '../../models/pack';
import { ProductType } from '../../enum/enum';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss'],
})
export class RatesComponent implements OnInit {
  pageTitle: string = 'Tarifs | PILINK';
  toEurAgenda = false;
  toEuroConnection = false;
  toEurInt = false;
  toEurCarreer = false;
  toEurPacks = false;
  toEurLocation = false;
  toEurVisa = false;
  requestModulesDone = false;
  requestPacksDone = false;

  careerList: Product[] = [];
  integrationList: Product[] = [];
  campusFranceVisaList: Product[] = [];
  housingBasicProductList: Product[] = [];
  housingPremiumProductList: Product[] = [];
  espacePI?: Product;
  automaticAgenda?: Product;

  packsList: Pack[] = [];

  sectionSelected: any;

  @Select(RatesState.selectProductsData) productsList$: Observable<Product[]> | undefined;
  @Select(RatesState.selectPacksData) packsList$: Observable<Pack[]> | undefined;

  constructor(
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private sharedService: SharedService,
    private title: Title,
    private meta: Meta
  ) {
    this.sectionSelected = this.router.getCurrentNavigation()!.extras.state;
    console.log('section selected', this.sectionSelected);
  }

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({
      description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`,
    });

    forkJoin([
      this.store.dispatch(new getAllProducts()),
      this.store.dispatch(new getAllPacks()),
    ]).subscribe({
      complete: () => this.getAll(),
      error: (e) => this.errorMessage(e),
    });
  }

  reducer(val: any): any {
    return discount(val);
  }

  nonDiscountedPrice(val: number): number {
    return removeDiscount(val);
  }

  roundingNumber(price: any) {
    return Number(price.toPrecision(4));
  }

  getAll(): void {
    this.productsList$?.subscribe({
      next: (products) => {
        this.automaticAgenda = products.find((p) => p.type === ProductType.AUTOMATIC_AGENDA);

        this.espacePI = products.find((p) => p.type === ProductType.ESPACE_PI);

        this.integrationList = products.filter((p) => p.type === ProductType.INTEGRATION);

        this.careerList = products.filter((p) => p.type === ProductType.CAREER);

        this.campusFranceVisaList = products.filter((p) => p.type === ProductType.CAMPUS_FRANCE);

        this.housingBasicProductList = products.filter(
          (p) =>
            [ProductType.HOUSING, ProductType.HOUSING_CERTIFICATE].includes(p.type) && !p.isPremium
        );

        this.housingPremiumProductList = products.filter(
          (p) =>
            [ProductType.HOUSING, ProductType.HOUSING_CERTIFICATE].includes(p.type) && p.isPremium
        );

        this.requestModulesDone = true;
      },
      error: (err) => {
        this.toastr.error(err?.message || 'Une erreur est survenue.', 'Erreur');
      },
    });

    this.packsList$?.subscribe({
      next: (packs) => {
        this.packsList = packs;
        this.requestPacksDone = true;
      },
      error: (err) => {
        this.toastr.error(err?.message || 'Une erreur est survenue.', 'Erreur');
      },
    });
  }

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        let errorMessage = err.error.errors
          .map((x: any) => {
            if (typeof x == 'object') {
              return x?.message;
            } else {
              return x;
            }
          })
          .join(', ');
        this.toastr.error(errorMessage, 'Erreur');
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: errorMessage,
        });
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
        if (err.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
            footer: `<a routerLink="/authentication/login">Veuillez-vous reconnecter</a>`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
          });
        }
      }
    }
  };

  subscribe(object: any) {
    const token = this.authService.getToken();
    if (token) {
      this.router.navigate([`user/purchase-service`]);
    } else {
      localStorage.setItem('current_subscribe', this.sharedService.encryptJson(object));
      this.router.navigate([`authentication/login`]);
    }
  }

  onCheckEurAgenda(event: any) {
    this.toEurAgenda = event.target.checked;
  }

  onCheckEurConnection(event: any) {
    this.toEuroConnection = event.target.checked;
  }

  onCheckEurInt(event: any) {
    this.toEurInt = event.target.checked;
  }

  onCheckEurVisa(event: any) {
    this.toEurVisa = event.target.checked;
  }

  onCheckEurCarreer(event: any) {
    this.toEurCarreer = event.target.checked;
  }

  onCheckEurPack(event: any) {
    this.toEurPacks = event.target.checked;
  }

  onCheckEurLocation(event: any) {
    this.toEurLocation = event.target.checked;
  }

  // changeDevise(price: number): number {
  //   return Math.round((price + (2 * price) / 100) * 655.5);
  // }
}
