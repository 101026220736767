<div class="container cgv px-3 py-5">
  <p class="text-60 title">{{ 'cgvText' | translate }} (CGV)</p>
  <ol>
    <li class="fw-bold">Présentation</li>
    <ng-container>
      <p>
        PILINK SAS est une société innovante française pionnière dans le domaine de l'intégration
        sociale, dont le siège social est établi au 40 Avenue du 8 Mai 1945, 94260 Fresnes.
      </p>
      <p>
        PILINK SAS propose des prestations diverses notamment : l'accompagnement carrière ,
        recherche de logement, accompagnement campus France/visa, espace pi et intégration.
      </p>
    </ng-container>
    <li class="fw-bold">Objet</li>
    <ng-container>
      <p>
        Les présentes conditions générales de vente s'appliquent à toutes les prestations énumérées à
        l'article 1 délivrées par PILINK SAS.
      </p>
      <p>
        Le fait de souscrire à un service, notamment via le site internet, implique l'adhésion entière et
        sans réserve du client aux présentes conditions générales de vente.
      </p>
      <p>
        Toute condition contraire et notamment toute condition générale ou particulière opposée par le
        client ne peut, sauf acceptation formelle et écrite de PILINK SAS, prévaloir sur les présentes
        conditions et ce, quel que soit le moment où elle aura pu être portée à sa connaissance.
      </p>
      <p>
        Le client se porte fort du respect des présentes conditions, dans le cas éventuel d'une personne
        morale elle garantit le respect des conditions générales de vente par l'ensemble de ses
        souscripteurs.
      </p>
      <p>
        Le client reconnaît également que, préalablement à toute commande, il a bénéficié des
        informations et conseils suffisants de la part de PILINK SAS lui permettant de s'assurer de
        l'adéquation de l'offre de services à ses besoins.
      </p>
    </ng-container>
    <li class="fw-bold">Documents contractuels</li>
    <ng-container>
      <p>
        Les présentes conditions générales viennent fixer le cadre générique des services délivrées par
        PILINK SAS qui seront complétés par des accords spécifiques selon le service souscrits.
      </p>
      <p>
        Ceci dit, les Conditions Générales, qui constituent l'accord entre les Parties, est intégralement
        et exclusivement composé par :
      </p>
      <ol>
        <li>
          Le présent document qui comporte les stipulations générales applicables à toutes les
          prestations délivrées par PILINK SAS ;
        </li>
        <li>
          Les conditions générales spécifiques applicable au service souscrit :
        </li>
        <ng-container>
          <ul>
            <li>
              Conditions générales de ventes campus France/ visa
            </li>
            <li>
              Conditions générales de vente recherche logement
            </li>
            <li>
              Conditions générales de vente intégration
            </li>
            <li>
              Conditions générales de vente espace pi
            </li>
            <li>
              Conditions générales de vente Accompagnent carrière
            </li>
          </ul>
        </ng-container>
      </ol>
    </ng-container>
    <li class="fw-bold">Souscription</li>
    <ng-container>
      <p>
        Toute souscription nécessite la création d'un compte utilisateur, et le règlement de la prestation.
      </p>
      <p>
        PILINK SAS doit être avisé des modalités spécifiques de prise en charge des frais du service et
        en tout état de cause avant le démarrage de la prestation. Il en va de même des délais de paiement
        pratiqués par le client.
      </p>
    </ng-container>
    <li class="fw-bold">Validation de la souscription</li>
    <ng-container>
      <p>
        Vous déclarez avoir pris connaissance et accepté les présentes Conditions générales de vente
        avant la finalisation de votre souscription. La validation de votre commande vaut donc
        acceptation de ces conditions générales de vente.
      </p>
      <p>
        Un récapitulatif des informations de votre souscription et des Conditions Générales de Ventes,
        vous sera communiqué en format PDF via l'e-mail de confirmation de votre commande.
      </p>
      <p>
        En acceptant les présentes conditions, vous acceptez que votre facture soit mise à votre
        disposition de manière dématérialisée dans votre espace client.
      </p>
    </ng-container>
    <li class="fw-bold">Prix et paiement</li>
    <ng-container>
      <p>
        6.1 - Les prix de nos services sont indiqués en euros et en Franc CFA toutes taxes comprises
      </p>
      <p>
        6.2 - PILINK SAS se réserve le droit de modifier ses prix à tout moment mais les services seront
        facturés sur la base des tarifs en vigueur au moment de votre validation de commande.
      </p>
      <p>
        Le paiement se fait via le moyen choisi sur le site web. Le paiement ne sera réputé effectué que
        lorsque PILINK SAS aura reçu des fonds disponibles relativement au montant total mentionné
        sur la commande.
      </p>
      <p>
        PILINK SAS accepte les paiements par Visa, Mastercard, Solo, Maestro, Électron PayPal,
        Orange money et MTN mobile money
      </p>
    </ng-container>
    <li class="fw-bold">Défaut de paiement</li>
    <ng-container>
      <p>
        En cas de défaut de paiement, PILINK SAS se réserve le droit de suspendre la prestation et les
        accès au site du souscripteur jusqu'à régularisation de l'impayé.
      </p>
      <p>
        A de défaut de régularisation dans le délai imparti de 30 jours après envoi de la facture, une
        lettre de rappel amiable est envoyée au débiteur. Lorsque la créance n'a pu être recouvrée à
        l'amiable, PILINK SAS se réserve la possibilité de qui procéder au recouvrement contentieux
      </p>
    </ng-container>
    <li class="fw-bold">Obligations respectives des parties</li>
    <ng-container>
      <p class="fw-bold">8.1 Les obligations de PILINK SAS</p>
      <ul>
        <li class="text-uppercase">obligations liées à la fourniture du service</li>
        <ng-container>
          <p>
            PILINK SAS s'engage à rendre accessible aux souscripteurs à tout moment et en tout état
            de cause avant toute inscription, un certain nombre d'informations précontractuelles, en
            particulier les informations prévues par le Code de la consommation (pour les contrats
            conclus à distance en vertu de l'article L221-5) à savoir : le contenu de l'offre ; la durée de
            la prestation ; les modalités de suivi ; et garanties possibles;
          </p>
        </ng-container>
        <li class="text-uppercase">obligations liées à l'exécution des prestations</li>
        <ng-container>
          <p>
            PILINK SAS s'engage à réaliser toutes les actions prévues lors de la souscription : Il
            s'engage à exécuter la prestation, en cohérence avec les objectifs préalablement
            déterminés. Il est tenu de mettre en œuvre les moyens organisationnelles et techniques
            nécessaires à la réalisation de la prestation.
          </p>
          <p>
            PILINK SAS s'engage à fournir la prestation avec toute la diligence et soin raisonnables
            dans les règles de l'art. Etant soumis à une obligation de moyens, Il ne sera tenu
            responsable vis-à-vis du souscripteur qu'en cas de faute prouvée et uniquement pour les
            dommages directs résultant d'une mauvaise exécution de ses prestations de la prestation,
            à l'exclusion de tout dommage indirect.
          </p>
          <p>En tout état de cause, PILINK SAS est soumis à une obligation de moyens.</p>
        </ng-container>
      </ul>
      <p class="fw-bold">8.2 les obligations du souscripteur</p>
      <p>
        Le souscripteur s'engage à payer le prix de la prestation. Il accepte le règlement intérieur et les
        conditions d'utilisation du site de PILINK SAS consultable via ce lien :
        <a href="https://pi-link.fr/legal-notices" target="_blank">https://pi-link.fr/legal-notices</a>
      </p>
    </ng-container>
    <li class="fw-bold">Droit de rétractation</li>
    <ng-container>
      <p>
        Le souscripteur est informé qu'un droit de rétractation s'applique en principe aux contrats de
        prestations de services conclus à distance entre un professionnel et un consommateur, ce droit
        étant à exercer dans les 14 (quatorze) jours à compter de sa signature du présent contrat. Il peut
        exercer ce droit en adressant à PILINK SAS aux coordonnées mentionnées au présent contrat,
        avant l'expiration du délai susvisé, le formulaire figurant en annexe du présent contrat, dûment
        complété, ou toute autre déclaration, dénuée d'ambiguïté, exprimant sa volonté de se rétracter.
      </p>
      <p>
        En cas d'exercice du droit de rétractation, PILINK remboursera à l'Etudiant tous les paiements
        reçus de celui-ci, sans retard excessif et, en tout état de cause, au plus tard 14 (quatorze) jours
        à compter du jour où PILINK aura été informée de la décision de rétractation de l'Etudiant. Le
        remboursement sera effectué en utilisant le même moyen de paiement que celui utilisé pour la
        transaction initiale, sauf si l'Etudiant convient expressément d'un moyen différent. En tout état
        de cause, ce remboursement n'occasionnera pas de frais pour l'Etudiant.
      </p>
      <p>
        Le souscripteur est toutefois expressément informé et accepte que s'il a demandé à ce que la
        prestation lui soient fournies dès la signature du présent contrat et sont pleinement exécutées
        avant la fin du délai de rétractation visé ci-dessus, PILINK SAS aura alors pleinement exécuté
        ses prestations relatives à cet accompagnement avant la fin du délai de rétractation. En
        conséquence, le souscripteur renonce expressément, dans cette hypothèse, à son droit de
        rétractation, qui ne pourra donc pas être exercé, conformément à l'article L.222-9 du Code de
        la consommation.
      </p>
    </ng-container>
    <li class="fw-bold">Résiliation</li>
    <ng-container>
      <p>
        Le présent contrat pourra être résilié par anticipation et de plein droit, par l'une ou l'autre des
        parties, en cas d'inexécution de l'une quelconque des obligations, sans qu'il ne soit nécessaire
        de le faire constater par un juge. Cette résiliation anticipée interviendra quinze (15) jours après
        mise en demeure signifiée par lettre recommandée avec demande d'avis de réception (ou
        équivalent international) ou par lettre remise en main propre contre décharge à la Partie
        défaillante indiquant les faits reprochés et l'intention de faire application de la présente clause,
        restée sans effet pendant quinze (15) jours.
      </p>
      <p>
        En tout état de cause, la Partie lésée pourra demander en justice l'octroi de dommages et intérêts.
      </p>
    </ng-container>
    <li class="fw-bold">Confidentialité</li>
    <ng-container>
      <p>
        Chacune des parties s'engage à garder strictement confidentiels tous les documents et
        informations de nature juridique, commerciale, stratégique, technique relatifs à l'autre Partie
        ou détenus par celle-ci dont elle aurait eu connaissance à l'occasion de la conclusion et de
        l'exécution du présent contrat et à ne pas les divulguer sans l'accord écrit préalable de l'autre
        Partie.
      </p>
      <p>
        Cette obligation ne s'étend pas aux documents et informations :
      </p>
      <p>
        (i) dont la Partie qui les reçoit avait déjà connaissance ;
      </p>
      <p>
        (ii) déjà publics lors de leur communication ou qui le deviendraient sans violation du présent accord ;
      </p>
      <p>
        (iii) qui auraient été reçus d'un tiers de manière licite ;
      </p>
      <p>
        (iv) dont la communication serait exigée par les autorités judiciaires, en application des lois et
        règlements ou en vue d'établir les droits d'une Partie au titre du présent contrat.
      </p>
      <p>
        Celle-ci continuera à produire ses effets pendant les cinq (5) ans suivant la fin des relations
        entre les Parties.
      </p>
    </ng-container>
    <li class="fw-bold">Protection des données à caractère personnel</li>
    <ng-container>
      <p>
        Le traitement de vos données à caractère personnel est régi par notre politique de confidentialité
        conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016
        (« <span class="fw-bold">RGPD</span> »).
      </p>
      <p>
        Vous disposez d'un droit d'accès à vos données personnelles, de rectification et d'effacement,
        de limitation de traitement, d'opposition dans les conditions prévues par la réglementation
        applicable, à l'adresse suivante :
      </p>
      <p>
        Vous disposez également du droit d'introduire une réclamation auprès de la Commission
        Nationale de l'Informatique et des Libertés (CNIL), autorité de contrôle en charge du respect
        des obligations en matière de données à caractère personnel.
      </p>
    </ng-container>
    <li class="fw-bold">Contentieux</li>
    <ng-container>
      <p>
        La possibilité est offerte aux stagiaires ou aux clients de solliciter le médiateur de PILINK SAS
        à l'aide de l'adresse courriel : 40 Avenue du 8 Mai 1945, 94260 Fresnes
      </p>
      <p>
        A défaut de résolution amiable, tout litige de toute nature ou toute contestation relative à une
        action de formation ou à l'exécution de la commande sera soumis au tribunal de Lyon
        compétent. La responsabilité de PILINK SAS vis-à-vis du client ne saurait excéder en totalité
        le montant payé par le client au titre des présentes conditions.
      </p>
    </ng-container>
    <li class="fw-bold">Droit applicable - Attribution de compétence</li>
    <ng-container>
      <p>
        Les présentes conditions générales sont régies par le droit français. En cas de litige survenant
        entre le client et PILINK SAS à l'occasion de l'exécution du contrat, il sera recherché une
        solution à l'amiable et, à défaut, le règlement sera du ressort du tribunal compétent de Fresnes.
      </p>
    </ng-container>
    <li class="fw-bold">Force majeure</li>
    <ng-container>
      <p>
        La responsabilité du prestataire ne peut pas être mise en cause si le défaut d'exécution ou le
        retard dans l'exécution de l'une de ses obligations décrites dans les présentes CGV découle d'un
        cas de force majeure entendue de tout événement extérieur, imprévisible et irrésistible au sens
        de l'article 1148 du Code civil. Sont considérés comme cas de force majeure ou cas fortuit,
        outre ceux habituellement reconnus par la jurisprudence des cours et tribunaux français et sans
        que cette liste soit restrictive : la maladie ou l'accident d'un consultant ou d'un animateur de
        formation, les grèves ou conflits sociaux internes ou externes à PILINK SAS , les désastres
        naturels, les incendies, l'interruption des télécommunications, l'interruption de
        l'approvisionnement en énergie, l'interruption des communications ou des transports de tout
        type, événements de guerre déclarés ou non déclarés, de grève générale de travail, de maladies épidémiques, de mise en quarantaine, d'incendie, de crues exceptionnelles, d'accidents ou autres
        événements indépendants de la volonté des deux parties.
      </p>
    </ng-container>
    <li class="fw-bold">Divers</li>
    <ng-container>
      <p>
        Les présentes conditions expriment l'intégralité des obligations du client ainsi que de celles de
        PILINK SAS. Il se réserve le droit de modifier unilatéralement les termes des présentes, les
        conditions applicables étant celles en vigueur à la date de souscription . Dans l'hypothèse où
        l'une des dispositions des présentes conditions serait considérée nulle en vertu d'une disposition
        légale ou réglementaire, présente ou future, ou d'une décision de justice revêtue de l'autorité
        de la chose jugée et émanant d'une juridiction ou d'un organisme compétent, cette disposition
        du contrat serait alors réputée non écrite, toutes les autres dispositions des présentes conditions
        conservant force obligatoire entre les Parties
      </p>
    </ng-container>
  </ol>
</div>
