<div id="error-page">
  <div class="content">
    <h2 class="header" data-text="404">
      404
    </h2>
    <h4 class="px-3 lh-md fw-bold" data-text="Oups ! Page non trouvée">
      Oups ! Page non trouvée
    </h4>
    <p class="px-3 lh-md">
      Désolé, la page que vous recherchez n'existe pas.
    </p>
    <div class="btn-space">
      <a [routerLink]="['/']">retourner à l'accueil</a>
    </div>
  </div>
</div>
