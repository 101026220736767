import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../../user/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { HelpDialogDetailsComponent } from './help-dialog-details/help-dialog-details.component';
import { HelpService } from 'src/app/core/services/help.service';
import { UseFulInformationService } from 'src/app/core/services/useFulInformation.service';
import { UsefulInformation } from 'src/app/core/models/useFulInformation';
import { UsefulInformationType } from 'src/app/core/enum/enum';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  p: number = 1;
  helpList: UsefulInformation[] = [];
  requestDone: boolean = false;
  constructor(
    private dialog: MatDialog,
    private useFulInformationService: UseFulInformationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.useFulInformationService.getFreeUsefulInformation(UsefulInformationType.HELP).subscribe({
      next: (res: any) => {
        this.helpList = res;
      },
      error: (e) => this.errorMessage(e),
      complete: () => {
        this.requestDone = true;
      },
    });
  }

  openDialogDetails(element: any): void {
    this.dialog.open(HelpDialogDetailsComponent, {
      data: {
        restrictions: element,
      },
    });
  }

  onShowClick(elt: any) {
    let data = document.getElementById('information-' + elt._id);
    let btnLess =
      document.getElementById('btn-less-' + elt._id) || document.createElement('button');
    let btnMore =
      document.getElementById('btn-more-' + elt._id) || document.createElement('button');
    if (data?.classList.contains('show')) {
      data.classList.remove('show');
      btnLess.style.display = 'none';
      btnMore.style.display = 'block';
    } else {
      data?.classList.add('show');
      btnLess.style.display = 'block';
      btnMore.style.display = 'none';
    }
  }

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else if (err.status == 404) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        this.toastr.error(
          err.error.errors
            .map((x: any) => {
              if (typeof x == 'object') {
                return x?.message;
              } else {
                return x;
              }
            })
            .join(', '),
          'Erreur'
        );
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
      }
    }
  };
}
