import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordService } from '../../services/reset-password.service';
import { Title, Meta } from '@angular/platform-browser';
import { SharedService } from 'src/app/shared/services/shared.service';
import Swal from 'sweetalert2';
import { ShowHideInputDirective } from 'src/app/shared/directives/show-hide-input.directive';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { AuthState } from 'src/app/authentication/typings';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  pageTitle: string = 'Réinitialisation du mot de passe | PILINK';

  codeSet = { code: '' };
  resetForm!: FormGroup;
  code: any;
  pattern: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_])([-+!*$@%_\w]{8,})$/;
  loaderBtn = false;
  validation = false;
  showPwd = false;
  showConfirmPwd = false;
  submitted = false;

  // reset link verification
  resetLinkVerified = false;
  resetLinkVerificationLoading = true;

  @ViewChildren(ShowHideInputDirective) public inputs!: QueryList<ShowHideInputDirective>;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private sharedService: SharedService,
    public formBuilder: FormBuilder,
    public resetService: ResetPasswordService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta
  ) {
    this.resetForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.-^*()%!~£_\w])([$&+,:;=?@#|'<>.-^*()%!~£_\w]{8,})$/
            ),
          ],
        ],
        newpassword: ['', [Validators.required]],
      },
      { validator: this.checkPasswords }
    );
  }

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({
      description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`,
    });

    // wait for supasbase to retrive session from user that is being updated
    this.authService.authState.subscribe((state) => {
      console.log(state);
      switch (state) {
        case AuthState.LOADING:
          this.resetLinkVerificationLoading = true;
          break;
        case AuthState.AUHTENTICATED:
          this.resetLinkVerified = true;
          this.resetLinkVerificationLoading = false;
          break;
        case AuthState.NOT_AUTHENTICATED:
          this.resetLinkVerified = false;
          this.resetLinkVerificationLoading = false;
          break;
      }
    });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.newpassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  toggleShowPwd() {
    this.showPwd = !this.showPwd;
    if (this.showPwd) {
      this.inputs.first.changeType('text');
    } else {
      this.inputs.first.changeType('password');
    }
  }

  toggleShowConfirmPwd() {
    this.showConfirmPwd = !this.showConfirmPwd;
    if (this.showConfirmPwd) {
      this.inputs.last.changeType('text');
    } else {
      this.inputs.last.changeType('password');
    }
  }

  get f() {
    return this.resetForm.controls;
  }

  /**
   * permet de reinitialiser le mot de passe
   */
  async resetPassword() {
    this.submitted = true;
    if (this.resetForm.valid) {
      this.loaderBtn = true;
      let mail: any = localStorage.getItem('mail')
        ? this.sharedService.decryptJson(localStorage.getItem('mail'))
        : null;
      const { data, error } = await this.authService.updatePassword(this.resetForm.value.password);
      if (error) {
        this.errorMessage(error);
      }
      if (data) {
        this.loginComplete();
      }
      this.completeRequest();
    } else {
      this.toastr.warning('Veuillez correctement remplir les champs', 'Attention !');
    }
  }

  loginComplete = () => {
    this.toastr.success('Votre mot de passe a été réinitialisé avec succès.', 'Succès');
    this.resetForm.reset();
    this.router.navigate(['/authentication/login']);
  };

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        let errorMessage = err.error.errors
          .map((x: any) => {
            if (typeof x == 'object') {
              return x?.message;
            } else {
              return x;
            }
          })
          .join(', ');
        this.toastr.error(errorMessage, 'Erreur');
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: errorMessage,
        });
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
        if (err.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
            footer: `<a routerLink="/authentication/login">Veuillez-vous reconnecter</a>`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
          });
        }
      }
    }
  };

  completeRequest = () => {
    this.loaderBtn = false;
  };
}
