<section class="container-fluid d-flex align-items-center banner-about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-10">
        <p class="banner-label text-60">
          {{ 'About.BannerLabelText' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<section class="container py-5 about-us">
  <p class="label-whoweare">{{ 'About.text' | translate }}</p>
  <div class="container">
    <div class="left-block" data-aos="fade-left" data-aos-duration="1000">
      <p class="text-whoweare text-60 text-justify">
        {{ 'About.SectionA.textA' | translate }}
      </p>
      <p class="details-whoweare text-justify">
        {{ 'About.SectionA.textB' | translate }}
      </p>
    </div>
    <div class="container-grid d-md-grid" data-aos="fade-right" data-aos-duration="1000">
      <div class="item item-1"></div>
      <div class="item item-2"></div>
      <div class="item item-3"></div>
      <div class="item item-4"></div>
      <div class="item item-5"></div>
      <div class="item item-6"></div>
    </div>
  </div>
</section>
<div class="line-purple"></div>
<section class="container-fluid our-missions">
  <p class="label-our-missions text-60 pt-5">{{ 'About.SectionOurMissions.text' | translate }}</p>
  <div class="container">
    <div class="row d-md-flex justify-content-center mt-5">
      <div class="col-lg-4 my-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="card rounded-0 border-0 p-3 bg-light shadow">
          <div class="card-body">
            <img src="assets/img/career.png" width="150" height="150" alt="career image" />
            <p class="mission-title text-left text-dark pt-3">
              {{ 'About.SectionOurMissions.cardA.title' | translate }}
            </p>
            <p class="mission-desc">
              {{ 'About.SectionOurMissions.cardA.description' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 my-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="card rounded-0 border-0 p-3 bg-purple">
          <div class="card-body">
            <img src="assets/img/passport.png" width="150" height="150" alt="passport image" />
            <p class="mission-title text-left text-white pt-3">
              {{ 'About.SectionOurMissions.cardB.title' | translate }}
            </p>
            <p class="mission-desc text-white">
              {{ 'About.SectionOurMissions.cardB.description' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 my-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="card rounded-0 border-0 p-3 bg-light shadow">
          <div class="card-body">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-house-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"
              />
              <path
                d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"
              />
            </svg>
            <p class="mission-title text-left text-dark pt-3">
              {{ 'About.SectionOurMissions.cardC.title' | translate }}
            </p>
            <p class="mission-desc">
              {{ 'About.SectionOurMissions.cardC.description' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 my-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="card rounded-0 border-0 p-3 bg-purple">
          <div class="card-body">
            <img src="assets/img/handshake.png" width="150" height="150" alt="handshake image" />
            <p class="mission-title text-left text-white pt-3">
              {{ 'About.SectionOurMissions.cardD.title' | translate }}
            </p>
            <p class="mission-desc text-white">
              {{ 'About.SectionOurMissions.cardD.description' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 my-2" data-aos="fade-up" data-aos-duration="1000">
        <div class="card rounded-0 border-0 p-3 bg-light shadow">
          <div class="card-body">
            <img src="assets/img/puzzle.svg" width="150" height="150" alt="puzzle image" />
            <p class="mission-title text-left pt-3">
              {{ 'About.SectionOurMissions.cardE.title' | translate }}
            </p>
            <p class="mission-desc pt-3">
              {{ 'About.SectionOurMissions.cardE.description' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid our-values mt-5">
  <p class="label-our-values text-60 pt-5">{{ 'About.SectionOurValues.text' | translate }}</p>
  <div class="container">
    <div class="row" data-aos="fade-up" data-aos-duration="1000">
      <div class="col-md-3 text-center">
        <p class="value-number">01</p>
      </div>
      <div class="col-md-6">
        <p class="label-value">{{ 'About.SectionOurValues.first.title' | translate }}</p>
        <p class="value-desc">
          {{ 'About.SectionOurValues.first.content' | translate }}
        </p>
      </div>
    </div>
    <div
      class="row d-md-flex justify-content-md-center pt-5"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div class="col-md-3">
        <p class="value-number text-center">02</p>
      </div>
      <div class="col-md-6">
        <p class="label-value">{{ 'About.SectionOurValues.second.title' | translate }}</p>
        <p class="value-desc">
          {{ 'About.SectionOurValues.second.content' | translate }}
        </p>
      </div>
    </div>
    <div
      class="row d-md-flex justify-content-md-end pt-5"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div class="col-md-3">
        <p class="value-number text-center">03</p>
      </div>
      <div class="col-md-6">
        <p class="label-value">{{ 'About.SectionOurValues.third.title' | translate }}</p>
        <p class="value-desc">
          {{ 'About.SectionOurValues.third.content' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<section class="container our-team pt-5 pb-2">
  <p class="label-our-team">{{ 'About.SectionTeam.OurTeamText' | translate }}</p>
  <div class="container">
    <p class="text-meet-team text-60 pt-3">{{ 'About.SectionTeam.MeetOurTeamText' | translate }}</p>
  </div>
  <swiper
    #swiperRef
    [slidesPerView]="numberPreviewSlide"
    [spaceBetween]="30"
    [slidesPerGroup]="numberPreviewSlide"
    [loop]="false"
    [loopFillGroupWithBlank]="true"
    [pagination]="{
      clickable: true
    }"
    [navigation]="true"
    class="swiperCard py-5"
  >
    <ng-template *ngFor="let p of people" swiperSlide>
      <div class="card cardSlide">
        <img src="{{ p.image ?? defaultImage(p.gender) }}" class="card-img-top imgCard" alt="..." />
        <div class="card-body px-4 pb-5">
          <p class="fw-bold fs-4 titleCard lh-1">{{ p.fullName }}</p>
          <p class="card-text textCardSlide">{{ p.role }}</p>
        </div>
      </div>
    </ng-template>
  </swiper>
</section>
<app-become-mentor
  [entete]="entete"
  [descriptionMentor]="descriptionMentor"
  [libelleButon]="libelleButon"
  [titre]="titre"
>
</app-become-mentor>
