<app-main-banner></app-main-banner>
<app-trust-persons></app-trust-persons>
<app-services></app-services>
<app-clients-feedback></app-clients-feedback>
<app-become-mentor
  [descriptionMentor]="descriptionMentor"
  [libelleButon]="libelleButon"
  [titre]="titre"
  [sousTitre]="sousTitre"
>
</app-become-mentor>
