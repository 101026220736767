<div *ngIf="!validation" class="justify-content-around" id="container">
  <form [formGroup]="forgotForm" (ngSubmit)="forgotPassword()">
    <div class="container-fluid text-center">
      <div class="row justify-content-around form-group pb-3">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <mat-form-field class="w-100 mt-5" appearance="outline">
            <mat-label class="fw-bolder">E-mail</mat-label>
            <input matInput type="email" formControlName="email" name="email" id="email" required />
          </mat-form-field>
          <div *ngIf="email.invalid && (email.dirty || email.touched)">
            <p *ngIf="email.errors?.['required']" class="text-danger">
              {{ 'requiredFieldText' | translate }}
            </p>
            <p *ngIf="email.value && email.invalid" class="text-danger">
              {{ 'EmailInvalidText' | translate }}
            </p>
          </div>
          <br />
          <small class="textFormatLogin text-black fs-6 form-text text-muted">
            {{ 'ForgotPwdTextDirective' | translate }}
          </small>
        </div>
      </div>
      <br />
      <div class="row justify-content-around">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <button type="submit" class="btn bg-violet rounded-0 text-white textFormatLogin py-2">
            {{ 'ConfirmText' | translate }} <span *ngIf="loaderBtn">&nbsp;</span>
            <span
              *ngIf="loaderBtn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            >
            </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<section *ngIf="validation" class="section text-center">
  <label class="mb-3 size">
    <strong>
      {{ 'ForgotPwdTextSucceedProcess' | translate }}
    </strong>
  </label>
  <p>
    {{ 'ForgotPwdTextSucceedProcessDirective' | translate }}
  </p>
  <p class="mt-5">{{ 'DontReceiveAnyMailText' | translate }}</p>
  <button
    class="textFormatLogin mb-4 p-10 btn bg-violet text-white btn-lg"
    style="border-radius: 0"
    (click)="forgotPassword()"
    role="button"
    aria-pressed="true"
  >
    {{ 'ResendMailText' | translate }}
  </button>
</section>
