import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ProspectsService } from '../../services/prospects.service';
import { Title, Meta } from '@angular/platform-browser';
import { IndicatorCountry } from 'src/app/authentication/register/interface/indicatorCountry';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProfileState } from 'src/app/user/components/common/account/account.state';
import { getProfileUser } from 'src/app/user/components/common/account/account.action';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  validationForm: FormGroup;
  send = false;
  errors = false;
  exist = false;
  pageTitle: string = 'Contact | PILINK';
  formatted_addr = '';
  indicatorList: any[] = [];
  countryCodeSelected: string = '';
  indicatorSelected!: IndicatorCountry;

  @Select(ProfileState.fetchProfileData) profileData$: Observable<any> | undefined;

  constructor(
    private prospectsService: ProspectsService,
    private toastr: ToastrService,
    private authService: AuthService,
    private entitle: Title,
    private meta: Meta,
    private store: Store
  ) {
    this.validationForm = new FormGroup({
      firstName: new FormControl(null, { validators: Validators.required, updateOn: 'submit' }),
      lastName: new FormControl(null, { validators: Validators.required, updateOn: 'submit' }),
      title: new FormControl(null, { validators: Validators.required, updateOn: 'submit' }),
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
        updateOn: 'submit',
      }),
      phoneNumber: new FormControl(null, {
        validators: Validators.pattern('[- +()0-9]+'),
        updateOn: 'submit',
      }),
      message: new FormControl(null, { updateOn: 'submit' }),
      address: new FormControl(null, { validators: Validators.required, updateOn: 'submit' }),
    });
  }

  ngOnInit(): void {
    this.entitle.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({
      description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`,
    });

    this.indicatorList = this.authService.getIndicatorCountries();

    if (
      localStorage.getItem('access_token') != null &&
      localStorage.getItem('currentUser') != null
    ) {
      this.store.dispatch(new getProfileUser()).subscribe({
        next: () => {
          this.profileData$?.subscribe({
            next: (res) => {
              this.countryCodeSelected = res?.phoneNumber?.countryCode;
              this.validationForm.patchValue({
                lastName: res?.lastName,
                firstName: res?.firstName,
                phoneNumber: res?.phoneNumber?.value,
                email: res?.email?.value,
                address: res?.address,
              });
              this.indicatorSelected = this.indicatorList.find(
                (el) => el.dialCode == this.countryCodeSelected
              );
            },
            error: (e) => this.errorMessage(e),
          });
        },
        error: (e) => this.errorMessage(e),
      });
    } else {
      this.countryCodeSelected = '+33';
      this.indicatorSelected = this.indicatorList.find(
        (el) => el.dialCode == this.countryCodeSelected
      );
    }
  }

  get form(): any {
    return this.validationForm.controls;
  }

  get firstName(): AbstractControl {
    return this.validationForm.get('firstName')!;
  }
  get title(): AbstractControl {
    return this.validationForm.get('title')!;
  }

  get lastName(): AbstractControl {
    return this.validationForm.get('lastName')!;
  }

  get email(): AbstractControl {
    return this.validationForm.get('email')!;
  }

  get phoneNumber(): AbstractControl {
    return this.validationForm.get('phoneNumber')!;
  }

  get address(): AbstractControl {
    return this.validationForm.get('address')!;
  }

  checkAddress(address: any): void {
    this.validationForm.patchValue({
      address: address.formatted_address,
    });
    this.formatted_addr = address.formatted_address;
  }

  changeIndicatorCountry(value: IndicatorCountry) {
    this.indicatorSelected = value;
  }

  onSubmit(): void {
    this.validationForm.value.phoneNumber = String(this.validationForm.value.phoneNumber);
    console.log(this.validationForm.value);

    this.validationForm.markAllAsTouched();
    if (this.validationForm.valid) {
      this.send = true;
      this.prospectsService
        .sendProspects({
          address: this.validationForm.value.address,
          email: this.validationForm.value.email,
          firstName: this.validationForm.value.firstName,
          lastName: this.validationForm.value.lastName,
          title: this.validationForm.value.title,
          phoneNumber: this.validationForm.value.phoneNumber,
          message: this.validationForm.value.message,
        })
        .subscribe({
          next: (res) => this.sendMessageContactUs(res),
          error: (e) => {
            this.errorMessage(e);
            this.send = false;
          },
          complete: () => {
            this.send = false;
          },
        });
    } else {
      this.errors = true;
      this.send = false;
    }
  }

  sendMessageContactUs = (res: any) => {
    this.errors = false;
    this.send = true;
    this.validationForm.reset();
    Swal.fire({
      icon: 'success',
      text: 'Votre demande a bien été réçue. Nous vous contacterons dans les plus bref délais',
    });
  };

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        let errorMessage = err.error.errors
          .map((x: any) => {
            if (typeof x == 'object') {
              return x?.message;
            } else {
              return x;
            }
          })
          .join(', ');
        this.toastr.error(errorMessage, 'Erreur');
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: errorMessage,
        });
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
        if (err.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
            footer: `<a routerLink="/authentication/login">Veuillez vous reconnecter</a>`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
          });
        }
      }
    }
  };
}
