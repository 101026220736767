import { Component, ViewEncapsulation, OnInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import SwiperCore, { Keyboard, Pagination, Navigation, Virtual } from 'swiper';
SwiperCore.use([Keyboard, Pagination, Navigation, Virtual]);

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutUsComponent implements OnInit {
  pageTitle: string = 'A propos | PILINK';
  getScreenWidth: any;
  numberPreviewSlide = 3;
  entete = 'Rejoignez-nous !';
  descriptionMentor = `Vous avez une bonne connaissance de votre ville, vous êtes disponible, et vous aimeriez aider vos pairs à
  mieux s'intégrer à leur vie académique, sociale et professionnelle tout en étant rémunéré ? Devenez Mentor
  PILINK.`;
  titre = 'Contactez-nous dès maintenant !';
  libelleButon = 'Commencer';

  people: { role: string; fullName: string; image?: string; gender: 'M' | 'F' }[] = [
    {
      fullName: 'Steve ELANGA',
      role: 'Responsable des données (Associé)',
      image: 'assets/img/team/steve.jpg',
      gender: 'M',
    },
    {
      fullName: 'Philip TCHATCHOUA',
      role: 'Responsable des opérations (Associé)',
      image: 'assets/img/team/Philip.jpg',
      gender: 'M',
    },

    {
      fullName: 'Serge DEUGOUE',
      role: 'Directeur Technique (Associé)',
      image: 'assets/img/team/Photo_Serge_Deugoue.jpg',
      gender: 'M',
    },
    {
      fullName: 'Yann PADJI',
      role: 'Responsable relation clients (Associé)',
      image: 'assets/img/team/yann.jpg',
      gender: 'M',
    },
    {
      fullName: 'Estella TCHAKOUNTÉ',
      role: 'Responsable compliance (Associé)',
      image: 'assets/img/team/Estella_Tchakounte.jpg',
      gender: 'F',
    },
    {
      fullName: 'Roch Durand NDJIGI',
      role: 'Responsable Partenariats (Associé)',
      image: 'assets/img/team/durand_bilong.jpg',
      gender: 'M',
    },
    {
      fullName: 'Pascal WAFO',
      role: 'Responsable Digitalisation (Associé)',
      image: 'assets/img/team/pascal_wafo.jpg',
      gender: 'M',
    },
  ];

  defaultImage(gender: 'M' | 'F') {
    switch (gender) {
      case 'M':
        return 'assets/img/man-avatar.png';
      case 'F':
        return 'assets/img/woman-avatar.png';
    }
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth < 576) {
      this.numberPreviewSlide = 1;
    } else if (this.getScreenWidth <= 768) {
      this.numberPreviewSlide = 2;
    } else if (this.getScreenWidth <= 992) {
      this.numberPreviewSlide = 3;
    } else if (this.getScreenWidth <= 1200) {
      this.numberPreviewSlide = 3;
    } else {
      this.numberPreviewSlide = 3;
    }
  }

  constructor(private title: Title, private meta: Meta) {}

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({
      description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`,
    });

    this.getScreenWidth = window.innerWidth;
    this.onWindowResize();
  }
}
