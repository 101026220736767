import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { UserService } from 'src/app/user/services/user.service';
import {
  addFormationInformation,
  addLifeStyle,
  addProfessionnalInformation,
  deleteFormationInformation,
  deleteProfessionnalInformation,
  getLifeStyle,
  getProfileUser,
  setFormationInformation,
  setLifeStyle,
  SetLoaded,
  SetLoading,
  setProfessionnalInformation,
  setProfileUser,
} from './account.action';

export class ProfileStateModel {
  userGeneralInfo: any;
  userLyfeStyle: any;
  userProfessionnalInformation: any;
  userFormationInformation: any;
  userMentorInformation: any;
  loading: any;
  loaded: any;
  role: any;
}

@State<ProfileStateModel>({
  name: 'profileState',
  defaults: {
    userGeneralInfo: null,
    userLyfeStyle: null,
    userProfessionnalInformation: [],
    userFormationInformation: null,
    userMentorInformation: null,
    loading: true,
    loaded: false,
    role: null,
  },
})
@Injectable()
export class ProfileState {
  constructor(private userService: UserService) {}

  @Selector()
  static fetchProfileData(state: ProfileStateModel) {
    return state.userGeneralInfo;
  }

  @Selector()
  static getUserStatus(state: ProfileStateModel) {
    return state.role;
  }

  @Selector()
  static fetchLyfeStyleData(state: ProfileStateModel) {
    return state.userLyfeStyle;
  }

  @Selector()
  static fetchProfessionnalInformationData(state: ProfileStateModel) {
    return state.userProfessionnalInformation;
  }

  @Selector()
  static fetchMentorInformationData(state: ProfileStateModel) {
    return state.userMentorInformation;
  }

  @Selector()
  static fetchFormationInformationData(state: ProfileStateModel) {
    return state.userFormationInformation;
  }

  @Selector()
  static loading(state: ProfileStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: ProfileStateModel) {
    return state.loaded;
  }

  @Action(SetLoading)
  setLoading({ patchState }: StateContext<ProfileStateModel>) {
    patchState({
      // userGeneralInfo: null,
      // userLyfeStyle: null,
      // userProfessionnalInformation: [],
      // userFormationInformation: null,
      loaded: false,
      loading: true,
    });
  }

  @Action(SetLoaded)
  setLoaded({ patchState }: StateContext<ProfileStateModel>, action: SetLoaded<any>) {
    patchState({
      userGeneralInfo: action.payload?.generalInfo,
      userLyfeStyle: action.payload?.lyfeStyle,
      userProfessionnalInformation: action.payload?.professionnalInformation,
      userFormationInformation: action.payload?.formationInformation,
      userMentorInformation: action.payload?.mentorInformation,
      role: action.payload?.role,
      loaded: true,
      loading: false,
    });
  }

  @Action(getProfileUser)
  getProfileInfoFromState(ctx: StateContext<ProfileStateModel>) {
    return this.userService.getProfileWithDetailsByIdUser().pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap((userProfileData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          userGeneralInfo: userProfileData.data.generalInfo,
          userLyfeStyle: userProfileData.data.lyfeStyle,
          userProfessionnalInformation: userProfileData.data.professionnalInformation,
          userFormationInformation: userProfileData.data.formationInformation,
          userMentorInformation: userProfileData.data.mentorInformation,
          role: userProfileData.data.role,
          loaded: true,
          loading: false,
        });

        ctx.dispatch(new SetLoaded(userProfileData.data));
      })
    );
  }

  @Action(deleteProfessionnalInformation)
  deleteProfessionalInfoFromState(
    ctx: StateContext<ProfileStateModel>,
    { id }: deleteProfessionnalInformation
  ) {
    return this.userService.deleteProfessionnalInformation(id).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap((res) => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(setProfileUser)
  setUserInfoFromState(ctx: StateContext<ProfileStateModel>, { id, payload }: setProfileUser) {
    return this.userService.updateGeneralInfos(id, payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(setLifeStyle)
  setLifeStyleInfoFromState(ctx: StateContext<ProfileStateModel>, { id, payload }: setLifeStyle) {
    return this.userService.updateLifeStyle(id, payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(addLifeStyle)
  addLifeStyleInfoFromState(ctx: StateContext<ProfileStateModel>, { payload }: addLifeStyle) {
    return this.userService.addLifeStyle(payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        const state = ctx.getState();
        let userLyfeStyle = { ...state.userLyfeStyle };
        userLyfeStyle = payload;
        ctx.setState({
          ...state,
          userLyfeStyle: userLyfeStyle,
          loaded: true,
          loading: false,
        });
        ctx.dispatch(new SetLoaded({ lyfeStyle: userLyfeStyle }));
      })
    );
  }

  @Action(addProfessionnalInformation)
  addProfessionnalInformationFromState(
    ctx: StateContext<ProfileStateModel>,
    { payload }: addProfessionnalInformation
  ) {
    return this.userService.addProfessionnalInformation(payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(addFormationInformation)
  addFormationInformationFromState(
    ctx: StateContext<ProfileStateModel>,
    { payload }: addFormationInformation
  ) {
    return this.userService.addFormationInformation(payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(setProfessionnalInformation)
  setProfessionnalInformationFromState(
    ctx: StateContext<ProfileStateModel>,
    { id, payload }: setProfessionnalInformation
  ) {
    return this.userService.updateProfessionnalInformation(id, payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(setFormationInformation)
  setFormationInformationFromState(
    ctx: StateContext<ProfileStateModel>,
    { id, payload }: setFormationInformation
  ) {
    return this.userService.updateFormationInformation(id, payload).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap(() => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }

  @Action(deleteFormationInformation)
  deleteFormationInfoFromState(
    ctx: StateContext<ProfileStateModel>,
    { id }: deleteFormationInformation
  ) {
    return this.userService.deleteFormationInformation(id).pipe(
      tap(() => {
        ctx.dispatch(new SetLoading());
      }),
      tap((res) => {
        ctx.dispatch(new getProfileUser());
      })
    );
  }
}
