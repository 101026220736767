import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import indicatorCountries from '../../shared/dataCountries.json';
import { SupabaseClient, User, createClient } from '@supabase/supabase-js';
import { AuthState, UserPermisson } from '../typings';
import { MentorServicePreference } from '../register/interface/mentorServicePreference';
import { UserRole } from 'src/app/core/models/userRole';
import { MarketingOrigin } from '../register/interface/marketingOrigin.ts';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint: string = 'http://localhost:3000/api';
  apiURL = environment.apiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser: BehaviorSubject<User | boolean> = new BehaviorSubject<User | boolean>(false);
  authState: BehaviorSubject<AuthState> = new BehaviorSubject<AuthState>(
    AuthState.NOT_AUTHENTICATED
  );

  private supabase: SupabaseClient;
  private accessToken?: string;

  constructor(
    private http: HttpClient,
    public router: Router,
    private translate: TranslateService
  ) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
    this.supabase.auth.onAuthStateChange((event, sess) => {
      this.authState.next(AuthState.LOADING);
      if (
        event === 'SIGNED_IN' ||
        event === 'TOKEN_REFRESHED' ||
        event === 'PASSWORD_RECOVERY' ||
        event === 'USER_UPDATED' ||
        event === 'INITIAL_SESSION'
      ) {
        this.currentUser.next(sess?.user ?? false);
        this.accessToken = sess?.access_token;

        const isAuth = !!sess?.user && !!sess.access_token;
        this.authState.next(isAuth ? AuthState.AUHTENTICATED : AuthState.NOT_AUTHENTICATED);
      } else {
        this.authState.next(AuthState.NOT_AUTHENTICATED);
        this.currentUser.next(false);
      }
    });

    // Trigger initial session load
    this.loadUser();
  }

  async loadUser() {
    if (this.currentUser.value) {
      // User is already set, no need to do anything else

      return;
    }
    const user = await this.supabase.auth.getUser();

    if (user.data.user) {
      this.currentUser.next(user.data.user);
      this.authState.next(AuthState.AUHTENTICATED);
    } else {
      this.currentUser.next(false);
    }
  }

  signUp(credentials: { email: string; password: string }) {
    return this.supabase.auth.signUp(credentials);
  }

  signIn(credentials: { email: string; password: string }) {
    return this.supabase.auth.signInWithPassword(credentials);
  }
  async signOutAndNavigate() {
    await this.supabase.auth.signOut();
    localStorage.removeItem('currentRole');
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  async signOut() {
    await this.supabase.auth.signOut();
  }

  getRoles() {
    return this.http.get<UserRole>(`${this.apiURL}/user/roles`);
  }

  updatePassword(password: string) {
    return this.supabase.auth.updateUser({ password });
  }

  getCurrentUser(): Observable<User | boolean> {
    return this.currentUser.asObservable();
  }

  getCurrentUserId(): string | null {
    if (this.currentUser.value) {
      return (this.currentUser.value as User).id;
    } else {
      return null;
    }
  }

  signInWithEmail(email: string) {
    return this.supabase.auth.signInWithOtp({ email });
  }

  get supabaseAccessToken() {
    return this.accessToken;
  }

  getIndicatorCountries() {
    return indicatorCountries;
  }

  register(user: any, role?: UserPermisson): Observable<any> {
    let api =
      role === UserPermisson.MENTOR
        ? `${this.apiURL}/auth/register/mentor`
        : `${this.apiURL}/auth/register/client`;
    return this.http.post(api, user);
  }

  getMentorServicePreferenceChoices() {
    let api = `${this.apiURL}/info/mentor-service-preference`;
    return this.http.get<MentorServicePreference[]>(api);
  }

  getMarketingOriginChoices() {
    let api = `${this.apiURL}/info/marketing-origin`;
    return this.http.get<MarketingOrigin[]>(api);
  }

  verifyEmail(object: any) {
    let api = `${this.apiURL}/auth/verify-email/user?lang=${
      this.translate.currentLang ?? this.translate.defaultLang
    }`;
    return this.http.post(api, object);
  }

  resendVerificationEmail(object: any) {
    let api = `${this.apiURL}/auth/resend-verification-email`;
    return this.http.post(api, object);
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  get isLoggedIn(): boolean {
    return this.authState.value === AuthState.AUHTENTICATED;
  }

  // User profile
  getUserProfile(id: any): Observable<any> {
    // let api = `${this.endpoint}/user-profile/${id}`;
    let api = `${this.endpoint}/users/${id}?lang=${
      this.translate.currentLang ?? this.translate.defaultLang
    }`;
    return this.http.get(api, { headers: this.headers }).pipe(
      map((res) => {
        return res || {};
      }),
      catchError(this.handleError)
    );
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
