<div class="container charte-rgpd px-3 py-5">
  <p class="text-60 fw-bold" style="color: #6633CC">{{ 'rgpdCharter' | translate }} ({{ 'rgpdCharterText' | translate }})</p>
  <p>
    Cette politique de protection de données décrit la manière dont vos informations personnelles
    sont collectées, utilisées et partagées lorsque vous visitez, postulez ou postez des annoncces
    sur Jobaas.
  </p>
  <h2>INFORMATIONS PERSONNELLES QUE NOUS COLLECTONS</h2>
  <p>
    Lorsque vous visitez le site, nous collectons automatiquement certaines informations sur votre
    appareil, notamment des informations sur votre navigateur Web, votre adresse IP, votre fuseau
    horaire et certains des cookies installés sur votre appareil. En outre, lorsque vous naviguez
    sur le site, nous collectons des informations sur les Pages Web individuelles ou les produits
    que vous affichez, sur les sites Web ou les termes de recherche qui vous renvoient au site,
    ainsi que des informations sur la manière dont vous interagissez avec le site. Nous nous
    référons à ces informations collectées automatiquement en tant qu’informations sur le
    périphérique.
  </p>
  <p>
    Nous recueillons des informations sur les appareils en utilisant les technologies suivantes:
  </p>
  <ul>
    <li>
      Les «fichiers journaux» suivent les actions effectuées sur le site et collectent des données,
      notamment votre adresse IP, le type de navigateur, le fournisseur de services Internet, les
      pages de renvoi / de sortie et les horodatages.
    </li>
    <li>
      Les «balises Web», les «balises» et les «pixels» sont des fichiers électroniques utilisés pour
      enregistrer des informations sur la façon dont vous naviguez sur le site.
    </li>
  </ul>
  <p>
    De plus, lorsque vous postez une annonce ou postulez à une annonce, nous collectons certaines
    informations, notamment votre nom, votre adresse de facturation, vos informations de paiement (y
    compris les numéros de carte de crédit et les identifiants Apple Pay), votre adresse
    électronique et numéro de téléphone. Nous nous référons à cette information en tant que
    "Informations de commande".
  </p>
  <p>
    Lorsque nous parlons de «Informations personnelles» dans la présente Politique de
    confidentialité, nous parlons à la fois d’informations sur les appareils et d’informations sur
    les annonces ainsi que vos données personnelles.
  </p>
  <h2>COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES?</h2>
  <p>
    Nous utilisons généralement les informations que nous collectons pour exécuter toutes les
    localisations sur les lieux des offres d'emploi sur le site (y compris le traitement de vos
    informations de paiement, l'organisation des missions et la fourniture de factures et / ou de
    confirmations de réalisation des offres d'emploi et payement ). De plus, nous utilisons ces
    informations pour: Communiquer avec vous; Examiner vos profils pour détecter tout risque
    potentiel ou fraude; Lorsque cela est conforme aux préférences que vous avez partagées avec
    nous, nous vous fournissons des informations ou des publicités relatives à nos services.
  </p>
  <p>
    Nous utilisons les informations sur le périphérique que nous collectons pour nous aider à
    détecter les risques potentiels et les fraudes (en particulier votre adresse IP), et plus
    généralement à améliorer et optimiser notre site (par exemple, en générant des analyses sur la
    manière dont nos utilisateurs naviguent et interagissent avec le site, et d’évaluer le succès de
    nos campagnes de marketing et de publicité).
  </p>
  <h2>PARTAGE DE VOS INFORMATIONS PERSONNELLES</h2>
  <p>
    Nous partageons vos informations personnelles avec les personnes avec qui ou chez qui vous
    travaillerez. Par exemple, nous transmettons votre profil de travailleur aux employeurs et de la
    même façon, les travailleurs recoivent des données sur les auteurs des offres d'emploi. Nous
    utilisons également Google Analytics pour nous aider à comprendre comment nos clients utilisent
    le site. Pour en savoir plus sur la manière dont Google utilise vos informations personnelles,
    consultez la page <span style="word-break: break-word">https://www.google.com/intl/fr/policies/privacy/</span>. Vous pouvez également
    désactiver Google Analytics ici: <span style="word-break: break-word">https://tools.google.com/dlpage/gaoptout</span>.
  </p>
  <p>
    Enfin, nous pouvons également partager vos informations personnelles afin de respecter les lois
    et réglementations en vigueur, de répondre à une assignation à comparaître, à un mandat de
    perquisition ou à toute autre demande licite d'informations que nous recevons, ou de protéger
    nos droits de quelque manière que ce soit.
  </p>
  <h2>COMPORTEMENT PUBLICITAIRE</h2>
  <p>
    Comme décrit ci-dessus, nous utilisons vos informations personnelles pour vous fournir des
    publicités ciblées, des communications marketing ou des missions susceptibles de vous
    intéresser.
  </p>
  <h2>NE PAS SUIVRE</h2>
  <p>
    Veuillez noter que nous ne modifions pas la collecte de données de notre site ni les pratiques
    en vigueur lorsque nous voyons un signal Ne pas suivre de votre navigateur.
  </p>
  <h2>VOS DROITS</h2>
  <p>
    Vous avez le droit d'accéder aux informations personnelles que nous détenons à votre sujet et de
    demander que vos informations personnelles soient corrigées, mises à jour ou supprimées. Si vous
    souhaitez exercer ce droit, veuillez nous contacter via les coordonnées ci-dessous.
  </p>
  <h2>DÉTENTION DES DONNÉES</h2>
  <p>
    Lorsque vous vous inscrivez sur le site, nous conservons vos informations pour nos
    enregistrements jusqu'à ce que vous nous demandiez de supprimer ces informations. CHANGEMENTS :
    Nous pouvons mettre à jour cette politique de confidentialité de temps à autre afin de refléter,
    par exemple, des changements apportés à nos pratiques ou pour d'autres raisons opérationnelles,
    légales ou réglementaires.
  </p>
</div>
