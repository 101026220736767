import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelpRequirement } from 'src/app/core/models/help';

@Component({
  selector: 'app-help-dialog-details',
  templateUrl: './help-dialog-details.component.html',
  styleUrls: ['./help-dialog-details.component.scss'],
})
export class HelpDialogDetailsComponent implements OnInit {
  requirements: HelpRequirement[] = [];
  constructor(
    public dialogRef: MatDialogRef<HelpDialogDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { restrictions: HelpRequirement[] }
  ) {}

  ngOnInit(): void {
    this.requirements = this.data.restrictions;
  }

  cancel() {
    this.dialogRef.close();
  }
}
