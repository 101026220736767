export enum AuthState {
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  LOADING = 'LOADING',
  AUHTENTICATED = 'AUHTENTICATED',
}

export enum UserPermisson {
  CLIENT = 'CLIENT',
  MENTOR = 'MENTOR',
  HOUSING_RESEARCHER = 'HOUSING_RESEARCHER',
  BUSINESS_PROVIDER = 'BUSINESS_PROVIDER',
}
