import { Component } from '@angular/core';

@Component({
  selector: 'app-clients-feedback',
  templateUrl: './clients-feedback.component.html',
  styleUrls: ['./clients-feedback.component.scss'],
})
export class ClientsFeedbackComponent {
  feedbackInfoList = [
    {
      name: 'Lionel K.',
      role: 'Client du service carrière',
      rate: 4,
      remarks: `Je trouve que le service était bon, j'ai été très satisfait de la réactivité et de la disponibilité de mon mentor`,
    },
    {
      name: 'Emerick',
      role: 'Client du service carrière',
      rate: 4,
      remarks: `Malgré quelques difficultés au début, le service a été au top. De la préparation aux entretiens au suivi du mentor`,
    },

    {
      name: 'Linda S.',
      role: 'Cliente du service carrière',
      rate: 4,
      remarks: `Suivi bien, PILINK a été efficace, professionnel et disponible`,
    },

    {
      name: 'Sonia T.',
      role: 'Mentor du service intégration',
      rate: 4,
      remarks: `C’était vraiment une belle expérience et c’est vraiment un service que je recommanderais aux personnes qui souhaitent intégrer la France`,
    },

    {
      name: 'Romuald Y.',
      role: 'Client du service logement',
      rate: 4,
      remarks: `Accompagnement de qualité, j'ai apprécié le suivi constant de mon dossier et l'attention portée`,
    },

    {
      name: 'Wilfried D.',
      role: 'Mentor du service Carrière',
      rate: 5,
      remarks: `Ravi d'avoir travaillé avec eux; J'ai pu accompagner des étudiants qui souhaitaient avoir des contrats en alternance. J'ai apprécié l'accompagnement de Pilink en tant que mentor. Ils m'ont fourni une très bonne documentation qui m'a permis à mon tour d'être efficace dans mon travail de mentor. Un service que je recommande fortement.`,
    },

    {
      name: 'Raphaela T.',
      role: 'Cliente du service Carrière',
      rate: 5,
      remarks: `J'ai bénéficié du Service Carrière de PILINK, notamment pour un accompagnement dans ma recherche d'emploi et un mentor m'a été confié. Une véritable pertinence dans le choix du mentor, quelqu'un qui avait beaucoup d'années d'expérience dans le même domaine que moi (un Dircom) et donc capable de m'orienter efficacement. Nous nous sommes bien entendus et mon mentor m'a donné de précieux conseils sur mon CV, ma lettre de motivation, mon portfolio, ma présence en ligne et les entretiens. J'ai pu obtenir un CDI, je recommande :) !`,
    },
    {
      name: 'Romuald Junior Y.',
      role: 'Client du service Carrière',
      rate: 4,
      remarks: `Accompagnement de qualité, j’ai apprécié le suivi constant de mon dossier et l’attention portée`,
    },
    {
      name: 'Yasmine S.',
      role: 'Cliente du service Carrière',
      rate: 4,
      remarks: `Très bonne Entreprise, dynamique, à l’écoute de ses clients , avec un service impeccable`,
    },
  ];

  constructor() {}
  evaluateStars(rating: number) {
    return Array(Math.floor(rating)).fill(0);
  }
  hasHalfStar(rating: number) {
    return Math.trunc(rating) !== rating;
  }
}
