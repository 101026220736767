<section class="container-fluid mt-3 bg-head-become-mentor">
  <div class="container">
    <div class="card" data-aos="fade-up" data-aos-duration="1000">
      <div class="card-body">
        <p class="card-text mb-2">{{ entete && 'JoinUs' | translate }}</p>
        <p class="card-title">{{ 'BecomeMentorCardTitle' | translate }}</p>
        <p class="card-text mb-5">{{ descriptionMentor && 'HomeAndAboutBecomeMentorDesc' | translate }}</p>
        <a (click)="commencer()" class="card-link btn btn-purple">{{ libelleButon && 'LetStart' | translate }}</a>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid bg-foot-become-mentor">
  <div class="container let-start text-center mb-2">
    <p class="text-go text-60">{{ titre && 'LetsGoNow' | translate }}</p>
    <p class="go-condition mb-5">{{ sousTitre && 'HomeBecomeMentorTextGoingOn' | translate }}</p>
    <a (click)="commencer()" class="btn btn-purple">{{ libelleButon && 'LetStart' | translate }}</a>
  </div>
</section>

