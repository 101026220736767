import { Injectable } from '@angular/core'
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { UserService } from 'src/app/user/services/user.service';
import { deleteUserFiles, getUserFiles, SetLoaded, setUserFiles } from "./file.action";
import { MetadataFile } from "./modal-add/interface/metadatafile";

export class UserFilesStateModel {
  files !: MetadataFile[];
  loading: any;
  loaded: any;
}

@State<UserFilesStateModel>({
  name: 'UserFilesState',
  defaults: {
    files: [],
    loading: true,
    loaded: false,
  }
})

@Injectable()
export class UserFilesState {

  constructor(private userService: UserService) {}

  static filesUser(category: string) {
    return createSelector([UserFilesState], (state) => {
      return state.files.filter((file: MetadataFile) => file.moduleCategory === category);
    });
  }

  @Selector()
  static loading(state: UserFilesStateModel) {
    return state.loading
  }

  @Selector()
  static filesInfo(state: UserFilesStateModel) {
    return state.files
  }

  @Selector()
  static loaded(state: UserFilesStateModel) {
    return state.loaded
  }

  @Action(SetLoaded)
  setLoading({ patchState }: StateContext<UserFilesStateModel>) {
    patchState({
      loaded: true,
      loading: false
    })
  }

  @Action(getUserFiles)
  getFilesInfoFromState(ctx: StateContext<UserFilesStateModel>, { id }: getUserFiles) {
    return this.userService.getMetadataFileByUser(id)
      .pipe(
        tap(fileData => {
          const state = ctx.getState();
          ctx.patchState({
            files: fileData.data,
            loaded: !state.loaded,
            loading: !state.loading
          });
        }),
        tap(
          () => {
            ctx.dispatch(new SetLoaded());
          }
        )
      )
  }

  @Action(setUserFiles)
  addUserFilesFromState(ctx: StateContext<UserFilesStateModel>, { payload, idUser }: setUserFiles) {
    return this.userService.addMetadataFile(payload)
      .pipe(
        tap((response) => {
          const state = ctx.getState();
          ctx.patchState({
            files: [...state.files, response.data],
            loaded: !state.loaded,
            loading: !state.loading
          });
        }),
        tap(
          () => {
            ctx.dispatch(new getUserFiles(idUser));
          }
        )
      )
  }

  @Action(deleteUserFiles)
  deleteProfessionalInfoFromState(ctx: StateContext<UserFilesStateModel>, { idFile, idUser }: deleteUserFiles) {
    return this.userService.deleteMetadataFile(idFile)
      .pipe(
        tap(response => {
          const state = ctx.getState();
          const oldFiles = state.files;
          const fileTodelete = {...response.data};
          const newFiles = oldFiles.filter(file => file._id !== fileTodelete._id);
          ctx.patchState({
            files: [...newFiles],
            loaded: !state.loaded,
            loading: !state.loading
          });
        }),
        tap(
          () => {
            ctx.dispatch(new getUserFiles(idUser));
          }
        )
      )
  }
}
