import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { SwiperModule } from 'swiper/angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar-next';
import { DevisePipe } from './pipes/devise.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TabsMentionsComponent } from './components/tabs-mentions/tabs-mentions.component';
import { LegalMentionsComponent } from './components/tabs-mentions/legal-mentions/legal-mentions.component';
import { CguComponent } from './components/tabs-mentions/cgu/cgu.component';
import { CharteRgpdComponent } from './components/tabs-mentions/charte-rgpd/charte-rgpd.component';
import { CgvComponent } from './components/tabs-mentions/cgv/cgv.component';
import { ShowHideInputDirective } from './directives/show-hide-input.directive';
import { DetailModalComponent } from './components/detail-modal/detail-modal.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent,
    SideNavComponent,
    NotFoundComponent,
    DevisePipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TabsMentionsComponent,
    LegalMentionsComponent,
    CguComponent,
    CgvComponent,
    CharteRgpdComponent,
    ShowHideInputDirective,
    DetailModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    SwiperModule,
    RouterModule,
    PerfectScrollbarModule,
    TranslateModule,
    NgxPaginationModule,
    GooglePlaceModule,
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    SideNavComponent,
    TabsMentionsComponent,
    LegalMentionsComponent,
    CguComponent,
    CgvComponent,
    CharteRgpdComponent,
    ShowHideInputDirective,
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    SwiperModule,
    PerfectScrollbarModule,
    SafeHtmlPipe,
    SafeUrlPipe,
    TranslateModule,
    NgxPaginationModule,
    GooglePlaceModule,
  ],
})
export class SharedModule {}
