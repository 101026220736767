<section class="container-fluid bg-yellow integration-pilink pt-5" id="integration-integration">
  <div class="container px-lg-5">
    <div class="row">
      <div class="col-lg-6 col-md-8">
        <p class="text-60 banner-label">
          {{ 'RatesBannerLabel' | translate }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-8 pt-2 pb-4">
        <p class="banner-directive">
          {{ 'RatesBannerDirective' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="container px-lg-5">
    <hr style="color: black" />
  </div>
  <div class="container px-lg-5" style="padding-top: 70px; padding-bottom: 50px">
    <div *ngIf="!requestModulesDone" class="text-center py-5">
      <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" *ngIf="requestModulesDone">
      <div class="col-lg-10 col-md-8">
        <h1 class="service-title text-60 text-dark">{{ 'IntegrationText' | translate }}</h1>
      </div>
      <div class="col-lg-2 col-md-4 pt-4">
        <div class="text-dark">
          <label class="label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
          <label class="label-cfa">€ &nbsp; &nbsp;</label>
          <div class="form-check form-switch d-inline-block switch">
            <input
              class="form-check-input"
              (change)="onCheckEurInt($event)"
              type="checkbox"
              role="switch"
            />
            <label class="label-euro">XAF</label>
          </div>
        </div>
      </div>
    </div>
    <h3
      *ngIf="requestModulesDone"
      class="text-dark"
      style="padding-bottom: 20px; padding-top: 10px; font-weight: 400"
    >
      {{ 'RatesIntegrationTextDesc' | translate }}
    </h3>
    <div class="row" *ngIf="requestModulesDone">
      <div
        class="col-md-4"
        *ngFor="let item of integrationList; let i = index"
        [attr.data-index]="i"
      >
        <div
          class="card rounded-0 bg-dark-blue"
          data-aos="fade-up"
          data-aos-duration="1000"
          [ngClass]="{ 'mt-3 mt-md-0': i > 0 }"
        >
          <div class="card-body position-relative">
            <p class="card-integration-title text-yellow">{{ item.name }}</p>
            <!-- <p
              class="text-white text-center amount-integration-card old-amount pt-4 pb-2"
              *ngIf="!toEurInt"
            >
              {{ nonDiscountedPrice(item.price.priceEUR) }} €
            </p> -->
            <p class="text-white text-center amount-integration-card pb-2" *ngIf="!toEurInt">
              {{ item.price.priceEUR }} €
            </p>
            <!-- <p
              class="text-white text-center amount-integration-card old-amount pt-4 pb-2"
              *ngIf="toEurInt"
            >
              {{ nonDiscountedPrice(item.price.priceXAF) }}
              XAF
            </p> -->
            <p class="text-white text-center amount-integration-card pb-2" *ngIf="toEurInt">
              {{ item.price.priceXAF }}
              XAF
            </p>
            <hr style="color: white" />
            <ul class="list-unstyled text-white card-offers pt-3">
              <li
                *ngFor="let productProcess of item.processes; let i = index"
                [attr.data-index]="i"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill check-success"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                {{ productProcess.process.title }}
              </li>
            </ul>
            <button
              class="btn btn-warning rounded-0 d-block mx-auto mt-4 w-100"
              (click)="subscribe({ data: item?.id, type: 'module' })"
              style="position: absolute; bottom: 0; left: 0; right: 0"
            >
              {{ 'Subscribe' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container-fluid bg-dark-blue carreer-pilink" id="carriere-career">
  <div class="container px-lg-5" style="padding-top: 70px; padding-bottom: 50px">
    <div *ngIf="!requestModulesDone" class="text-center py-5">
      <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" *ngIf="requestModulesDone">
      <div class="col-lg-10 col-md-8">
        <h1 class="service-title text-60 text-white">{{ 'CareerText' | translate }}</h1>
      </div>
      <div class="col-lg-2 col-md-4 pt-4">
        <div class="text-white">
          <label class="label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
          <label class="label-cfa">€ &nbsp; &nbsp;</label>
          <div class="form-check form-switch d-inline-block switch">
            <input
              class="form-check-input"
              (change)="onCheckEurCarreer($event)"
              type="checkbox"
              role="switch"
            />
            <label class="label-euro">XAF</label>
          </div>
        </div>
      </div>
    </div>
    <h3
      *ngIf="requestModulesDone"
      class="text-white"
      style="padding-bottom: 20px; padding-top: 10px; font-weight: 400"
    >
      {{ 'RatesCareerTextDesc' | translate }}
    </h3>
    <div class="row" *ngIf="requestModulesDone">
      <div class="col-md-6" *ngFor="let item of careerList; let i = index" [attr.data-index]="i">
        <div
          class="card rounded-0 bg-light"
          data-aos="fade-up"
          data-aos-duration="1000"
          [ngClass]="{ 'mt-3 mt-md-0': i > 0 }"
        >
          <div class="card-body position-relative">
            <p class="card-carreer-title text-dark">{{ item.name || '' }}</p>
            <!-- <p
              class="text-purple text-center amount-carreer-card old-amount pt-4 pb-2"
              *ngIf="!toEurCarreer"
            >
              {{ roundingNumber(nonDiscountedPrice(item.price.priceEUR)) }} €
            </p> -->
            <p class="text-purple text-center amount-carreer-card pb-2" *ngIf="!toEurCarreer">
              {{ roundingNumber(item.price.priceEUR) }} €
            </p>
            <!-- <p
              class="text-purple text-center amount-carreer-card old-amount pt-4 pb-2"
              *ngIf="toEurCarreer"
            >
              {{ roundingNumber(nonDiscountedPrice(item.price.priceXAF)) }}
              XAF
            </p> -->
            <p class="text-purple text-center amount-carreer-card pb-2" *ngIf="toEurCarreer">
              {{ item.price.priceXAF }}
              XAF
            </p>
            <hr style="color: #6633cc" />
            <ul class="list-unstyled text-dark card-offers pt-3">
              <li
                *ngFor="let productProcess of item.processes; let i = index"
                [attr.data-index]="i"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill check-success"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                {{ productProcess.process.title }}
              </li>
            </ul>
            <button
              class="btn btn-purple-large d-block mx-auto mt-4 w-100 position-absolute"
              (click)="subscribe({ data: item?.id, type: 'module' })"
              style="left: 0; right: 0; bottom: 0"
            >
              {{ 'Subscribe' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="container-fluid bg-yellow integration-pilink"
  id="service-campus-france-visa-campus_france_visa"
>
  <div class="container px-lg-5" style="padding-top: 70px; padding-bottom: 50px">
    <div *ngIf="!requestModulesDone" class="text-center py-5">
      <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" *ngIf="requestModulesDone">
      <div class="col-lg-10 col-md-8">
        <h1 class="service-title text-60 text-dark">{{ 'CampusFranceText' | translate }}</h1>
      </div>
      <div class="col-lg-2 col-md-4 pt-4">
        <div class="text-dark">
          <label class="label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
          <label class="label-cfa">€ &nbsp; &nbsp;</label>
          <div class="form-check form-switch d-inline-block switch">
            <input
              class="form-check-input"
              (change)="onCheckEurVisa($event)"
              type="checkbox"
              role="switch"
            />
            <label class="label-euro">XAF</label>
          </div>
        </div>
      </div>
    </div>
    <h3
      *ngIf="requestModulesDone"
      class="text-dark"
      style="padding-bottom: 20px; padding-top: 10px; font-weight: 400"
    >
      {{ 'RatesCampusFranceVisaTextDesc' | translate }}
    </h3>
    <div class="row" *ngIf="requestModulesDone">
      <div
        class="col-md-6"
        *ngFor="let item of campusFranceVisaList; let i = index"
        [attr.data-index]="i"
      >
        <div
          class="card rounded-0 bg-dark-blue"
          data-aos="fade-up"
          data-aos-duration="1000"
          [ngClass]="{ 'mt-3 mt-md-0': i > 0 }"
        >
          <div class="card-body position-relative">
            <p class="card-integration-title text-yellow">{{ item.name }}</p>
            <!-- <p
              class="text-white text-center amount-integration-card old-amount is-campus pt-4 pb-2"
              *ngIf="!toEurVisa"
            >
              {{ roundingNumber(nonDiscountedPrice(item.price.priceEUR)) }} €
            </p> -->
            <p class="text-white text-center amount-integration-card pb-2" *ngIf="!toEurVisa">
              {{ roundingNumber(item.price.priceEUR) }} €
            </p>
            <!-- <p
              class="text-white text-center amount-integration-card old-amount is-campus pt-4 pb-2"
              *ngIf="toEurVisa"
            >
              {{ roundingNumber(nonDiscountedPrice(item.price.priceXAF)) }}
              XAF
            </p> -->
            <p class="text-white text-center amount-integration-card pb-2" *ngIf="toEurVisa">
              {{ item.price.priceXAF }}
              XAF
            </p>
            <hr style="color: white" />
            <ul class="list-unstyled text-white card-offers pt-3">
              <li
                *ngFor="let productProcess of item.processes; let i = index"
                [attr.data-index]="i"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-circle-fill check-success"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                {{ productProcess.process.title }}
              </li>
            </ul>
            <button
              class="btn btn-warning rounded-0 d-block mx-auto mt-4 w-100"
              (click)="subscribe({ data: item?.id, type: 'module' })"
              style="position: absolute; bottom: 0; left: 0; right: 0"
            >
              {{ 'Subscribe' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container-fluid location-pilink" id="recherche-de-logement-housing">
  <div class="container px-lg-5" style="padding-top: 70px; padding-bottom: 50px">
    <div *ngIf="!requestModulesDone" class="text-center py-5">
      <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" *ngIf="requestModulesDone">
      <div class="col-lg-10 col-md-8">
        <h1 class="service-title text-60 text-purple">{{ 'SearchHousingText' | translate }}</h1>
      </div>
      <div class="col-lg-2 col-md-4 pt-4">
        <div class="text-dark">
          <label class="label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
          <label class="label-cfa">€ &nbsp; &nbsp;</label>
          <div class="form-check form-switch d-inline-block switch">
            <input
              class="form-check-input"
              (change)="onCheckEurLocation($event)"
              type="checkbox"
              role="switch"
            />
            <label class="label-euro">XAF</label>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="requestModulesDone"
      class="text-purple"
      style="padding-bottom: 20px; padding-top: 15px; font-weight: 400"
    >
      <ul>
        <li>Recherche du logement dans la ville de votre choix selon vos critères</li>
        <li>Elaboration d’un dossier solide auprès des bailleurs et des partenaires</li>
        <li>Suivi personnalisé tout au long du processus de recherche</li>
        <li>Visite minutieuse du logement avec des photos et vidéos de qualité</li>
        <li>Conseils et astuces liés au besoin et installation du client</li>
        <li>Mise à disposition du logement au temps convenu</li>
      </ul>
    </div>
    <div
      *ngIf="requestModulesDone"
      class="card border-0 rounded-0"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div class="card-body location-card row d-md-flex justify-content-center align-items-center">
        <div class="col-lg-6 d-none d-md-block">
          <img
            src="assets/img/logement.png"
            class="w-75 img-fluid d-md-block mx-auto"
            alt="Pilink"
          />
        </div>
        <div class="col-lg-6 pb-5">
          <div class="location-block-purpose mx-md-auto text-dark pb-2">
            <!-- <h1 class="pt-4">{{ 'RatesHousingSearchTextOfferFirst' | translate }}</h1> -->
            <div class="d-flex justify-content-center">
              <div
                *ngFor="let product of housingBasicProductList; let i = index"
                class="text-center"
                [ngClass]="
                  i < housingBasicProductList.length - 1
                    ? 'border-end border-dark pe-3 pe-md-5'
                    : ' ps-3 ps-md-5'
                "
              >
                <p class="fw-bold fs-6">
                  {{ product.name }}
                </p>
                <!-- <h1 class="fw-bold old-amount mb-0" *ngIf="!toEurLocation">
                  {{ roundingNumber(nonDiscountedPrice(product.price.priceEUR)) }}
                  €
                </h1> -->
                <h1 class="fw-bold mb-0" *ngIf="!toEurLocation">
                  {{ roundingNumber(product.price.priceEUR) }} €
                </h1>
                <h1 class="fw-bold mb-0" *ngIf="toEurLocation">
                  {{ product.price.priceXAF }}
                  XAF
                </h1>
                <!-- <h1 class="fw-bold mb-0 old-amount mb-0" *ngIf="toEurLocation">
                  {{ roundingNumber(nonDiscountedPrice(product.price.priceXAF)) }}
                  XAF
                </h1> -->
                <button
                  class="btn btn-purple-large d-block mt-5 w-100"
                  style="width: 138px !important"
                  (click)="
                    subscribe({
                      data: product?.id,
                      type: 'module'
                    })
                  "
                >
                  {{ 'Subscribe' | translate }}
                </button>
              </div>
            </div>
          </div>
          <!-- <hr class="mx-md-auto" style="max-width: 400px; color: black" /> -->
        </div>
      </div>
    </div>
    <p
      *ngIf="requestModulesDone"
      class="mt-5 text-purple text-center fs-5 fw-bold"
      style="line-height: 150%"
    >
      N/B : {{ 'RatesHousingSearchTextNotaBene' | translate }}
    </p>
  </div>
</section>

<section class="container-fluid connection-pilink" id="espace-pi-online" *ngIf="espacePI">
  <div class="container px-lg-5" style="padding-top: 40px; padding-bottom: 50px">
    <div *ngIf="!requestModulesDone" class="text-center py-5">
      <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" *ngIf="requestModulesDone">
      <div class="col-lg-10 col-md-8">
        <h1 class="service-title text-60 text-purple">
          {{ espacePI.name && 'EspacePI' | translate }}
        </h1>
      </div>
      <div class="col-lg-2 col-md-4 pt-4">
        <div class="text-dark">
          <label class="label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
          <label class="label-cfa">€ &nbsp; &nbsp;</label>
          <div class="form-check form-switch d-inline-block switch">
            <input
              class="form-check-input"
              (change)="onCheckEurConnection($event)"
              type="checkbox"
              role="switch"
            />
            <label class="label-euro">XAF</label>
          </div>
        </div>
      </div>
    </div>
    <h3
      *ngIf="requestModulesDone"
      class="text-purple"
      style="padding-bottom: 20px; padding-top: 10px; font-weight: 400"
    >
      {{ 'RatesEspacePiTextDesc' | translate }}
    </h3>
    <div
      *ngIf="requestModulesDone"
      class="card border-0 rounded-0 bg-gradient-purple"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div
        class="card-body connection-card row d-md-flex justify-content-center align-items-center"
      >
        <div class="col-md-4 d-none d-md-block align-self-end">
          <img
            src="assets/img/heroEspacePi.png"
            style="position: relative; bottom: 0"
            class="w-100 img-fluid"
            alt="Pilink"
          />
        </div>
        <div class="col-md-8 connection-col-2 pb-5">
          <!-- <div class="row text-white">
            <h1 class="fw-bold pt-4">{{ 'Advantages' | translate }}</h1>
            <div class="col-md-6">
              <ul class="px-1 connection-col-2-ul">
                <li *ngFor="let item of espacePI?.processes; let i = index" [attr.data-index]="i">
                  {{ item.process.title }}
                </li>
              </ul>
            </div>
          </div> -->
          <div class="row text-white pt-4">
            <div class="col-md-5 pe-0">
              <h1 class="fw-bold mb-0" *ngIf="!toEuroConnection">
                <!-- <span class="old-amount"
                  >{{ roundingNumber(nonDiscountedPrice(espacePI.price.priceEUR)) || '' }} €/{{
                    'monthText' | translate
                  }}</span
                > -->
                <span class="ms-2"
                  >{{ roundingNumber(espacePI.price.priceEUR) || '' }} €/{{
                    'monthText' | translate
                  }}</span
                >
              </h1>
              <h1 class="fw-bold mb-0" *ngIf="toEuroConnection">
                <!-- <span class="old-amount"
                  >{{ roundingNumber(nonDiscountedPrice(espacePI.price.priceXAF)) }} XAF/{{
                    'monthText' | translate
                  }}</span
                > -->
                <span class="ms-2"
                  >{{ espacePI.price.priceXAF }} XAF/{{ 'monthText' | translate }}</span
                >
              </h1>
              <small style="color: transparent">({{ 'textPermonth' | translate }})</small>
              <button
                (click)="subscribe({ data: espacePI?.id, type: 'online' })"
                class="btn btn-light btn-light-custom rounded-0 d-block mt-4 w-100"
              >
                {{ 'Subscribe' | translate }}
              </button>
            </div>
            <div class="col-md-1 ps-0 vertical-bar" style="border-left: 1px solid #fff"></div>
            <div class="col-md-5 pt-3 pt-md-0">
              <h1 class="fw-bold mb-0" *ngIf="!toEuroConnection">
                <!-- <span class="old-amount"
                  >{{
                    roundingNumber(
                      nonDiscountedPrice(espacePI.price.priceEUR * 12 - espacePI.price.priceEUR * 2)
                    )
                  }}
                  €/{{ 'yearText' | translate }}</span
                > -->
                <span class="ms-2"
                  >{{
                    roundingNumber(espacePI.price.priceEUR * 12 - espacePI.price.priceEUR * 2)
                  }}
                  €/{{ 'yearText' | translate }}</span
                >
              </h1>
              <h1 class="fw-bold mb-0" *ngIf="toEuroConnection">
                <!-- <span class="old-amount">
                  {{
                    roundingNumber(
                      nonDiscountedPrice(espacePI.price.priceXAF * 12 - espacePI.price.priceXAF * 2)
                    )
                  }}
                  XAF/{{ 'yearText' | translate }}
                </span> -->
                <span class="ms-2">
                  {{ roundingNumber(espacePI.price.priceXAF * 12 - espacePI.price.priceXAF * 2) }}
                  XAF/{{ 'yearText' | translate }}
                </span>
              </h1>
              <small>({{ 'textMonthsFree' | translate }})</small>
              <button
                class="btn btn-light btn-light-custom rounded-0 d-block mt-4 w-100 w-100"
                (click)="subscribe({ data: espacePI?.id, type: 'online' })"
              >
                {{ 'Subscribe' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="container-fluid agenda-pilink bg-gradient-purple"
  id="agenda-automatique-online"
  *ngIf="automaticAgenda"
>
  <div class="row">
    <div class="col-12">
      <div class="container px-lg-5">
        <div *ngIf="!requestModulesDone" class="text-center py-5">
          <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="row pt-5" *ngIf="requestModulesDone">
          <div class="col-lg-10 col-md-8">
            <h1 class="service-title text-60 text-white">
              {{ automaticAgenda.name }}
            </h1>
          </div>
          <div class="col-lg-2 col-md-4 pt-4">
            <div class="text-white d-flex align-items-center">
              <label class="fw-bold label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
              <label class="label-cfa">€&nbsp; &nbsp;</label>
              <div class="form-check form-switch d-inline-block switch">
                <input
                  class="form-check-input"
                  (change)="onCheckEurAgenda($event)"
                  type="checkbox"
                  role="switch"
                />
                <label class="label-euro">XAF</label>
              </div>
            </div>
          </div>
        </div>
        <h3
          *ngIf="requestModulesDone"
          class="text-white"
          style="padding-bottom: 20px; padding-top: 10px; font-weight: 400"
        >
          {{ 'RatesAgendaAutoTextDesc' | translate }}
        </h3>
        <div
          *ngIf="requestModulesDone"
          class="card rounded-0 mb-8"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div
            class="card-body agenda-card row d-md-flex justify-content-center align-items-center"
          >
            <div class="col-md-4 d-none d-md-block">
              <img src="assets/img/slide4.png" class="w-100 img-fluid" alt="Pilink" />
            </div>
            <div class="col-md-8 agenda-col-2">
              <!-- <div class="row text-purple">
                <h1 class="fw-bold pt-4">{{ 'RatesAgendaAutoTextAlert' | translate }}</h1>
                <div class="col-md-6">
                  <ul class="px-1 agenda-col-2-ul">
                    <li
                      *ngFor="let item of automaticAgenda?.processes; let i = index"
                      [attr.data-index]="i"
                    >
                      {{ item.process.title }}
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="row text-purple pt-4">
                <div class="col-md-5 pe-0">
                  <h1 class="agenda-price-month fw-bold mb-0" *ngIf="!toEurAgenda">
                    <!-- <span class="old-amount"
                      >{{
                        roundingNumber(nonDiscountedPrice(automaticAgenda.price.priceEUR)) || ''
                      }}
                      €/{{ 'monthText' | translate }}</span
                    > -->
                    <span class="ms-2"
                      >{{ roundingNumber(automaticAgenda.price.priceEUR) || '' }} €/{{
                        'monthText' | translate
                      }}</span
                    >
                  </h1>
                  <h1 class="agenda-price-month fw-bold mb-0" *ngIf="toEurAgenda">
                    <!-- <span class="old-amount"
                      >{{
                        roundingNumber(nonDiscountedPrice(automaticAgenda.price.priceXAF))
                      }}
                      XAF/{{ 'monthText' | translate }}</span
                    > -->
                    <span class="ms-2"
                      >{{ automaticAgenda.price.priceXAF }} XAF/{{ 'monthText' | translate }}</span
                    >
                  </h1>
                  <small style="color: transparent">({{ 'textPermonth' | translate }})</small>
                  <button
                    class="btn btn-purple d-block mt-4 w-100"
                    (click)="subscribe({ data: automaticAgenda?.id, type: 'module' })"
                  >
                    {{ 'Subscribe' | translate }}
                  </button>
                </div>
                <div
                  class="col-md-1 ps-0 vertical-bar"
                  style="border-left: 1px solid #6633cc"
                ></div>
                <div class="col-md-5 pt-3 pt-md-0">
                  <h1 class="agenda-price-month fw-bold mb-0" *ngIf="!toEurAgenda">
                    <!-- <span class="old-amount">
                      {{
                        roundingNumber(
                          nonDiscountedPrice(
                            automaticAgenda.price.priceEUR * 12 - automaticAgenda.price.priceEUR * 2
                          )
                        )
                      }}
                      €/{{ 'yearText' | translate }}
                    </span> -->
                    <span class="ms-2">
                      {{
                        roundingNumber(
                          automaticAgenda.price.priceEUR * 12 - automaticAgenda.price.priceEUR * 2
                        )
                      }}
                      €/{{ 'yearText' | translate }}
                    </span>
                  </h1>
                  <h1 class="agenda-price-month fw-bold mb-0" *ngIf="toEurAgenda">
                    <!-- <span class="old-amount">
                      {{
                        roundingNumber(
                          nonDiscountedPrice(
                            automaticAgenda.price.priceXAF * 12 - automaticAgenda.price.priceXAF * 2
                          )
                        )
                      }}
                      XAF/{{ 'yearText' | translate }}
                    </span> -->
                    <span class="ms-2">
                      {{ automaticAgenda.price.priceXAF * 12 - automaticAgenda.price.priceXAF * 2 }}
                      XAF/{{ 'yearText' | translate }}
                    </span>
                  </h1>
                  <small>({{ 'textMonthsFree' | translate }})</small>
                  <button
                    class="btn btn-purple d-block mt-4 w-100"
                    (click)="subscribe({ data: automaticAgenda?.id, type: 'online' })"
                  >
                    {{ 'Subscribe' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container-fluid bg-gradient-purple packs-pilink">
  <div class="container px-lg-5" style="padding-top: 70px; padding-bottom: 50px">
    <div *ngIf="!requestPacksDone" class="text-center py-5">
      <div class="spinner-grow text-warning" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" *ngIf="requestPacksDone">
      <div class="col-lg-10 col-md-8">
        <h1 class="service-title text-60 text-white">{{ 'packsText' | translate }}</h1>
      </div>
      <div class="col-lg-2 col-md-4 pt-4">
        <div class="text-white">
          <label class="label-devise">{{ 'Currency' | translate }} : &nbsp;</label>
          <label class="label-cfa">€ &nbsp; &nbsp;</label>
          <div class="form-check form-switch d-inline-block switch">
            <input
              class="form-check-input"
              (change)="onCheckEurPack($event)"
              type="checkbox"
              role="switch"
            />
            <label class="label-euro">XAF</label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="requestPacksDone" class="row d-md-flex justify-content-center mt-5">
      <div
        class="col-lg-4 my-2"
        *ngFor="let item of packsList; let i = index"
        [attr.data-index]="i"
      >
        <div class="card rounded-0 p-3 bg-light" data-aos="fade-up" data-aos-duration="1000">
          <div class="card-body">
            <p class="card-pack-title text-center text-white py-3">{{ item.name }}</p>
            <ul class="card-offers">
              <li *ngFor="let itemModule of item.products; let i = index" [attr.data-index]="i">
                {{ itemModule.name }}
              </li>
            </ul>
            <!-- <p class="card-pack-tax text-center text-white py-3" *ngIf="!toEurPacks">
              <span class="amount-tax"
                >{{ roundingNumber(nonDiscountedPrice(item.price.priceEUR)) }}€</span
              >
            </p> -->
            <p class="card-pack-trust-amount text-center text-purple pt-2" *ngIf="!toEurPacks">
              <span class="amount-trust">{{ roundingNumber(item.price.priceEUR) }}€</span>
            </p>
            <!-- <p class="card-pack-tax text-center text-white py-3" *ngIf="toEurPacks">
              <span class="amount-tax"
                >{{ roundingNumber(nonDiscountedPrice(item.price.priceXAF)) }} XAF</span
              >
            </p> -->
            <p class="card-pack-trust-amount text-center text-purple pt-2" *ngIf="toEurPacks">
              <span class="amount-trust">{{ item.price.priceXAF }} XAF</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <p
      *ngIf="requestPacksDone"
      class="text-center text-white pt-5 fst-normal"
      style="font-size: 20px"
    >
      {{ 'RatesOurPacksOfferText' | translate }}
    </p>
    <div *ngIf="requestPacksDone" class="subscribe mx-auto pt-4">
      <button
        class="btn btn-warning rounded-0 border-0 d-block mt-3 w-100"
        (click)="subscribe({ data: { title: 'pack' }, type: 'pack' })"
      >
        {{ 'Subscribe' | translate }}
      </button>
    </div>
  </div>
</section>

<section class="container-fluid lets-go">
  <div class="container px-lg-5 text-center">
    <p class="text-go text-60">{{ 'LetsGoNow' | translate }}</p>
    <p class="go-condition mb-5">{{ 'HomeBecomeMentorTextGoingOn' | translate }}</p>
    <div class="space-go">
      <a [routerLink]="['/authentication', 'login']" class="btn d-block rounded-0">{{
        'LetStart' | translate
      }}</a>
    </div>
  </div>
</section>
