<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div class="tabcontent" *ngIf="requestDone">
  <div
    class="pb-3 {{ indexOfelement === helpList.length - 1 ? 'blurField' : '' }}"
    *ngFor="
      let information of helpList | paginate : { itemsPerPage: 5, currentPage: p };
      let indexOfelement = index
    "
  >
    <p class="service">
      {{ information.title }}
      <button
        class="ms-3 btn btn-light rounded-5"
        matTooltip="conditons d'éligibilité"
        (click)="openDialogDetails(information?.requirements)"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0C3.59001 0 0 3.59001 0 8C0 12.4123 3.59001 16 8 16C12.4123 16 16 12.41 16 8C16 3.59001 12.4123 0 8 0ZM8 14.8516C4.22165 14.8516 1.14844 11.7784 1.14844 8C1.14844 4.22165 4.22165 1.14844 8 1.14844C11.7784 1.14844 14.8516 4.22165 14.8516 8C14.8516 11.7784 11.7784 14.8516 8 14.8516Z"
            fill="#000033"
          />
          <path d="M8.57422 6.93164H7.42578V12.5567H8.57422V6.93164Z" fill="#000033" />
          <path d="M8.57422 3.73633H7.42578V5.53018H8.57422V3.73633Z" fill="#000033" />
        </svg>
      </button>
    </p>
    <div
      [attr.id]="'information-' + information.id"
      class="service-about"
      [class.hideText]="content.scrollHeight > 75"
      #content
    >
      <span>{{ information.description }}</span>
    </div>
    <button
      [attr.id]="'btn-more-' + information.id"
      *ngIf="content.scrollHeight > 75 && indexOfelement < helpList.length - 1"
      class="show-button rounded"
      (click)="onShowClick(information)"
    >
      Afficher plus
      <i class="ms-2 bi bi-chevron-down" style="color: inherit"></i>
    </button>
    <button
      [attr.id]="'btn-less-' + information.id"
      *ngIf="content.scrollHeight > 75 && indexOfelement < helpList.length - 1"
      class="show-button rounded"
      style="display: none"
      (click)="onShowClick(information)"
    >
      Afficher moins
      <i class="ms-2 bi bi-chevron-up" style="color: inherit"></i>
    </button>
    <div class="d-flex py-2 pe-lg-6">
      <a [href]="information.usefulLink" class="btn btn-login" target="_blank"
        >{{ "J'en profite" | translate }}
      </a>
    </div>
    <br />
  </div>
  <!-- <div class="pt-4" *ngIf="helpList.length > 0 && requestDone">
    <pagination-controls
      class="custom-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div> -->
  <div class="text-center p-5" *ngIf="helpList.length === 0 && requestDone">
    <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
    <p class="mb-0 fs-6">{{ 'noResultsText' | translate }}</p>
  </div>
</div>
