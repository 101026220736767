import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-become-mentor',
  templateUrl: './become-mentor.component.html',
  styleUrls: ['./become-mentor.component.scss']
})
export class BecomeMentorComponent {

  @Input()
  entete: string = '';

  @Input()
  descriptionMentor: string = '';

  @Input()
  titre: string = '';

  @Input()
  sousTitre: string = '';

  @Input()
  libelleButon: string = '';

  constructor(private router: Router) { }

  commencer() {
    switch (this.libelleButon) {
      case "Commencer":
        this.router.navigate(['/authentication', 'login']);
        break;
      case 'Contactez-nous':
        this.router.navigate(['/contact-us']);
        break;
      default:
        break
    }
  }

}
