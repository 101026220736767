import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Prospect } from '../models/prospects';

@Injectable({
  providedIn: 'root',
})
export class ProspectsService {
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient, private translate: TranslateService) {}

  sendProspects(prospect: Prospect): Observable<any> {
    return this.http.post(`${this.apiURL}/prospect`, prospect);
  }
}
