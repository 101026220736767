<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div class="container-fluid" *ngIf="requestDone">
  <div *ngIf="dataBlog" class="section-bg row justify-content-center align-items-center pb-5">
    <div class="container my-3">
      <div class="card rounded-0 p-5">
        <div class="row d-flex flex-column align-items-center">
          <div class="col-md-6">
            <img [src]="dataBlog.imageUrl" class="img-fluid w-100 h-100" alt="" />
          </div>
          <!--          <div class="col-md-6 pt-2 px-2 d-flex align-items-center" [innerHTML]="dataBlog.blogContent | safeHtml"></div>-->
          <div class="col-md-12 pt-5 px-2" [innerHTML]="dataBlog.blogContent | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="blogSuggestionList.length > 0"
    class="section2-bg row justify-content-center align-items-center"
  >
    <div class="col-12 py-4">
      <div class="container">
        <div class="row">
          <h1 class="grostitre lh-1" style="color: #ffff">Consultez aussi</h1>
          <div class="col-12">
            <div class="container-fluid"></div>
            <div class="section2-bg row pb-5" *ngIf="blogSuggestionList.length > 0">
              <div class="col-lg-12 py-3" *ngFor="let blog of blogSuggestionList">
                <div class="row">
                  <div class="col-lg-5 col-md-6 col-sm-12">
                    <a *ngIf="blog?.blogUrl; else blogContent" href="{{ blog?.blogUrl }}">
                      <img
                        src="{{ blog.imageUrl }}"
                        style="height: 295px !important"
                        class="w-100"
                        alt=""
                      />
                    </a>
                    <ng-template #blogContent>
                      <a (click)="goToDefinition(blog?.id)" style="cursor: pointer">
                        <img
                          src="{{ blog.imageUrl }}"
                          style="height: 295px !important"
                          class="w-100"
                          alt=""
                        />
                      </a>
                    </ng-template>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12" style="margin-left: 0">
                    <div class="row pt-2 pt-md-0">
                      <div class="col-12">
                        <a class="titre" (click)="goToDefinition(blog?.id)">{{ blog.title }}</a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-auto">
                        <p class="contenu" style="color: #ffff">
                          {{ blog.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <a [routerLink]="['/blog']">Plus d'actus</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
