import { Component } from '@angular/core';

@Component({
  selector: 'app-trust-persons',
  templateUrl: './trust-persons.component.html',
  styleUrls: ['./trust-persons.component.scss']
})
export class TrustPersonsComponent {

  constructor() { }

}
