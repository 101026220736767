import { Component } from '@angular/core';

@Component({
  selector: 'app-charte-rgpd',
  templateUrl: './charte-rgpd.component.html',
  styleUrls: ['./charte-rgpd.component.scss']
})
export class CharteRgpdComponent {

  constructor() { }

}
