export class getProfileUser {
  static readonly type = '[Account profile] fetch user info';
}

export class setProfileUser {
  static readonly type = '[Account profile] set user info';
  constructor(public payload: any, public id: any){}
}

export class getLifeStyle {
  static readonly type = '[life style] fetch life style info';
  constructor(public id: any){}
}

export class setLifeStyle {
  static readonly type = '[life style] set life style info';
  constructor(public payload: any, public id: any){}
}

export class addLifeStyle {
  static readonly type = '[life style] add life style info';
  constructor(public payload: any){}
}

export class addProfessionnalInformation {
  static readonly type = '[professionnal information] add professionnal info';
  constructor(public payload: any){}
}

export class setProfessionnalInformation {
  static readonly type = '[professionnal information] set professionnal info';
  constructor(public id: string, public payload: any){}
}
export class deleteProfessionnalInformation {
  static readonly type = '[professionnal information] delete professionnal info';
  constructor(public id: string){}
}

export class addFormationInformation {
  static readonly type = '[formation information] add formation info';
  constructor(public payload: any){}
}

export class setFormationInformation {
  static readonly type = '[formation information] set formation info';
  constructor(public id: string, public payload: any){}
}
export class deleteFormationInformation {
  static readonly type = '[formation information] delete formation info';
  constructor(public id: string){}
}

export class SetLoading {
  static readonly type = '[Account profile] SetLoading';
  constructor() { }
}

export class SetLoaded<T>{
  static readonly type = '[Account profile] SetLoaded';
  constructor(public payload: T) { }
}
