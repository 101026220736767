<section class="container-fluid banner-mentor d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p class="banner-label-mentor text-60">
            {{ 'BecomeMentorCardTitle' | translate }}
          </p>
        </div>
      </div>
    </div>
</section>
<section class="container-fluid pilink-desc py-5">
  <div class="container">
    <p class="text-desc">
      {{ 'BecomeMentorPage.SectionA.textA' | translate }}
      <br> <br>
      {{ 'BecomeMentorPage.SectionA.textB' | translate }}
    </p>
  </div>
</section>
<section class="container-fluid mentor-desc" style="padding: 100px 0">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <p class="whatismentor text-60">{{ 'BecomeMentorPage.SectionB.textA' | translate }}</p>
      </div>
      <div class="col-md-7 position-relative">
        <hr class="w-100 bg-dark position-absolute top-0">
      </div>
    </div>
    <div class="row pt-5 d-flex justify-content-center">
      <div class="col-md-7">
        <p class="text-mentor-desc">
          {{ 'BecomeMentorPage.SectionB.textB' | translate }}
        </p>
      </div>
      <div class="col-md-5 d-md-block d-none pt-3 text-center position-relative">
        <svg width="10" height="10" style="position: absolute; top: 15%" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4.69629" width="6.64087" height="6.64087" transform="rotate(45 4.69629 0)" fill="#F6F2FF"/>
        </svg>
        <svg width="20" height="20" style="position: absolute; top: 18%; right: 40%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="9.7832" width="13.8357" height="13.8357" transform="rotate(45 9.7832 0)" fill="#F6F2FF"/>
        </svg>
        <svg class="svg-questionMark" style="width: 300px; height: 300px; transform: rotate(10deg); position: absolute; top: 25%; left: 0">
          <circle cx="100" cy="100" r="80" stroke-width="3" fill="#8542DB" />
          <text fill="#FFFFFF" x="32%" y="47%" class="questionMark" text-anchor="middle" stroke-width="2px" dy=".1em">?</text>
        </svg>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid mentor-profile py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <p class="label-mentor-profile text-60 text-white py-3 ps-4">
          {{ 'BecomeMentorPage.SectionC.textA' | translate }}
        </p>
        <ul class="list-mentor-profile text-white">
          <li>
            {{ 'BecomeMentorPage.SectionC.textB' | translate }}
          </li>
          <li class="py-3">
            {{ 'BecomeMentorPage.SectionC.textC' | translate }}
          </li>
          <li>
            {{ 'BecomeMentorPage.SectionC.textD' | translate }}
          </li>
        </ul>
      </div>
      <div class="col-md-5 d-md-block d-none position-relative">
        <svg height="100" width="100" style="position: absolute; top: 2%; right: 10%;">
          <circle cx="50" cy="50" r="40"  stroke-width="3" fill="#FFCC33" />
        </svg>
        <svg width="25" height="25" style="position: absolute; top: 15%; right: 5%; transform: rotate(45deg);">
          <rect width="20" height="20" fill="#F7F4FF"/>
        </svg>
        <svg width="12" height="12" style="position: absolute; bottom: 15%; right: 4%; transform: rotate(45deg);">
          <rect width="10" height="10" fill="#FFCC33"/>
        </svg>
        <svg height="100" width="100" style="position: absolute; bottom: 5%; right: 10%;">
          <circle cx="50" cy="50" r="40"  stroke-width="3" fill="#F7F4FF" />
        </svg>
        <svg width="12" height="12" style="position: absolute; bottom: 0; right: 2%; transform: rotate(45deg);">
          <rect width="10" height="10" fill="#FFCC33"/>
        </svg>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid why-become-mentor py-5 bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <p class="label-become-mentor text-60 text-dark py-3 ps-4">
          {{ 'BecomeMentorPage.SectionD.textA' | translate }}
        </p>
        <ul class="list-become-mentor text-dark list-unstyled">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill check-success" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg> &nbsp;
            {{ 'BecomeMentorPage.SectionD.textB' | translate }}
          </li>
          <li class="py-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill check-success" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg> &nbsp;
            {{ 'BecomeMentorPage.SectionD.textC' | translate }}
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill check-success" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg> &nbsp;
            {{ 'BecomeMentorPage.SectionD.textD' | translate }}
          </li>
          <li class="pt-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill check-success" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg> &nbsp;
            {{ 'BecomeMentorPage.SectionD.textE' | translate }}
          </li>
        </ul>
      </div>
      <div class="col-md-5 d-md-block d-none position-relative">
        <svg height="100" width="100" style="position: absolute; top: 2%; right: 10%;">
          <circle cx="50" cy="50" r="40"  stroke-width="3" fill="#FFCC33" />
        </svg>
        <svg width="25" height="25" style="position: absolute; top: 15%; right: 5%; transform: rotate(45deg);">
          <rect width="20" height="20" fill="#6633CC"/>
        </svg>
        <svg width="12" height="12" style="position: absolute; bottom: 25%; right: 15%; transform: rotate(45deg);">
          <rect width="10" height="10" fill="#FFCC33"/>
        </svg>
        <svg height="100" width="100" style="position: absolute; bottom: 5%; right: 10%;">
          <circle cx="50" cy="50" r="40"  stroke-width="3" fill="#6633CC" />
        </svg>
        <svg width="12" height="12" style="position: absolute; bottom: 0; right: 2%; transform: rotate(45deg);">
          <rect width="10" height="10" fill="#FFCC33"/>
        </svg>
      </div>
    </div>
  </div>
</section>
<section class="container-fluid lets-go-mentor" *ngIf="!userIsAuth">
  <div class="container text-center">
    <p class="text-go text-60 mb-4">
      {{ 'BecomeMentorPage.SectionE.textA' | translate }}
    </p>
    <div class="space-go pt-2 pb-2">
      <a [routerLink]="['/authentication', 'register']" class="btn d-block rounded-0">
        {{ 'BecomeMentorPage.SectionE.textB' | translate }}
      </a>
    </div>
  </div>
</section>
