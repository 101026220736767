import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProfessionalInfo, SubscriptionType } from 'src/app/core/enum/enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import {
  CheckoutPayload,
  PaymentStatus,
  StripeInitiatePaymentResponse,
} from '../components/common/checkout/typings';
import { ApiResponse } from 'src/app/core/typings';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/core/models/user';
import { Lifestyle } from 'src/app/core/models/lifestyle';
import { ProfessionalInformation } from 'src/app/core/models/professionalInformation';
import { BusinessField } from 'src/app/core/models/businessField';
import { TrainingInformation } from 'src/app/core/models/trainingInformation';
import { ReferralInfo } from 'src/app/core/models/referralInfo';
import {
  CreateOrderPayload,
  FormDetail,
  Mission,
  Order,
  OrderCheckoutSummary,
  OrderProductDetaiProcessFile,
  OrderType,
  ProductSubscription,
} from '../typings';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiURL = environment.apiUrl;

  private profileSubject$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private sharedService: SharedService,
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  getReferralInfo() {
    return this.http.get<ReferralInfo>(`${this.apiURL}/user/referral-info`);
  }

  getBusinessFields() {
    return this.http.get<BusinessField[]>(`${this.apiURL}/info/business-field`);
  }
  getProfile() {
    return this.http.get<User>(`${this.apiURL}/user`);
  }

  updateProfile(data: any) {
    return this.http.patch<User>(`${this.apiURL}/user`, data);
  }

  getLifestyle() {
    return this.http.get<Lifestyle>(`${this.apiURL}/user/lifestyle`);
  }

  updateLifestyle(data: any) {
    return this.http.patch(`${this.apiURL}/user/lifestyle`, data);
  }

  getProfessionalInformationList() {
    return this.http.get<ProfessionalInformation[]>(`${this.apiURL}/user/professional-information`);
  }

  createProfessionalInformation(data: any) {
    return this.http.post<ProfessionalInformation>(
      `${this.apiURL}/user/professional-information`,
      data
    );
  }

  updateProfessionalInformation(id: number, data: any) {
    return this.http.patch<ProfessionalInformation>(
      `${this.apiURL}/user/professional-information/${id}`,
      data
    );
  }

  deleteProfessionalInformation(id: number) {
    return this.http.delete(`${this.apiURL}/user/professional-information/${id}`);
  }

  getTrainingInformationList() {
    return this.http.get<TrainingInformation[]>(`${this.apiURL}/user/training-information`);
  }

  createTrainingInformation(data: any) {
    return this.http.post<TrainingInformation>(`${this.apiURL}/user/training-information`, data);
  }

  updateTrainingInformation(id: number, data: any) {
    return this.http.patch<TrainingInformation>(
      `${this.apiURL}/user/training-information/${id}`,
      data
    );
  }

  deleteTrainingInformation(id: number) {
    return this.http.delete(`${this.apiURL}/user/training-information/${id}`);
  }

  updateProfileImage(image: any) {
    return this.http.patch<User>(`${this.apiURL}/user/profile-image/`, image);
  }

  deleteProfileImage() {
    return this.http.delete<User>(`${this.apiURL}/user/profile-image/`);
  }

  checkIsAlreadyOrdered(type: OrderType, id?: number) {
    return this.http.get<ApiResponse<{ alreadyOrdered: boolean; tagSlugs: string[] }>>(
      `${this.apiURL}/order/already-ordered?type=${type}&id=${id}`
    );
  }

  createCareerRequest(data: any) {
    return this.http.post(`${this.apiURL}/order/request/career/`, data);
  }
  createHousingRequest(data: any) {
    return this.http.post(`${this.apiURL}/order/request/housing/`, data);
  }

  createHousingCertificateRequest(data: any) {
    return this.http.post(`${this.apiURL}/order/request/housing-certificate/`, data);
  }
  createIntegrationRequest(data: any) {
    return this.http.post(`${this.apiURL}/order/request/integration/`, data);
  }
  createCampusFranceRequest(data: any) {
    return this.http.post(`${this.apiURL}/order/request/campus-france/`, data);
  }

  createOrder(data: CreateOrderPayload) {
    return this.http.post<{ checkoutImmediately?: boolean; orderId: number }>(
      `${this.apiURL}/order`,
      data
    );
  }

  getOrderFormDetails(orderId: number) {
    return this.http.get<FormDetail>(`${this.apiURL}/order/${orderId}/form-details`);
  }

  getOrderDetails(orderId: number) {
    return this.http.get<OrderCheckoutSummary>(`${this.apiURL}/order/${orderId}/checkout-summary`);
  }

  checkoutOrder(orderId: number, payload: CheckoutPayload) {
    return this.http.post<StripeInitiatePaymentResponse>(
      `${this.apiURL}/order/${orderId}/checkout`,
      payload
    );
  }

  requestManualPaymentOnOrder(orderId: number) {
    return this.http.post<any>(`${this.apiURL}/order/${orderId}/request-manual-payment`, {});
  }

  uploadFilesToOrderProductDetail(orderProductDetailId: number, files: File[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    return this.http.post(`${this.apiURL}/missions/${orderProductDetailId}/files`, formData);
  }

  getOrderProductDetailFiles(orderProductDetailId: number) {
    return this.http.get<OrderProductDetaiProcessFile[]>(
      `${this.apiURL}/missions/${orderProductDetailId}/files`
    );
  }

  deleteOrderProductDetailFile(orderProductDetailId: number, fileId: number) {
    return this.http.delete(`${this.apiURL}/missions/${orderProductDetailId}/files/${fileId}`);
  }
  //***************************LEGACY BELOW */
  getProfileSubject(): Observable<string> {
    return this.profileSubject$.asObservable();
  }

  setProfileSubject(profile: string) {
    this.profileSubject$.next(profile);
  }
  get lang() {
    return this.translate.currentLang ?? this.translate.defaultLang;
  }

  getHelpsInFranceFreemium(): Observable<any> {
    return this.http.get(`${this.apiURL}/helps/free/plan`);
  }

  getHelpsInFrancePremium(): Observable<any> {
    return this.http.get(`${this.apiURL}/helps`);
  }

  getAllModules(params?: HttpParams): Observable<any> {
    let api = `${this.apiURL}/product`;

    if (params) {
      return this.http.get(api, { params: params });
    } else {
      return this.http.get(api);
    }
  }

  // getProductDetails(id: number | string): Observable<any> {
  //   let api = `${this.apiURL}/product/${id}`;
  //   return this.http.get(api);
  // }

  updateGeneralInfos(id: any, data: any): Observable<any> {
    let api = `${this.apiURL}/users/${id}/generalInfo/user`;
    return this.http.put(api, data);
  }

  updateLifeStyle(id: any, data: any): Observable<any> {
    let api = `${this.apiURL}/lifeStyle/user/${id}`;
    return this.http.put(api, data);
  }

  getLifeStyleByUser(idUser: string): Observable<any> {
    let api = `${this.apiURL}/lifeStyle/user/${idUser}`;
    return this.http.get(api);
  }

  addLifeStyle(data: any): Observable<any> {
    let api = `${this.apiURL}/lifeStyle`;
    return this.http.post(api, data);
  }

  addProfessionnalInformation(data: any): Observable<any> {
    let api = `${this.apiURL}/professionnalInformation`;
    return this.http.post(api, data);
  }

  updateProfessionnalInformation(id: string, data: any): Observable<any> {
    let api = `${this.apiURL}/professionnalInformation/${id}`;
    return this.http.put(api, data);
  }

  deleteProfessionnalInformation(id: string): Observable<any> {
    let api = `${this.apiURL}/professionnalInformation/${id}`;
    return this.http.delete(api);
  }

  addFormationInformation(data: any): Observable<any> {
    let api = `${this.apiURL}/formationInformation`;
    return this.http.post(api, data);
  }

  updateFormationInformation(id: string, data: any): Observable<any> {
    let api = `${this.apiURL}/formationInformation/${id}`;
    return this.http.put(api, data);
  }

  deleteFormationInformation(id: string): Observable<any> {
    let api = `${this.apiURL}/formationInformation/${id}`;
    return this.http.delete(api);
  }

  getDeadLinesList(numberPayment: number, price: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let tabDeadLines: any[] = [];
      const convertDate = function (date = new Date()) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
          .toISOString()
          .split('T')[0];
      };
      const addMonthsToDate = function (numOfMonths: number, date = new Date()) {
        if (numOfMonths == 0) {
          return date;
        } else {
          date.setMonth(date.getMonth() + numOfMonths);
          return date;
        }
      };
      const handler = function (size: number) {
        if (size == 0) {
          resolve(tabDeadLines);
        } else {
          tabDeadLines.push({
            paymentDate: convertDate(addMonthsToDate(numberPayment - size)),
            priceTTC: price,
          });
          handler(size - 1);
        }
      };
      handler(numberPayment);
    });
  }

  housingRequest(payment: any): Observable<any> {
    let api = `${this.apiURL}/housingRequests?lang=${
      this.translate.currentLang ?? this.translate.defaultLang
    }`;
    return this.http.post(api, payment);
  }

  submitSubscriptionByPayPal(
    payment: any | null,
    PayerID: string | null,
    paymentId: string | null
  ): Observable<any> {
    let api = ``;
    switch (payment.subscriptionType) {
      case SubscriptionType.PACK:
        api = `${this.apiURL}/packSubscriptions?PayerID=${PayerID}&paymentId=${paymentId}&lang=${
          this.translate.currentLang ?? this.translate.defaultLang
        }`;
        break;
      case SubscriptionType.MODULE:
        api = `${this.apiURL}/moduleSubscriptions?PayerID=${PayerID}&paymentId=${paymentId}&lang=${
          this.translate.currentLang ?? this.translate.defaultLang
        }`;
        break;
      case SubscriptionType.ONLINE:
        api = `${this.apiURL}/onlineSubscriptions?PayerID=${PayerID}&paymentId=${paymentId}&lang=${
          this.translate.currentLang ?? this.translate.defaultLang
        }`;
        break;

      default:
        break;
    }
    return this.http.post(api, payment);
  }

  initiatePaymenPayPal(objectSubscription: any, type: string): Observable<any> {
    let api = ``;
    switch (type) {
      case SubscriptionType.PACK:
        api = `${this.apiURL}/packSubscriptions/initiatePayment/paypal?lang=${
          this.translate.currentLang ?? this.translate.defaultLang
        }`;
        break;
      case SubscriptionType.ONLINE:
        api = `${this.apiURL}/onlineSubscriptions/initiatePayment/paypal?lang=${
          this.translate.currentLang ?? this.translate.defaultLang
        }`;
        break;
      case SubscriptionType.MODULE:
        api = `${this.apiURL}/moduleSubscriptions/initiatePayment/paypal?lang=${
          this.translate.currentLang ?? this.translate.defaultLang
        }`;
        break;

      default:
        break;
    }
    return this.http.post(api, objectSubscription);
  }

  getAllPacks(params?: HttpParams): Observable<any> {
    let api = `${this.apiURL}/product/pack`;
    if (params) {
      return this.http.get(api, { params: params });
    } else {
      return this.http.get(api);
    }
  }

  getPaymentInfo(paymentId: string): Observable<ApiResponse<{ paymentStatus: PaymentStatus }>> {
    return this.http.get(`${this.apiURL}/shared/payments/${paymentId}`);
  }

  checkIsAlreadySubscribed(type: string) {
    return this.http.get<boolean>(`${this.apiURL}/subscription/already-subscribed`, {
      params: { type },
    });
  }

  cancelSubscription(subscriptionId: number): Observable<any> {
    return this.http.post(`${this.apiURL}/subscription/${subscriptionId}/cancel`, {});
  }

  revertCancelSubscription(subscriptionId: number): Observable<any> {
    return this.http.post(`${this.apiURL}/subscription/${subscriptionId}/revert-cancel`, {});
  }

  getActiveSubscriptions() {
    return this.http.get<ProductSubscription[]>(`${this.apiURL}/subscription`);
  }

  getAllOrdersByIdClient(params?: HttpParams): Observable<Order[]> {
    return this.http.get<Order[]>(`${this.apiURL}/order`);
  }

  getProfileWithDetailsByIdUser(): Observable<any> {
    // let user: any = localStorage.getItem('currentUser')
    //   ? this.sharedService.decryptJson(localStorage.getItem('currentUser'))
    //   : null;
    // let api = `${this.apiURL}/shared/profile/${user._id}`;
    // if (this.translate?.currentLang) {
    //   api = `${this.apiURL}/shared/profile/${user._id}?lang=${
    //     this.translate.currentLang ?? this.translate.defaultLang
    //   }`;
    // }
    return this.http.get('');
  }

  getFileName(urlFile: string) {
    const startStr = '.amazonaws.com/';
    const endStr = '?';
    const pos = urlFile.indexOf(startStr) + startStr.length;
    const file = urlFile.substring(pos, urlFile.indexOf(endStr, pos)).split('-')[1];
    return decodeURI(file);
  }

  getUserMissions(params?: any): Observable<Mission[]> {
    return this.http.get<Mission[]>(`${this.apiURL}/missions/ongoing/client`);
  }

  getMentorMissions(params?: any): Observable<Mission[]> {
    return this.http.get<Mission[]>(`${this.apiURL}/missions/ongoing/mentor`);
  }

  getUserPurchases(params?: any): Observable<any> {
    let user: any = localStorage.getItem('currentUser')
      ? this.sharedService.decryptJson(localStorage.getItem('currentUser'))
      : null;
    let api = `${this.apiURL}/order/purchases`;

    if (params) {
      return this.http.get(api, { params: params });
    } else {
      return this.http.get(api);
    }
  }

  updateModuleSubscriptionStep(id: any, data: any): Observable<any> {
    let api = `${this.apiURL}/missions/step-validation/${id}`;
    return this.http.patch(api, data);
  }

  getModuleSubscriptionById(id: any): Observable<any> {
    return this.http.get(`${this.apiURL}/moduleSubscriptions/${id}`);
  }

  updateMentorInformation(data: any): Observable<any> {
    let user: any = localStorage.getItem('currentUser')
      ? this.sharedService.decryptJson(localStorage.getItem('currentUser'))
      : null;

    let api = `${this.apiURL}/mentorInformation/${user._id}`;
    return this.http.put(api, data);
  }

  searchHelps(body: any): Observable<any> {
    let api = `${this.apiURL}/helps/search`;
    if (this.translate?.currentLang) {
      api = `${this.apiURL}/helps/search?lang=${
        this.translate.currentLang ?? this.translate.defaultLang
      }`;
    }
    return this.http.post(api, body);
  }

  ///////metadataFile////////

  getMetadataFileByUser(id: string): Observable<any> {
    return this.http.get(`${this.apiURL}/metadatafiles/${id}`);
  }

  addMetadataFile(metadata: any): Observable<any> {
    let api = `${this.apiURL}/metadatafiles`;
    return this.http.post(api, metadata);
  }

  deleteMetadataFile(id: string): Observable<any> {
    let api = `${this.apiURL}/metadatafiles/${id}`;
    return this.http.delete(api);
  }

  getStats(): Observable<any> {
    let user: any = localStorage.getItem('currentUser')
      ? this.sharedService.decryptJson(localStorage.getItem('currentUser'))
      : null;
    return this.http.get(`${this.apiURL}/business_provider/${user._id}/statistics`);
  }
}
