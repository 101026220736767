import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ProductInfo } from '../models/productInfo';
import { Product } from '../models/product';
import { Pack } from '../models/pack';

@Injectable({
  providedIn: 'root',
})
export class RatesService {
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient, private translate: TranslateService) {}

  getAllProducts() {
    return this.http.get<Product[]>(`${this.apiURL}/product`);
  }

  getAlProductInfo() {
    return this.http.get<ProductInfo[]>(`${this.apiURL}/product/info`);
  }

  getServiceByReferenceId(params?: HttpParams | null, service?: string): Observable<any> {
    let api = `${this.apiURL}/moduleOverviews/${service}`;
    if (this.translate?.currentLang) {
      api = `${this.apiURL}/moduleOverviews?lang=${
        this.translate.currentLang ?? this.translate.defaultLang
      }`;
    }
    if (params) {
      return this.http.get(api, { params: params });
    } else {
      return this.http.get(api);
    }
  }

  getAllPacks() {
    return this.http.get<Pack[]>(`${this.apiURL}/product/pack`);
  }
}
