<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div class="tabcontent" *ngIf="requestDone">
  <div
    class="pb-3 {{ indexOfelement === trickList.length - 1 ? 'blurField' : '' }}"
    *ngFor="
      let information of trickList | paginate : { itemsPerPage: 5, currentPage: p };
      let indexOfelement = index
    "
  >
    <div class="info row">
      <div class="img-side col-4">
        <img
          src="{{ information?.imageUrl }}"
          alt="Img"
          *ngIf="information.imageUrl !== null; else noImageFound"
        />
        <ng-template #noImageFound>
          <img src="assets/img/sorry.svg" alt="Fallbackimage" style="width: 8rem" />
        </ng-template>
      </div>
      <div class="text-side col-8">
        <p class="service">
          {{ information.title }}
        </p>
        <div
          [attr.id]="'information-' + information.id"
          class="service-about"
          [class.hideText]="content.scrollHeight > 75"
          #content
        >
          <span>{{ information.description }}</span>
        </div>
        <button
          [attr.id]="'btn-more-' + information.id"
          *ngIf="content.scrollHeight > 75"
          class="show-button rounded"
          (click)="onShowClick(information)"
        >
          Afficher plus
          <i class="ms-2 bi bi-chevron-down" style="color: inherit"></i>
        </button>
        <button
          [attr.id]="'btn-less-' + information.id"
          *ngIf="content.scrollHeight > 75"
          class="show-button rounded"
          style="display: none"
          (click)="onShowClick(information)"
        >
          Afficher moins
          <i class="ms-2 bi bi-chevron-up" style="color: inherit"></i>
        </button>
        <br />
        <br />
      </div>
    </div>
    <div *ngFor="let link of information.usefulLink" class="d-flex py-2 pe-lg-5">
      <a [href]="link" class="btn btn-login">{{ "J'en profite" | translate }} </a>
    </div>
  </div>
  <!-- <div class="pt-4" *ngIf="trickList.length > 0 && requestDone">
    <pagination-controls
      class="custom-pagination"
      (pageChange)="p = $event"
    ></pagination-controls>
  </div> -->
  <div class="text-center p-5" *ngIf="trickList.length === 0 && requestDone">
    <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
    <p class="mb-0 fs-6">{{ 'noResultsText' | translate }}</p>
  </div>
</div>
