import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import indicatorCountries from '../dataCountries.json';
import { CountryIndicator } from '../typings';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private keyEncrypt = 'f3eb7825-673d-451e-8531-6852ca5576dd';
  constructor() {}

  encryptJson(object: any) {
    let objectEncrypt = encodeURIComponent(
      CryptoJS.AES.encrypt(JSON.stringify(object), this.keyEncrypt).toString()
    );
    return objectEncrypt;
  }

  encryptString(stringParams: any) {
    let stringEncrypt = encodeURIComponent(
      CryptoJS.AES.encrypt(stringParams, this.keyEncrypt).toString()
    );
    return stringEncrypt;
  }

  decryptString(stringEncrypt: any) {
    const deData = CryptoJS.AES.decrypt(decodeURIComponent(stringEncrypt), this.keyEncrypt);
    let stringDecrypt = deData.toString(CryptoJS.enc.Utf8);
    return stringDecrypt;
  }

  decryptJson(objectEncrypt: any) {
    const deData = CryptoJS.AES.decrypt(decodeURIComponent(objectEncrypt), this.keyEncrypt);
    let objectDecrypt = JSON.parse(deData.toString(CryptoJS.enc.Utf8));
    return objectDecrypt;
  }

  getIndicatorCountries(): CountryIndicator[] {
    return indicatorCountries;
  }
}
