import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { AuthState } from 'src/app/authentication/typings';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  userIsAuth: boolean = false;
  loaderBtn = false;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.authState.subscribe((state) => {
      switch (state) {
        case AuthState.AUHTENTICATED:
          this.userIsAuth = true;
          break;
        case AuthState.NOT_AUTHENTICATED:
          this.userIsAuth = false;
      }
    });
  }

  hideNavbar(e: any) {
    e.classList.toggle('show');
  }

  async logout() {
    await this.authService.signOutAndNavigate();
    this.loaderBtn = true;
    this.loaderBtn = false;
  }

  getFlagClass(lang: any) {
    switch (lang) {
      case 'fr': {
        return 'fs-6 flag-icon flag-icon-fr';
      }
      case 'en': {
        return 'fs-6 flag-icon flag-icon-us';
      }
      default: {
        return 'fs-6 flag-icon flag-icon-fr';
      }
    }
  }

  switchLanguage(lang: any) {
    this.translate.use(lang);
  }

  getLanguage(lang: string) {
    switch (lang) {
      case 'fr': {
        return 'Français';
      }
      case 'en': {
        return 'Anglais';
      }
      default: {
        return 'Français';
      }
    }
  }
}
