import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/services/shared.service';
import Swal from 'sweetalert2';
import { ResetPasswordService } from '../../services/reset-password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotForm: FormGroup;
  validation = false;
  submitted = false;
  loaderBtn = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    public formBuilder: FormBuilder,
    public resetService: ResetPasswordService,
    private sharedService: SharedService
  ) {
    this.forgotForm = new FormGroup({
      email: new FormControl(null, {
        validators: [Validators.required, Validators.email],
        updateOn: 'submit',
      }),
    });
  }

  get email() {
    return this.forgotForm.get('email')!;
  }

  forgotPassword() {
    this.submitted = true;
    this.forgotForm.markAllAsTouched();
    if (this.forgotForm.valid) {
      this.loaderBtn = true;
      this.resetService
        .sendMail(this.forgotForm.value['email'])
        .subscribe({
          next: (res) => this.sendEmail(res),
          error: (e) => this.errorMessage(e),
        })
        .add(() => {
          this.completeRequest();
        });
    }
  }

  sendEmail = (res: any) => {
    this.toastr.success(
      'Nous vous avons envoyé un email de réinitialisation de mot de passe.',
      'Succès'
    );
    this.submitted = false;
    this.forgotForm.reset();
  };

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Ce mail est inexistant dans notre base de données.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        let errorMessage = err.error.errors
          .map((x: any) => {
            if (typeof x == 'object') {
              return x?.message;
            } else {
              return x;
            }
          })
          .join(', ');
        this.toastr.error(errorMessage, 'Erreur');
        Swal.fire({
          icon: 'error',
          title: 'Erreur',
          text: errorMessage,
        });
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
        if (err.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
            footer: `<a routerLink="/authentication/login">Veuillez-vous reconnecter</a>`,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: err?.error?.message,
          });
        }
      }
    }
  };

  completeRequest = () => {
    this.loaderBtn = false;
  };
}
