<div class="container-fluid containerServiceComponent py-5">
  <div class="row">
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="head-title">{{ 'NosServices' | translate }}</p>
            <div *ngIf="loading" class="my-5 py-5">
              <mat-spinner class="mx-auto"></mat-spinner>
            </div>
            <div
              *ngIf="!loading"
              class="container-fluid pt-4 px-0 position-relative"
              fxLayout="column"
              fxLayoutAlign="space-between center"
            >
              <!-- start section prev button -->
              <img
                *ngIf="slideIndex > 0"
                src="assets/img/prev.png"
                class="prevIcon"
                alt="Prev"
                (click)="matgroup.selectedIndex = slideIndex - 1"
              />
              <!-- end section prev button -->
              <!-- start section next button -->
              <img
                *ngIf="slideIndex < 5"
                src="assets/img/next.png"
                class="nextIcon"
                alt="Next"
                (click)="matgroup.selectedIndex = slideIndex + 1"
              />
              <!-- end section next button -->
              <mat-tab-group
                class="container-fluid"
                #matgroup
                [@.disabled]="true"
                mat-align-tabs="center"
                dynamicHeight
                [selectedIndex]="slideIndex"
                (selectedTabChange)="tabChanged($event)"
              >
                <div *ngFor="let item of productInfoList; let i = index" [attr.data-index]="i">
                  <mat-tab label="{{ item.title }}">
                    <div class="container-fluid px-0 pt-0 px-lg-5 pt-lg-5 px-md-0 pt-md-0">
                      <div class="row px-2 px-lg-4 pt-lg-5 px-md-0 pt-md-0 pt-0">
                        <div class="col-lg-6 col-md-12 left-block">
                          <p class="fw-bold slideTitle text-60 lh-1">{{ item.title }}</p>
                          <p class="slideText pt-4">
                            {{ item?.description || '' }}
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <img
                            src="{{ item?.imageUrl || '' }}"
                            [ngClass]="{
                              'w-50': item.imageUrl.includes('slide2'),
                              'w-100': !item.imageUrl.includes('slide2')
                            }"
                            class="imgSlide3 d-block mx-auto img-fluid"
                            alt="{{ item.title }}"
                          />
                        </div>
                      </div>
                      <p class="howdidworks">{{ 'Howdidworks' | translate }}</p>
                      <p class="howdidworks-text">{{ item?.howItWorks || '' }}</p>
                      <p class="advantage">{{ 'Advantages' | translate }}</p>
                      <div class="row service-details">
                        <div class="col-md-12">
                          <ul class="list-unstyled">
                            <li
                              *ngFor="
                                let itemAdvantage of item.productInfoAdvantages;
                                let i = index
                              "
                              [attr.data-index]="i"
                            >
                              <svg
                                style="color: green"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-check-circle-fill check-success"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                />
                              </svg>
                              &nbsp; {{ itemAdvantage.description }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </div>
              </mat-tab-group>
            </div>
            <p class="slide3TextFooter1 fs-6 px-5 mt-5 lh-sm">
              {{ 'TextDetailsPricing' | translate }}
              <a
                class="slide3TextFooter2 text-decoration-underline"
                [routerLink]="['/rates']"
                [fragment]="labelIndexTab"
                [state]="labelIndexTab"
                >{{ 'LinkDetailsPricing' | translate }}</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
