import { AfterViewInit, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  largeScreen = false;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth > 575.98) {
      this.largeScreen = true;
    } else {
      this.largeScreen = false;
    }
  }

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.onWindowResize();
  }

  getFlagClass(lang: any) {
    switch (lang) {
      case 'fr': {
        return 'fs-6 flag-icon flag-icon-fr';
      }
      case 'en': {
        return 'fs-6 flag-icon flag-icon-us';
      }
      default: {
        return 'fs-6 flag-icon flag-icon-fr';
      }
    }
  }

  switchLanguage(lang: any) {
    this.translate.use(lang);
  }

  getLanguage(lang: string) {
    switch (lang) {
      case 'fr': {
        return 'Français';
      }
      case 'en': {
        return 'Anglais';
      }
      default: {
        return 'Français';
      }
    }

  }

}
