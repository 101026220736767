import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { BlogService } from '../../services/blog.service';
import { getAllBlogElements } from './blog.action';

export class BlogStateModel {
  elts: any;
}

@State<BlogStateModel>({
  name: 'blogState',
  defaults: {
    elts: [],
  },
})
@Injectable()
export class BlogState {
  constructor(private blogService: BlogService) {}

  @Selector()
  static selectBlogData(state: BlogStateModel) {
    return state.elts;
  }

  @Action(getAllBlogElements)
  getBlogsFromState(ctx: StateContext<BlogStateModel>) {
    return this.blogService.getBlogs().pipe(
      tap((blogData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          elts: blogData,
        });
      })
    );
  }
}
