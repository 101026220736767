import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit, AfterViewInit {

  constructor(private facebookService: FacebookService) { }

  ngOnInit(): void {
    this.initFacebookService();
  }

  ngAfterViewInit(): void {
    const chatbox = document.getElementById('fb-customer-chat');
    chatbox?.setAttribute("page_id", "100229916105404");
    chatbox?.setAttribute("attribution", "biz_inbox");
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml:true, version:'v15.0'};
    this.facebookService.init(initParams);
  }

}
