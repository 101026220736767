<div class="container-fluid pb-5">
  <div class="container pt-5">
    <p class="text-60 fw-bold text-center pt-3 pb-5">{{ 'FAQ' | translate }}</p>
    <div *ngIf="!requestDone" class="my-5 py-5">
      <mat-spinner class="mx-auto"></mat-spinner>
    </div>
    <ng-container *ngIf="requestDone">
      <div class="space-search-bar mb-4">
        <input
          type="search"
          (input)="onSearchChange($event)"
          placeholder="Recherchez une question par mots clés"
          [(ngModel)]="search"
        />
        <button
          class="btn btn-outline-light dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ sortCategory }} &nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-filter-left"
            viewBox="0 0 16 16"
          >
            <path
              d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a class="dropdown-item" style="cursor: pointer" (click)="checkItem('All')">Tous</a>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <div class="" *ngFor="let category of categoryList; let indexOfelement = index">
            <li>
              <a class="dropdown-item" style="cursor: pointer" (click)="checkItem(category.key)">{{
                category.name
              }}</a>
            </li>
            <li *ngIf="categoryList.length - 1 !== indexOfelement">
              <hr class="dropdown-divider" />
            </li>
          </div>
        </ul>
      </div>
      <div class="mt-5">
        <ng-container *ngFor="let faq of FaqList | paginate: { itemsPerPage: 10, currentPage: p }; let i = index">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ faq?.question }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p style="color: #6633cc">{{ faq?.answer }}</p>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
      <div class="pt-4" *ngIf="FaqList.length > 0 && requestDone">
        <pagination-controls
          class="custom-pagination"
          (pageChange)="p = $event"
        ></pagination-controls>
      </div>
    </ng-container>
    <div class="text-center p-5" *ngIf="FaqList && FaqList.length === 0 && requestDone">
      <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
      <p class="mb-0 fs-6">Aucun resultat</p>
    </div>
  </div>
</div>
