import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Blog } from '../models/blog';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  apiURL = environment.apiUrl;
  constructor(private http: HttpClient, private translate: TranslateService) {}

  getBlogs(title?: string): Observable<Blog[]> {
    const queryParams = new HttpParams();
    if (title) queryParams.append('title', title);
    return this.http.get<Blog[]>(`${this.apiURL}/blog`, { params: queryParams });
  }

  getBlogById(id: number): Observable<Blog> {
    return this.http.get<Blog>(`${this.apiURL}/blog/${id}`);
  }
}
