<div class="container-fluid HomeTrustPersons">
  <div class="container">
    <div class="purpose-space">
      <p class="purpose-text text-60 text-center">
        {{ 'TrustPersonPurposeText' | translate }}
      </p>
    </div>
    <div class="trust-persons-img mt-5 d-none d-md-block" data-aos="fade-up"
      data-aos-duration="1000">
      <img src="assets/img/homeTrustPerson1.png" class="img-fluid"> &nbsp;
      <img src="assets/img/homeTrustPerson2.png" class="trust-person-shift img-fluid" alt=""> &nbsp;
      <img src="assets/img/homeTrustPerson3.png" class="img-fluid" alt=""> &nbsp;
      <img src="assets/img/homeTrustPerson4.png" class="trust-person-shift img-fluid" alt=""> &nbsp;
      <img src="assets/img/homeTrustPerson5.png" class="img-fluid" alt="">
    </div>
    <p class="pt-5 text-center text-solution">{{ 'TrustPersonSolutionText' | translate }}</p>
    <div class="btn-action">
      <a [routerLink]="['/authentication', 'register']" class="btn btn-purple">{{ 'TrustPersonBtnActionText' | translate }}</a>
    </div>
  </div>
</div>
