<!-- Footer -->
<footer class="footer text-center text-lg-start text-white">
  <!-- Section: Links  -->
  <section class="p-2">
    <div class="container text-center text-md-start mt-5">
      <!-- Grid row -->
      <div class="row mt-3 justify-content-center">
        <!-- Grid column -->
        <div class="col-md-8 col-lg-8 col-xl-8 mb-4">
          <div class="row">
            <div class="col-md-8 col-12 col-lg-8 mb-4">
              <img style="width: 120px; height: 50px" src="assets/img/pilink-footer.png" />
              <div class="border-bottom mt-3" [style.width]="largeScreen ? '40%' : '100%'"></div>
            </div>
          </div>
          <p style="font-size: 16px; line-height: 22px">
            {{ 'ContactezNousDirectement' | translate }}
          </p>
          <div class="row d-flex lh-lg">
            <div class="col-md-auto col-lg-auto col-sm-auto">
              <h4 class="mb-0">{{ 'Phone' | translate }} :</h4>
              <span>+33 6 99 40 19 70</span><br />
              <span>+237 6 55 96 07 68</span>
            </div>
            <div class="col-md-auto col-lg-auto col-sm-auto">
              <h4 class="mb-0">Email :</h4>
              <a class="text-warning" href="mailto:contact@pi-link.fr">contact@pi-link.fr</a>
            </div>
            <div class="col-md-auto col-lg-auto col-sm-auto">
              <h4 class="mb-0">{{ 'France Location' | translate }} :</h4>
              <span>24 allée de la libération,</span><br />
              <span>57100 Thionville, France</span> 
            </div>

            <div class="col-md-auto col-lg-auto col-sm-auto">
              <h4 class="mb-0">{{ 'Cameroon Location' | translate }} :</h4>
              <span> Derrière le dispensaire Odza, </span><br />
              <span> Yaoundé, Cameroun</span>
            </div>
          </div>
          <div class="my-3">
            <a target="_blank" href="https://www.facebook.com/PILINKSAS/" class="me-4 text-reset">
              <img src="assets/img/icons8_Facebook_32.png" class="icone" alt="" />
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/company/pilink"
              class="me-4 text-reset"
            >
              <img src="assets/img/icons8_linkedin_2_filled_32.png" class="icone" alt="" />
            </a>
            <a target="_blank" href="https://www.twitter.com/PILINK_SAS/" class="me-4 text-reset">
              <img src="assets/img/icons8_twitter_32.png" class="icone" alt="" />
            </a>
            <a target="_blank" href="https://www.instagram.com/pilink_sas/" class="me-4 text-reset">
              <img src="assets/img/icons8_instagram_new_32.png" class="icone" alt="" />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCeHf2X82_Yw1nMycYFdtVeQ"
              class="me-4 text-reset"
            >
              <img src="assets/img/icons8_youtube_play_32.png" class="icone" alt="" />
            </a>

            <a target="_blank" href="https://www.tiktok.com/@pilink_sas" class="text-reset">
              <img src="assets/img/icons8_tiktok_32.png" class="icone" alt="" />
            </a>
          </div>

          <form>
            <a [routerLink]="['/contact-us']" class="btn btn-warning btn-lg border-0 rounded-0">
              {{ 'ContactezNous' | translate }}
            </a>
          </form>
        </div>
        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-3">
                <a
                  [routerLink]="['/become-mentor']"
                  class="text-reset"
                  style="text-decoration: none"
                  >{{ 'DevenirMentor' | translate }}</a
                >
              </div>
              <div class="col-12 mb-3">
                <a [routerLink]="['/rates']" class="text-reset" style="text-decoration: none">{{
                  'Tarifs' | translate
                }}</a>
              </div>
              <div class="col-12 mb-3">
                <a [routerLink]="['/espace-pi']" class="text-reset" style="text-decoration: none">{{
                  'EspacePI' | translate
                }}</a>
              </div>
              <div class="col-12 mb-3">
                <!--<div class="nav-item dropdown col">
                  <a
                    class="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Langue &nbsp;&nbsp;
                    <i
                      [ngClass]="getFlagClass(translate.currentLang)"
                      style="border-radius: 10px"
                    ></i>
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="top: -1px">
                    <li *ngFor="let language of translate.getLangs()" style="cursor: pointer">
                      <a class="dropdown-item" (click)="switchLanguage(language)">
                        <i [ngClass]="getFlagClass(language)" style="border-radius: 10px"></i>
                        &nbsp; {{ getLanguage(language) }}
                      </a>
                      <br />
                    </li>
                  </ul>
                </div>-->
              </div>
              <div class="col-12 mb-3">
                <a [routerLink]="['/faq']" class="text-reset" style="text-decoration: none">FAQ</a>
              </div>
              <div class="col-12 mb-3">
                <a [routerLink]="['/about-us']" class="text-reset" style="text-decoration: none"
                  >{{ 'AproposDe' | translate }} PILINK</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-2">
        <div class="border-bottom mt-2 w-100"></div>
      </div>
      <div class="py-2 d-flex" style="width: 100%">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-4">
              Copyright © 2022 -
              <a class="text-reset" [routerLink]="['home']" style="text-decoration: none">PILINK</a>
            </div>
            <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
              <div class="row d-flex justify-content-end">
                <div class="col-md-auto">
                  <a class="me-2 text-warning" [routerLink]="['/legal-notices']">{{
                    'LegalNotices' | translate
                  }}</a>
                  <a class="text-warning" [routerLink]="['/cgv-cgu']">{{
                    'ProtectionData' | translate
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</footer>
