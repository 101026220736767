import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RatesService } from '../../services/rates.service';
import { Title, Meta } from '@angular/platform-browser';
import { ProductInfo } from '../../models/productInfo';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OurServicesComponent implements OnInit {
  pageTitle: string = 'Nos services | PILINK';
  slideIndex!: number;
  loading = true;
  productInfoList: ProductInfo[] = [];
  labelIndexTab: string | any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private rateService: RatesService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.title.setTitle(this.pageTitle);
    this.meta.addTag({
      keywords:
        'intégration, étrangers, social, bons plans, astuces, prévention arnaques, vie en France, vie étudiante, stage, alternance, cdi, coaching, France',
    });
    this.meta.addTag({
      description: `PILINK est une entreprise française qui facilite
    l'intégration sociale et culturelle des étrangers en France. Elle optimise les coûts au travers de bons plans et d'astuces et
    simplifie les démarches administratives. Elle également grace à des coachings toute personne en France dans l'insertion professionnelle notamment les stages, alternances et CDI`,
    });

    this.rateService.getAlProductInfo().subscribe({
      next: (infos) => {
        this.productInfoList = infos;
        this.loading = false;
      },
      error: (e) => this.errorMessage(e),
      complete: () => {
        const id = this.activatedRoute.snapshot.paramMap.get('labelIndexTab');
        const index = this.productInfoList.findIndex((info) => info.referenceId === id);
        this.slideIndex = index === -1 ? 0 : index;
      },
    });

    // this.labelIndexTab = this.activatedRoute.snapshot.paramMap.get('labelIndexTab');
    // if (this.labelIndexTab === 'integration') {
    //   this.slideIndex = 0;
    // } else if (this.labelIndexTab === 'career') {
    //   this.slideIndex = 1;
    // } else if (this.labelIndexTab === 'search_housing') {
    //   this.slideIndex = 2;
    // } else if (this.labelIndexTab === 'campusfr') {
    //   this.slideIndex = 3;
    // } else if (this.labelIndexTab === 'automatic_diary') {
    //   this.slideIndex = 4;
    // } else if (this.labelIndexTab === 'espace_pi') {
    //   this.slideIndex = 5;
    // }
    // this.rateService.getAlProductInfo().subscribe({
    //   next: (res) => this.getAll(res),
    //   error: (e) => this.errorMessage(e),
    // });
  }

  // getAll = (res: any) => {
  //   if (res?.data) {
  //     this.productInfoList = res?.data;
  //     this.loading = false;
  //     let labelSlide = this.labelIndexTab;
  //     if (labelSlide) {
  //       this.slideIndex = this.productInfoList.findIndex(function (element, index) {
  //         if (element.referenceId == labelSlide) {
  //           return index;
  //         }
  //       });
  //     } else {
  //       this.slideIndex = 0;
  //     }
  //   } else {
  //     this.toastr.error('Une erreur est survenue', 'Erreur');
  //   }
  // };

  errorMessage = (err: any) => {
    if (err.status === 500) {
      this.toastr.error('Une erreur est survenue.', 'Erreur');
    } else {
      if (err.error?.errors?.length > 0) {
        this.toastr.error(
          err.error.errors
            .map((x: any) => {
              if (typeof x == 'object') {
                return x?.message;
              } else {
                return x;
              }
            })
            .join(', '),
          'Erreur'
        );
      } else {
        this.toastr.error(err.error?.message || 'Une erreur est survenue.', 'Erreur');
      }
    }
  };

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.slideIndex = tabChangeEvent.index;
  }
}
