<div class="contact-us">
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="p-md-5 p-sm-0 w-100 mb-4">
      <div *ngIf="countryCodeSelected" class="container card rounded-0 p-3">
        <form [formGroup]="validationForm" class="formContact px-3" (ngSubmit)="onSubmit()">
          <div class="row mb-5">
            <div class="col-md-8 col-sm-12 mb-4">
              <p class="text-dark py-3 fw-bold text-60">{{ 'ContactezNous' | translate }}</p>
              <div class="col-md-10 col-sm-12">
                <p class="fs-5 lh-sm">
                  {{ 'ContactezNousTextDesc' | translate }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="fw-bolder">{{ 'lastName' | translate }}</mat-label>
                <input
                  matInput
                  formControlName="lastName"
                  name="lastName"
                  id="lastName"
                  required
                />
              </mat-form-field>
              <p
                *ngIf="errors && form['lastName'].invalid"
                class="text-danger text-start pt-2 fs-6"
              >
                {{ 'requiredFieldText' | translate }}
              </p>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="fw-bolder">{{ 'firstName' | translate }}</mat-label>
                <input
                  matInput
                  formControlName="firstName"
                  name="firsName"
                  id="firsName"
                  required
                />
              </mat-form-field>
              <p
                *ngIf="errors && form['firstName'].invalid"
                class="text-danger text-start pt-2 fs-6"
              >
                {{ 'requiredFieldText' | translate }}
              </p>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="fw-bolder">E-mail</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="email"
                  name="email"
                  id="email"
                  required
                />
              </mat-form-field>
              <div *ngIf="email.invalid && (email.dirty || email.touched)">
                <p *ngIf="email.errors?.['required']" class="text-danger text-start pt-2 fs-6">
                  {{ 'requiredFieldText' | translate }}
                </p>
                <p *ngIf="email.value && email.invalid" class="text-danger text-start pt-2 fs-6">
                  {{ 'EmailInvalidText' | translate }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="mb-3 inputContent">
                <div class="input-group pt-1">
                  <button
                    class="btn btn-outline-pilink dropdown-toggle btnIndicator"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img width="25px" src="{{ indicatorSelected.flag }}" alt="" />
                    {{ indicatorSelected?.dialCode }}
                  </button>
                  <ul class="dropdown-menu" style="height: 200px; overflow: auto">
                    <ng-template ngFor let-indicator [ngForOf]="indicatorList" let-i="index">
                      <li class="pointer">
                        <a class="dropdown-item" (click)="changeIndicatorCountry(indicator)"
                          ><img width="25px" src="{{ indicator.flag }}" alt="{{ indicator.name }}" />
                          {{ indicator.name }}</a
                        >
                      </li>
                    </ng-template>
                  </ul>
                  <input
                    type="number"
                    formControlName="phoneNumber"
                    placeholder="{{ 'Phone' | translate }}"
                    required
                    name="phoneNumber"
                    class="form-control"
                    id="phoneNumber"
                    style="padding: 0.75rem 0.5rem;"
                    aria-label="PhoneNumber"
                  />
                </div>
                <p
                  *ngIf="errors && form['phoneNumber'].invalid"
                  class="text-danger mt-4 text-start fs-6"
                >
                  {{ 'requiredFieldText' | translate }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 mt-md-0 mt-4 mb-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label class="fw-bolder">{{ 'Address' | translate }}</mat-label>
                <input
                  matInput
                  formControlName="address"
                  ngx-google-places-autocomplete
                  (onAddressChange)="checkAddress($event)"
                  name="address"
                  id="address"
                  required
                />
              </mat-form-field>
              <p *ngIf="errors && form['address'].invalid" class="text-danger text-start pt-2 fs-6">
                {{ 'requiredFieldText' | translate }}
              </p>
            </div>
            <div class="col-md-12">
              <label for="country" class="form-label">{{ 'ContactezNousYourMsgText' | translate }}</label>
              <div class="col-12 mailObjectContainer">
                <div class="row m-0 p-0 bg-white">
                  <div class="col-lg-1 col-md-2 col-sm-2 col-xs-12 py-3">
                    <label for="objet">{{ 'ObjectText' | translate }} :</label>
                  </div>
                  <div class="col-lg-11 col-md-10 col-sm-10 col-xs-12 mt-2">
                    <input
                      formControlName="title"
                      id="objet"
                      type="text"
                      required
                      name="object"
                      placeholder="{{ 'ContactezNousPlaceholderEntitleText' | translate }}"
                      class="form-control mailObject py-2"
                      id="object"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mailObjectContainer rounded-bottom">
                <textarea
                  class="form-control mailMessage border-0"
                  formControlName="message"
                  required
                  name="mailMessage"
                  placeholder="{{ 'ContactezNousPlaceholderMsgText' | translate }}"
                  id="mailMessage"
                  rows="7"
                >
                </textarea>
              </div>
              <p *ngIf="errors && form['message'].invalid" class="text-danger text-start pt-2 fs-6">
                {{ 'requiredFieldText' | translate }}
              </p>
            </div>
            <div class="col-md-5 col-sm-12">
              <button
                type="submit"
                class="btn bg-purple text-white mt-4 rounded-0 w-100 py-3"
                [disabled]="send"
              >
                {{ 'SendFormText' | translate }} &nbsp;
                <span
                  *ngIf="send"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="!countryCodeSelected" class="my-5 py-5">
        <mat-spinner class="mx-auto"></mat-spinner>
      </div>
    </div>
  </div>
</div>
