<section class="container-fluid blog-banner d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-7"></div>
      <div class="col-lg-4 col-md-5">
        <p class="banner-label-blog text-60">
          {{ 'BlogBannerText' | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
<div *ngIf="!requestDone" class="my-5 py-5">
  <mat-spinner class="mx-auto"></mat-spinner>
</div>
<div *ngIf="requestDone" class="container-fluid pt-5">
  <div class="container py-3">
    <div
      class="row row-cols-1 row-cols-lg-3 row-cols-md-1 row-cols-sm-2 justify-content-md-center row-cols-xs-1 g-5 px-md-5"
    >
      <div class="col" *ngFor="let blog of BlogList">
        <div class="card border-0 rounded-0">
          <img
            src="{{ blog?.imageUrl || '' }}"
            style="height: 200px !important"
            class="card-img-top"
            alt="image de blog"
          />
          <div class="card-body" style="height: 300px !important">
            <p>
              <i class="bi bi-alarm"></i>&nbsp;<small
                >Ajouté le {{ formatDate(blog?.createdAt) }}</small
              >
            </p>
            <p class="card-title" style="color: #6633cc">{{ blog?.title || '' }}</p>
            <p class="card-text">{{ blog?.description || '' }}</p>
          </div>
          <div class="card-footer border-0 text-end">
            <a
              class="btn-readMore"
              *ngIf="blog?.blogUrl; else blogContent"
              [href]="blog?.blogUrl"
              target="_blank"
            >
              Lire la suite
            </a>
            <ng-template #blogContent>
              <a [routerLink]="['/blogpage', blog?.id]" class="btn-readMore"> Lire la suite </a>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-5" *ngIf="BlogList.length - 1"></div>
    </div>
  </div>
  <div class="text-center p-5" *ngIf="BlogList.length === 0 && requestDone">
    <img class="mb-3" src="assets/img/sorry.svg" alt="Image Description" style="width: 9rem" />
    <p class="mb-0 fs-6">Aucun résultat</p>
  </div>
</div>
