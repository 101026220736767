import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { FaqService } from '../../services/faq.service';
import { getAllFaqs } from './faq.action';

export class FaqStateModel {
  faqs: any;
}

@State<FaqStateModel>({
  name: 'faqState',
  defaults: {
    faqs: [],
  },
})
@Injectable()
export class FaqState {
  constructor(private faqService: FaqService) {}

  @Selector()
  static selectFaqData(state: FaqStateModel) {
    return state.faqs;
  }

  @Action(getAllFaqs)
  getFaqsFromState(ctx: StateContext<FaqStateModel>) {
    return this.faqService.getFaqs().pipe(
      tap((faqData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          faqs: faqData,
        });
      })
    );
  }
}
