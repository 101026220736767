<div class="container cgu px-3 py-5">
  <p class="text-60 title">{{ 'cguText' | translate }} (CGU)</p>
  <p>
    En accédant au présent site internet et aux informations qu'il contient, l'internaute, ci-après
    désigné sous le terme « Utilisateur », reconnaît avoir pris connaissance des présentes Conditions
    Générales d'Utilisation, ci-après désignées sous le terme « CGU », et s'engage à les respecter.
    L'éditeur se réserve le droit de modifier les présentes CGU à tout moment afin, notamment, de
    les adapter aux évolutions du site. L'Utilisateur s'engage donc à la consulter régulièrement. Il
    est réputé accepter cette dernière version à chaque nouvelle connexion au site.
  </p>
  <p class="fw-bold">1. Présentation et objet du site</p>
  <p>
    Le présent site internet est un site d'information et de présentation à destination du public
    (clients et non clients de PILINK) de l'activité et des offres commercialisées par
    PILINK, notamment leurs produits et services (Recherche de logement, intégration,
    accompagnement à l'insertion professionnelle, Accompagnement campus France/ visa, Agenda
    automatique etc.).
  </p>
  <p class="fw-bold">2. Conditions d'accès</p>
  <p>
    Le présent site internet est soumis à la loi française. La majorité des contenus et services est
    proposée en français. Si toutefois, certains d'entre eux étaient publiés en anglais, la version
    française fera foi en cas de litige.
  </p>
  <h1 class="text-uppercase fw-bold">accessibilité du site</h1>
  <p>
    Le présent site est accessible 24 heures sur 24, 7 jours sur 7, sauf cas de force majeure, fait(s)
    de tiers et/ou travaux de maintenance et interventions nécessaires au bon fonctionnement du
    site. Dans ce cas, cette interruption sera notifiée au moyen d'un avertissement figurant sur la
    page d'accueil ou par tout autre procédé. Cette interruption ne peut en aucun cas engager la
    responsabilité de l'éditeur et n'ouvre droit à aucune indemnité. Par ailleurs, l'éditeur peut mettre
    fin à tout moment à l'accès au présent site pour donner suite à un quelconque manquement aux
    présentes conditions par l'utilisateur.
  </p>
  <h1 class="text-uppercase fw-bold">tarification</h1>
  <p>
    L'accès au présent site Internet est gratuit, hormis la fourniture d'accès internet et les
    communications téléphoniques dont les coûts sont facturés directement par les opérateurs.
  </p>
  <p class="fw-bold">3. Propriété intellectuelle</p>
  <p>
    Le présent site internet doit être considéré comme un tout indissociable. Avec l'ensemble des
    éléments y figurant (informations, données, texte, sons, images, dessins, graphismes, signes
    distinctifs, logos, marques, etc.), ils sont la propriété exclusive de l'éditeur et sont soumis aux
    dispositions du Code de la propriété intellectuelle. Ils sont, à ce titre, protégés contre toute
    utilisation non autorisée par la loi ou les présentes conditions d'utilisation.
  </p>
  <p>
    La reproduction de ces éléments n'est autorisée qu'à des fins d'information et/ou de copies ou
    reproductions destinées à un usage strictement privé et personnel. Toute autre reproduction,
    représentation ou diffusion, en tout ou partie du contenu de ce site, sur quelque support ou par
    tout procédé que ce soit, est interdite. Le non-respect de cette interdiction constitue une
    contrefaçon susceptible d'engager la responsabilité civile et pénale du contrefacteur.
  </p>
  <p>
    Il est notamment strictement interdit d'utiliser ou de reproduire le nom « PILINK » et/ou son
    logo, seuls ou associés, à quelque titre que ce soit, et notamment à des fins publicitaires, sans
    l'accord préalable écrit de l'éditeur. De même, le téléchargement ou toute autre forme de copie
    d'un logiciel ou d'informations présentes sur le site ne confèrent aucun droit sur ceux-ci. Il est
    strictement interdit de les reproduire (en tout ou partie), de les transmettre (électroniquement
    ou de quelque autre manière) et de les modifier. Il est également interdit d'utiliser le présent site
    à des fins publiques ou commerciales sans l'autorisation écrite préalable de l'éditeur.
  </p>
  <p class="fw-bold">4. Mise en garde des informations diffusées</p>
  <p>
    L'éditeur du présent site internet, PILINK SAS s'efforce d'assurer l'exactitude et la mise à jour
    des informations diffusées sur ce site, dont il se réserve le droit de corriger, à tout moment et
    sans préavis, le contenu. L'éditeur ne peut cependant en garantir l'exhaustivité ou l'absence de
    modification par un tiers (intrusion, virus, etc.)
  </p>
  <p>
    L'Utilisateur est informé que le contenu du présent site internet est à usage purement informatif.
    Les informations qui y sont contenues ne peuvent être assimilées à une offre au public, une
    sollicitation ou un démarchage de la part de l'éditeur à l'égard de l'Utilisateur.
  </p>
  <h1 class="text-uppercase fw-bold">informations sur les produits et services</h1>
  <p>
    Toute demande de souscription en ligne d'un produit ou service proposé par PILINK implique
    la soumission de l'opération aux conditions contractuelles et tarifaires en vigueur, ainsi qu'à la
    loi française. Pour toute demande d'information, l'Utilisateur est invité à prendre contact avec
    le service client PILNK dont les coordonnées figurent sur le site internet
  </p>
  <h1 class="text-uppercase fw-bold">
    autres informations à caractère général fournies à titre non contractuel
  </h1>
  <p>
    Les autres informations à caractère général (astuces sur la vie quotidienne, etc.) sont fournies à
    titre non contractuel et n'ont qu'une valeur indicative ou informative. Malgré tous les soins
    apportés à l'actualisation de ces informations et à leurs contenus, les documents mis en ligne
    sont susceptibles de contenir des inexactitudes techniques et des erreurs typographiques qui ne
    sauraient engager la responsabilité de l'éditeur, de quelque manière que ce soit.
  </p>
  <h1 class="text-uppercase fw-bold">liens hypertextes</h1>
  <p>
    L'existence, sur le présent site, d'un lien hypertexte conduisant à un autre site ne constitue pas
    une validation de cet autre site ou de son contenu. L'Utilisateur décide, sous sa seule
    responsabilité, d'accéder à un autre site par l'intermédiaire d'un lien hypertexte: l'éditeur
    n'assume aucune responsabilité de ces sites, leur contenu, publicités, produits ou services
    disponibles sur ou à partir de ces sites.
  </p>
  <p>
    Par ailleurs, la responsabilité de l'éditeur ne saurait être engagée quant aux informations,
    opinions et recommandations formulée par ces tiers.
    Enfin, tout lien hypertexte vers le présent site Internet doit faire l'objet d'une autorisation
    expresse et préalable de l'éditeur, lequel se réserve la possibilité de faire supprimer ce lien à
    tout moment.
  </p>
  <p class="fw-bold">5. Usage du site et de ses outils</p>
  <h1 class="text-uppercase fw-bold">utilisation générale du site</h1>
  <p>
    L'Utilisateur est informé que l'usage du réseau internet ne garantit pas le secret des
    correspondances ; il lui appartient de prendre toutes les mesures appropriées afin de protéger
    ses propres données et outils. Aussi l'Utilisateur reconnaît :
  </p>
  <ul>
    <li>
      Que la communication de ses éventuels codes d'accès ou d'une manière générale de
      toute information jugée confidentielle est faite sous sa propre responsabilité ;
    </li>
    <li>
      Qu'il lui appartient de prendre toutes mesures nécessaires pour s'assurer que les
      caractéristiques techniques de son ordinateur lui permettent la consultation des contenus ;
    </li>
    <li>
      Qu'il lui appartient de prendre toutes les mesures appropriées de façon à protéger ses
      propres données et/ou logiciels de la contamination par des éventuels virus circulant à
      travers le site de PILINK.
    </li>
  </ul>
  <p>
    D'une façon générale, l'Utilisateur s'engage à respecter l'intégrité du site et s'interdit d'entraver
    ou forcer le fonctionnement du site, de modifier, d'altérer ou de supprimer frauduleusement les
    contenus accessibles via le site et d'introduire frauduleusement des données sur le site.
  </p>
  <h1 class="text-uppercase fw-bold">responsabilité</h1>
  <p>Suite à l'utilisation du site internet, l'éditeur :</p>
  <ul>
    <li>
      Ne saurait être tenu responsable des dommages qui pourraient éventuellement être
      subis, à l'occasion d'une visite sur son site, par l'environnement technique de l'Utilisateur
      et notamment, ses ordinateurs, logiciels, équipements réseaux (modems, téléphones,
      etc…) et tout autre matériel utilisé pour accéder à ou utiliser le service et/ou les
      informations ;
    </li>
    <li>
      Ne peut être tenu responsable en cas de mauvaise utilisation du site par l'Utilisateur ;
    </li>
    <li>
      Ne peut en aucun cas être tenu pour responsable de la fiabilité de la transmission des
      données, des temps d'accès, des éventuelles restrictions d'accès sur le réseau internet ou
      les réseaux qui lui sont connectés ;
    </li>
    <li>
      Ne saurait être responsable en cas d'interruption des réseaux d'accès au Site,
      d'indisponibilité totale ou partielle du Site résultant notamment de l'opérateur de
      télécommunications, en cas d'erreur de transmission ou de problèmes liés à la sécurité
      des transmissions, en cas de défaillances du matériel de réception ou de votre ligne
      téléphonique.
    </li>
  </ul>
  <h1 class="text-uppercase fw-bold">espaces collaboratifs / charte de modération</h1>
  <p>
    Le présent site internet est susceptible de mettre à disposition des Utilisateurs des espaces
    collaboratifs permettant de consulter et/ou de publier des contributions (retours d'expérience,
    notation du service) sous forme de commentaires par exemple, aux thèmes abordés sur le site.
    Il est précisé que l'éditeur se réserve le droit de restreindre l'accès aux fonctionnalités de
    publication de contributions aux seuls utilisateurs souscripteurs.
  </p>
  <p>
    Les données collectées dans les formulaires de publication de contributions feront l'objet de
    traitements de données personnels dans les conditions fixées au sein de la politique de
    protection des données du présent site.
  </p>
  <p>
    Les contributions des Utilisateurs sont modérées a priori et leur publication ne sera pas
    instantanée : tous les commentaires sont lus préalablement à leur publication par un modérateur
    et sont susceptibles d'être supprimés, avec ou sans information à l'Utilisateur, lorsque ceux-ci
    ne sont pas en relation avec le thème de la discussion, la ligne éditoriale du site ou qui seraient
    contraire à la loi.
  </p>
  <h1 class="text-uppercase fw-bold">outils de tchat et de visio</h1>
  <p>
    Le présent site internet peut permettre à l'Utilisateur d'accéder à un service de messagerie
    instantanée électronique, ci-après « Tchat », et de visio-conférence, ci-après « Visio ». Ce
    service est disponible pour obtenir tout renseignement relatif sur un produit ou un service
    commercialisé par l'éditeur. La communication d'information sur un produit ou un service ne
    présume pas de l'éligibilité de l'Utilisateur à ce produit ou ce service. Cette fonction peut
    également permettre l'accès à un service de prise de Rendez-vous avec un conseiller via
    notamment le canal Visio sous réserve de l'acceptation des conditions générales d'utilisation
    du service Visio-Conférence.
  </p>
  <p>
    L'éditeur se réserve, par ailleurs, la possibilité de proposer à l'Utilisateur la réalisation
    d'opération concernant un produit ou service financier (accompagnement personnalisé à
    l'insertion professionnelle, Accompagnement campus France etc.) via le service Tchat ou Visio.
    Dans ce cas, l'éditeur pourra soumettre l'Utilisateur à une procédure d'authentification
    complémentaire par l'utilisation de dispositifs de sécurité spécifiques variant en fonction de la
    sensibilité des opérations (code SMS par exemple), ainsi que d'enregistrer et de conserver les
    conversations pour une durée conforme aux dispositions légales en vigueur. Par ailleurs,
    l'ensemble des documents contractuels nécessaires (conditions générales des produits
    concernés, tarifs, notices d'information éventuelles, document comportant les modalités et
    règles de souscription, etc.) seront fournis à l'Utilisateur conformément aux dispositions légales
    en vigueur.
  </p>
</div>
