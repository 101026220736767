import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { CoreComponent } from './core.component';
import { HomeComponent } from './components/home/home.component';
import { AuthInterceptor } from './authconfig.interceptor';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MainBannerComponent } from './components/home/main-banner/main-banner.component';
import { TrustPersonsComponent } from './components/home/trust-persons/trust-persons.component';
import { ServicesComponent } from './components/home/services/services.component';
import { BecomeMentorComponent } from './components/home/become-mentor/become-mentor.component';
import { BecomeMentorComponent as MentorPage } from './components/become-mentor/become-mentor.component';
import { EspacePiComponent } from './components/espace-pi/espace-pi.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogPageComponent } from './components/blog-page/blog-page.component';
import { FaqComponent } from './components/faq/faq.component';
import { RatesComponent } from './components/rates/rates.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { GoodPlansComponent } from './components/espace-pi/good-plans/good-plans.component';
import { ScamComponent } from './components/espace-pi/scam/scam.component';
import { FeedbackComponent } from './components/espace-pi/feedback/feedback.component';
import { DidYouKnowComponent } from './components/espace-pi/did-you-know/did-you-know.component';
import { HelpComponent } from './components/espace-pi/help/help.component';
import { HelpDialogDetailsComponent } from './components/espace-pi/help/help-dialog-details/help-dialog-details.component';
import { TrickComponent } from './components/espace-pi/trick/trick.component';
import { ClientsFeedbackComponent } from './components/home/clients-feedback/clients-feedback.component';
import { StarRatingModule } from 'angular-star-rating';
import { AvatarModule } from 'ngx-avatar';

@NgModule({
  declarations: [
    CoreComponent,
    HomeComponent,
    ContactUsComponent,
    AboutUsComponent,
    MainBannerComponent,
    TrustPersonsComponent,
    ServicesComponent,
    BecomeMentorComponent,
    MentorPage,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EspacePiComponent,
    BlogComponent,
    BlogPageComponent,
    FaqComponent,
    RatesComponent,
    OurServicesComponent,
    GoodPlansComponent,
    ScamComponent,
    FeedbackComponent,
    DidYouKnowComponent,
    HelpComponent,
    TrickComponent,
    HelpDialogDetailsComponent,
    ClientsFeedbackComponent,
  ],
  imports: [
    CoreRoutingModule,
    StarRatingModule.forRoot(),
    CarouselModule.forRoot(),
    SharedModule,
    AvatarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  exports: [],
})
export class CoreModule {}
