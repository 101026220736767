export class getUserFiles {
  static readonly type = '[User files] fetch user files';
  constructor(public id: string){}
}

export class setUserFiles {
  static readonly type = '[User files] set user files';
  constructor(public payload: any, public idUser: string){}
}

export class SetLoaded {
  static readonly type = '[User files] SetLoaded';
  constructor() { }
}

export class deleteUserFiles {
  static readonly type = '[User files] delete user files';
  constructor(public idFile: string, public idUser: string){}
}
