<div class="content-nav">
  <nav class="navbar shadow-sm navbar-expand-lg navbar-light bg-light nav-bar">
    <div class="container-fluid px-0">
      <a class="navbar-brand" [routerLink]="userIsAuth ? '/user/welcome' : '/home'">
        <svg
          width="200"
          height="50"
          viewBox="0 0 212 83"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.6178 59.4463C19.1155 59.4463 15.1005 57.7413 12.2092 54.2809V71.4947H0V15.5846H12.2092V20.51C15.1762 16.4939 19.2796 14.7889 25.6178 14.7889C35.6553 14.7889 45.213 22.657 45.213 37.0418V37.206C45.213 51.5782 35.8194 59.4463 25.6178 59.4463ZM33.0165 37.0418C33.0165 29.8936 28.1934 25.1576 22.4992 25.1576C16.805 25.1576 12.0576 29.8936 12.0576 37.0418V37.206C12.0576 44.3542 16.7923 49.1028 22.4992 49.1028C28.2061 49.1028 33.0165 44.4426 33.0165 37.206V37.0418Z"
            fill="black"
          />
          <path d="M49.9225 58.6506V15.5846H62.1316V58.6506H49.9225Z" fill="black" />
          <path d="M70.3763 58.6506V0H82.5854V58.6506H70.3763Z" fill="black" />
          <path d="M90.8175 58.6506V15.5846H103.027V58.6506H90.8175Z" fill="black" />
          <path
            d="M138.493 58.6507V34.6297C138.493 28.8454 135.765 25.8775 131.106 25.8775C126.447 25.8775 123.48 28.8454 123.48 34.6297V58.6507H111.271V15.5846H123.48V21.6846C126.296 18.0726 129.907 14.7763 136.094 14.7763C145.336 14.7763 150.714 20.8763 150.714 30.7651V58.638H138.493V58.6507Z"
            fill="black"
          />
          <path d="M62.1316 0H0V9.08049H62.1316V0Z" fill="#FFCC33" />
          <path d="M150.171 0H90.8175V9.08049H150.171V0Z" fill="#6633CC" />
          <path
            d="M174.135 34.5539L169.88 39.0499V58.6506H157.671V0H169.88V24.7535L184.173 9.09312H198.793L182.405 26.0417"
            fill="black"
          />
          <path
            d="M184.248 33.2151C189.766 37.9638 196.887 48.7619 201.546 58.3349C189.45 58.3349 198.377 58.3349 186.925 58.3349C183.478 51.5782 180.713 47.1074 175.777 42.1188"
            fill="black"
          />
          <path
            d="M24.0017 79.4005V68.1351H28.2818C29.4939 68.1351 30.4535 68.4382 31.1731 69.0318C31.8802 69.638 32.2463 70.4463 32.2463 71.4945C32.2463 72.5427 31.8928 73.3636 31.1858 73.9572C30.4787 74.5508 29.5065 74.8539 28.2944 74.8539H25.3905V79.4131H24.0017V79.4005ZM25.3779 73.6289H28.2818C29.0772 73.6289 29.6959 73.4394 30.1252 73.0732C30.5545 72.7069 30.7691 72.1765 30.7691 71.4945C30.7691 70.7999 30.5545 70.2695 30.1252 69.9032C29.6959 69.537 29.0772 69.3475 28.2818 69.3475H25.3779V73.6289Z"
            fill="black"
          />
          <path
            d="M39.5819 76.7106L40.6172 77.3547C39.8597 78.8449 38.6729 79.5901 37.0694 79.5901C35.9204 79.5901 34.9735 79.2112 34.2286 78.4408C33.471 77.6704 33.1049 76.6979 33.1049 75.5108C33.1049 74.311 33.471 73.3259 34.1907 72.5682C34.9104 71.8104 35.8321 71.4189 36.9431 71.4189C38.0542 71.4189 38.938 71.7725 39.5819 72.4924C40.2258 73.2123 40.5415 74.1721 40.5415 75.3719C40.5415 75.5992 40.5415 75.7381 40.5288 75.8139H34.519C34.5568 76.6222 34.8094 77.2663 35.2513 77.7336C35.6932 78.2008 36.3118 78.4282 37.0694 78.4282C38.1678 78.4282 39.0138 77.8598 39.5819 76.7106ZM36.9179 72.5682C36.274 72.5682 35.731 72.7576 35.3018 73.1491C34.8725 73.528 34.62 74.0584 34.5316 74.7278H39.14C39.0264 73.288 38.2815 72.5682 36.9179 72.5682Z"
            fill="black"
          />
          <path
            d="M48.6599 78.4408C47.8897 79.2112 46.9428 79.6027 45.8191 79.6027C44.6954 79.6027 43.7484 79.2112 42.9783 78.4408C42.2081 77.6704 41.8293 76.698 41.8293 75.5108C41.8293 74.3236 42.2081 73.3385 42.9783 72.5808C43.7484 71.8104 44.6954 71.4315 45.8191 71.4315C46.9428 71.4315 47.8897 71.8104 48.6599 72.5808C49.43 73.3512 49.8088 74.3236 49.8088 75.5108C49.8088 76.6853 49.43 77.6578 48.6599 78.4408ZM43.9504 77.6325C44.4302 78.163 45.0489 78.4282 45.8191 78.4282C46.5892 78.4282 47.2079 78.163 47.6877 77.6325C48.1675 77.1021 48.3947 76.3948 48.3947 75.5108C48.3947 74.6267 48.1548 73.9195 47.6877 73.3891C47.2079 72.8586 46.5892 72.5934 45.8191 72.5934C45.0489 72.5934 44.4302 72.8586 43.9504 73.3891C43.4707 73.9195 43.2434 74.6267 43.2434 75.5108C43.2434 76.3948 43.4833 77.1021 43.9504 77.6325Z"
            fill="black"
          />
          <path
            d="M51.4881 82.8105V71.621H52.839V72.8713C53.5082 71.9114 54.4551 71.4315 55.6924 71.4315C56.7151 71.4315 57.5737 71.823 58.2681 72.6187C58.9625 73.4143 59.3034 74.3742 59.3034 75.5108C59.3034 76.6601 58.9625 77.6199 58.2681 78.4155C57.5737 79.2112 56.7151 79.6027 55.6924 79.6027C54.4551 79.6027 53.5082 79.1228 52.839 78.1756V82.8105H51.4881ZM55.4021 78.4282C56.1849 78.4282 56.7909 78.163 57.2328 77.6451C57.6747 77.1147 57.902 76.4075 57.902 75.5108C57.902 74.6141 57.6747 73.9069 57.2328 73.3764C56.7909 72.846 56.1849 72.5934 55.4021 72.5934C54.6824 72.5934 54.0637 72.8713 53.5839 73.4396C53.1042 74.0079 52.8516 74.6899 52.8516 75.5108C52.8516 76.3317 53.0915 77.0263 53.5839 77.582C54.0637 78.1503 54.6698 78.4282 55.4021 78.4282Z"
            fill="black"
          />
          <path d="M61.0205 79.4006V68.1353H62.3715V79.4006H61.0205Z" fill="black" />
          <path
            d="M70.5657 76.7106L71.601 77.3547C70.8434 78.8449 69.6566 79.5901 68.0531 79.5901C66.9042 79.5901 65.9572 79.2112 65.2123 78.4408C64.4548 77.6704 64.0886 76.6979 64.0886 75.5108C64.0886 74.311 64.4548 73.3259 65.1744 72.5682C65.8941 71.8104 66.8158 71.4189 67.9269 71.4189C69.0379 71.4189 69.9217 71.7725 70.5657 72.4924C71.2096 73.2123 71.5252 74.1721 71.5252 75.3719C71.5252 75.5992 71.5252 75.7381 71.5126 75.8139H65.5027C65.5406 76.6222 65.7931 77.2663 66.235 77.7336C66.6769 78.2008 67.2956 78.4282 68.0531 78.4282C69.1516 78.4282 69.9975 77.8598 70.5657 76.7106ZM67.9016 72.5682C67.2577 72.5682 66.7148 72.7576 66.2855 73.1491C65.8562 73.528 65.6037 74.0584 65.5153 74.7278H70.1237C70.0101 73.288 69.2652 72.5682 67.9016 72.5682Z"
            fill="black"
          />
          <path d="M77.4341 79.4006V68.1353H78.8103V79.4006H77.4341Z" fill="black" />
          <path
            d="M81.3102 79.4006V71.621H82.6612V72.5934C83.2167 71.8104 84.0626 71.4315 85.1737 71.4315C86.1206 71.4315 86.8656 71.7094 87.3832 72.2777C87.9009 72.846 88.166 73.6164 88.166 74.6015V79.4133H86.8151V74.7404C86.8151 73.3133 86.1712 72.5934 84.8707 72.5934C84.2268 72.5934 83.7091 72.7829 83.2925 73.1491C82.8758 73.5154 82.6738 74.0458 82.6738 74.7151V79.4006H81.3102Z"
            fill="black"
          />
          <path
            d="M92.724 79.5901C91.2594 79.5901 90.5144 78.7565 90.5144 77.0768V72.7828H89.2392V71.6209H90.5144L90.7922 69.2971H91.8654V71.6209H95.0092V72.7828H91.8654V77.1147C91.8654 77.9609 92.1684 78.3776 92.7745 78.3776C93.33 78.3776 93.835 77.8851 94.2769 76.9L95.3754 77.4178C95.1102 78.0745 94.7315 78.605 94.2643 78.9965C93.7971 79.4006 93.2795 79.5901 92.724 79.5901Z"
            fill="black"
          />
          <path
            d="M102.66 76.7106L103.696 77.3547C102.938 78.8449 101.751 79.5901 100.148 79.5901C98.999 79.5901 98.052 79.2112 97.3071 78.4408C96.5496 77.6704 96.1834 76.6979 96.1834 75.5108C96.1834 74.311 96.5496 73.3259 97.2692 72.5682C97.9889 71.8104 98.9106 71.4189 100.022 71.4189C101.133 71.4189 102.017 71.7725 102.66 72.4924C103.304 73.2123 103.62 74.1721 103.62 75.3719C103.62 75.5992 103.62 75.7381 103.607 75.8139H97.5975C97.6354 76.6222 97.8879 77.2663 98.3298 77.7336C98.7717 78.2008 99.3904 78.4282 100.148 78.4282C101.246 78.4282 102.092 77.8598 102.66 76.7106ZM99.9964 72.5682C99.3525 72.5682 98.8096 72.7576 98.3803 73.1491C97.951 73.528 97.6985 74.0584 97.6101 74.7278H102.219C102.105 73.288 101.36 72.5682 99.9964 72.5682Z"
            fill="black"
          />
          <path
            d="M111.486 71.4315C111.221 71.4315 110.993 71.6588 110.779 72.1261C111.36 72.606 111.65 73.2628 111.65 74.1089C111.65 74.9551 111.36 75.6118 110.779 76.0917C110.198 76.5716 109.453 76.799 108.557 76.799C108.026 76.799 107.534 76.7106 107.079 76.5211C106.587 76.66 106.347 76.9253 106.347 77.3168C106.347 77.5188 106.423 77.6957 106.562 77.8219C106.713 77.9609 106.903 78.024 107.143 78.024H110.034C111.991 78.024 112.963 78.7565 112.963 80.2089C112.963 81.0172 112.572 81.6865 111.802 82.2043C111.019 82.7221 110.059 82.9873 108.898 82.9873C107.534 82.9873 106.499 82.7726 105.792 82.3432C105.085 81.9138 104.731 81.3455 104.731 80.6257C104.731 79.9437 105.11 79.388 105.867 78.9586C105.35 78.6302 105.085 78.1377 105.085 77.5188C105.085 76.8242 105.451 76.2938 106.183 75.9275C105.703 75.4729 105.476 74.8667 105.476 74.0963C105.476 73.2501 105.766 72.5934 106.347 72.1135C106.928 71.6336 107.66 71.4062 108.557 71.4062C109.049 71.4062 109.478 71.482 109.857 71.6209C110.324 70.7369 110.918 70.2949 111.637 70.2949C112.054 70.2949 112.408 70.4338 112.698 70.7116C113.001 70.9895 113.14 71.4062 113.14 71.9746V72.2398L112.054 72.4292C112.029 71.7725 111.839 71.4315 111.486 71.4315ZM109.92 79.3627H107.471C107.054 79.3627 106.726 79.4638 106.461 79.6658C106.196 79.8679 106.069 80.1331 106.069 80.4488C106.069 81.3708 107.016 81.8254 108.885 81.8254C109.668 81.8254 110.312 81.6865 110.829 81.4087C111.347 81.1308 111.612 80.7646 111.612 80.3099C111.612 79.9689 111.473 79.729 111.208 79.5774C110.943 79.4385 110.514 79.3627 109.92 79.3627ZM108.557 75.7128C109.049 75.7128 109.466 75.5739 109.807 75.2961C110.148 75.0182 110.312 74.6267 110.312 74.1089C110.312 73.6164 110.148 73.2249 109.807 72.9344C109.466 72.6439 109.049 72.505 108.557 72.505C108.052 72.505 107.635 72.6439 107.307 72.9218C106.978 73.1996 106.814 73.6037 106.814 74.1089C106.814 74.6141 106.978 75.0056 107.307 75.2961C107.635 75.5866 108.052 75.7128 108.557 75.7128Z"
            fill="black"
          />
          <path
            d="M114.352 79.4006V71.621H115.703V72.9344C116.132 71.9746 116.865 71.4947 117.912 71.4947C118.506 71.4947 119.011 71.6967 119.415 72.1009C119.819 72.505 120.021 73.0354 120.021 73.6922C120.021 74.0584 119.958 74.4247 119.844 74.7909L118.582 74.6646C118.619 74.3615 118.645 74.1468 118.645 74.0332C118.645 73.1491 118.266 72.7071 117.521 72.7071C117.016 72.7071 116.587 72.9723 116.233 73.5154C115.88 74.0584 115.703 74.7404 115.703 75.5992V79.4259H114.352V79.4006Z"
            fill="black"
          />
          <path
            d="M124.023 79.5901C123.152 79.5901 122.432 79.3627 121.877 78.9207C121.321 78.4787 121.044 77.8725 121.044 77.1147C121.044 76.4201 121.321 75.8391 121.89 75.3592C122.458 74.8793 123.165 74.6394 124.023 74.6394C124.932 74.6394 125.715 74.9425 126.372 75.5613V74.5383C126.372 73.9195 126.208 73.4396 125.879 73.0859C125.551 72.7323 125.109 72.5555 124.528 72.5555C123.329 72.5555 122.584 73.1744 122.294 74.3994L121.082 74.0584C121.284 73.2249 121.7 72.5681 122.306 72.1009C122.912 71.6209 123.67 71.3936 124.554 71.3936C125.501 71.3936 126.271 71.6588 126.852 72.2019C127.432 72.745 127.723 73.5027 127.723 74.4878V79.3754H126.372V78.365C125.791 79.1985 124.996 79.5901 124.023 79.5901ZM124.15 78.4787C125.021 78.4787 125.766 78.0493 126.372 77.1779V76.8874C125.753 76.1675 125.021 75.8013 124.15 75.8013C123.645 75.8013 123.228 75.9276 122.925 76.1675C122.622 76.4201 122.458 76.7358 122.458 77.1273C122.458 77.5315 122.609 77.8472 122.925 78.0998C123.228 78.3524 123.645 78.4787 124.15 78.4787Z"
            fill="black"
          />
          <path
            d="M132.293 79.5901C130.829 79.5901 130.084 78.7565 130.084 77.0768V72.7828H128.809V71.6209H130.084L130.361 69.2971H131.435V71.6209H134.579V72.7828H131.435V77.1147C131.435 77.9609 131.738 78.3776 132.344 78.3776C132.899 78.3776 133.404 77.8851 133.846 76.9L134.945 77.4178C134.68 78.0745 134.301 78.605 133.834 78.9965C133.366 79.4006 132.849 79.5901 132.293 79.5901Z"
            fill="black"
          />
          <path
            d="M142.23 76.7106L143.265 77.3547C142.508 78.8449 141.321 79.5901 139.717 79.5901C138.568 79.5901 137.621 79.2112 136.876 78.4408C136.119 77.6704 135.753 76.6979 135.753 75.5108C135.753 74.311 136.119 73.3259 136.839 72.5682C137.558 71.8104 138.48 71.4189 139.591 71.4189C140.702 71.4189 141.586 71.7725 142.23 72.4924C142.874 73.2123 143.189 74.1721 143.189 75.3719C143.189 75.5992 143.189 75.7381 143.177 75.8139H137.167C137.205 76.6222 137.457 77.2663 137.899 77.7336C138.341 78.2008 138.96 78.4282 139.717 78.4282C140.816 78.4282 141.649 77.8598 142.23 76.7106ZM139.566 72.5682C138.922 72.5682 138.379 72.7576 137.95 73.1491C137.52 73.528 137.268 74.0584 137.179 74.7278H141.788C141.674 73.288 140.929 72.5682 139.566 72.5682Z"
            fill="black"
          />
          <path
            d="M148.126 79.5901C147.103 79.5901 146.245 79.1986 145.55 78.4029C144.856 77.6073 144.515 76.6474 144.515 75.5108C144.515 74.3615 144.856 73.4017 145.55 72.606C146.245 71.823 147.103 71.4189 148.126 71.4189C149.363 71.4189 150.31 71.8988 150.979 72.846V68.1226H152.33V79.388H150.979V78.1377C150.31 79.1101 149.363 79.5901 148.126 79.5901ZM148.429 78.4282C149.149 78.4282 149.767 78.1503 150.247 77.582C150.74 77.0263 150.979 76.3317 150.979 75.5108C150.979 74.6899 150.74 73.9953 150.247 73.4396C149.755 72.8713 149.149 72.5934 148.429 72.5934C147.646 72.5934 147.04 72.8586 146.598 73.3764C146.156 73.8942 145.929 74.6141 145.929 75.5108C145.929 76.4075 146.156 77.1147 146.598 77.6451C147.04 78.1629 147.646 78.4282 148.429 78.4282Z"
            fill="black"
          />
          <path d="M158.643 79.4006V68.1353H160.02V78.1882H166.181V79.4006H158.643Z" fill="black" />
          <path
            d="M168.87 69.7645C168.693 69.9413 168.479 70.0297 168.214 70.0297C167.949 70.0297 167.734 69.9413 167.557 69.7645C167.38 69.5876 167.292 69.3856 167.292 69.133C167.292 68.8804 167.38 68.6783 167.557 68.5015C167.734 68.3247 167.949 68.2363 168.214 68.2363C168.479 68.2363 168.693 68.3247 168.87 68.5015C169.047 68.6783 169.135 68.8804 169.135 69.133C169.135 69.3856 169.047 69.5876 168.87 69.7645ZM167.545 79.4006V71.621H168.895V79.4006H167.545Z"
            fill="black"
          />
          <path
            d="M171.092 79.4006V71.621H172.443V72.5934C172.999 71.8104 173.845 71.4315 174.956 71.4315C175.903 71.4315 176.648 71.7094 177.165 72.2777C177.683 72.846 177.948 73.6164 177.948 74.6015V79.4133H176.597V74.7404C176.597 73.3133 175.953 72.5934 174.653 72.5934C174.009 72.5934 173.491 72.7829 173.075 73.1491C172.658 73.5154 172.456 74.0458 172.456 74.7151V79.4006H171.092Z"
            fill="black"
          />
          <path
            d="M186.925 78.264C187.026 78.264 187.127 78.2513 187.228 78.2261V79.4132C187.039 79.4511 186.824 79.4764 186.584 79.4764C185.94 79.4764 185.385 79.287 184.943 78.9207C184.488 78.5545 183.983 77.9482 183.428 77.1021L182.493 75.6876L181.446 76.6979V79.388H180.095V68.1226H181.446V75.1572L185.259 71.6083H187.026L183.541 74.7909L184.501 76.2559C185.044 77.0768 185.461 77.6199 185.738 77.8725C186.003 78.1377 186.407 78.264 186.925 78.264Z"
            fill="black"
          />
          <path
            d="M194.336 76.7106L195.372 77.3547C194.614 78.8449 193.427 79.5901 191.824 79.5901C190.675 79.5901 189.728 79.2112 188.983 78.4408C188.226 77.6704 187.859 76.6979 187.859 75.5108C187.859 74.311 188.226 73.3259 188.945 72.5682C189.665 71.8104 190.587 71.4189 191.698 71.4189C192.809 71.4189 193.693 71.7725 194.336 72.4924C194.98 73.2123 195.296 74.1721 195.296 75.3719C195.296 75.5992 195.296 75.7381 195.283 75.8139H189.274C189.311 76.6222 189.564 77.2663 190.006 77.7336C190.448 78.2008 191.066 78.4282 191.824 78.4282C192.922 78.4282 193.756 77.8598 194.336 76.7106ZM191.672 72.5682C191.029 72.5682 190.486 72.7576 190.056 73.1491C189.627 73.528 189.375 74.0584 189.286 74.7278H193.895C193.781 73.288 193.036 72.5682 191.672 72.5682Z"
            fill="black"
          />
          <path
            d="M200.233 79.5901C199.21 79.5901 198.351 79.1986 197.657 78.4029C196.963 77.6073 196.622 76.6474 196.622 75.5108C196.622 74.3615 196.963 73.4017 197.657 72.606C198.351 71.823 199.21 71.4189 200.233 71.4189C201.47 71.4189 202.417 71.8988 203.086 72.846V68.1226H204.437V79.388H203.086V78.1377C202.417 79.1101 201.47 79.5901 200.233 79.5901ZM200.523 78.4282C201.243 78.4282 201.861 78.1503 202.341 77.582C202.834 77.0263 203.074 76.3317 203.074 75.5108C203.074 74.6899 202.834 73.9953 202.341 73.4396C201.849 72.8713 201.243 72.5934 200.523 72.5934C199.74 72.5934 199.134 72.8586 198.692 73.3764C198.25 73.8942 198.023 74.6141 198.023 75.5108C198.023 76.4075 198.25 77.1147 198.692 77.6451C199.134 78.1629 199.753 78.4282 200.523 78.4282Z"
            fill="black"
          />
          <path
            d="M204.551 10.596V14.7763H203.402V10.596H201.811V9.53516H206.154V10.596H204.551Z"
            fill="black"
          />
          <path
            d="M210.851 14.7763V11.3538L209.374 13.6018H209.349L207.884 11.379V14.7763H206.748V9.53516H207.998L209.374 11.7579L210.75 9.53516H212V14.789H210.851V14.7763Z"
            fill="black"
          />
        </svg>
      </a>
      <button
        class="navbar-toggler me-4"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" #collapse id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item" (click)="hideNavbar(collapse)" *ngIf="!userIsAuth">
            <a
              class="nav-link colorLink"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              [routerLink]="['/home']"
              >{{ 'Home' | translate }}</a
            >
          </li>
          <li class="nav-item" *ngIf="userIsAuth">
            <a
              class="nav-link colorLink"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              [routerLink]="['/user', 'welcome']"
            >
              {{ 'mySpaceText' | translate }}
            </a>
          </li>
          <li class="nav-item" (click)="hideNavbar(collapse)" *ngIf="!userIsAuth">
            <a
              class="nav-link colorLink"
              [routerLink]="['/espace-pi']"
              [routerLinkActive]="['text-purple', 'fw-bold']"
            >
              {{ 'EspacePI' | translate }}
            </a>
          </li>
          <li class="nav-item" (click)="hideNavbar(collapse)">
            <a
              class="nav-link colorLink"
              [routerLink]="['/services']"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              >{{ 'NosServices' | translate }}</a
            >
          </li>
          <li class="nav-item" (click)="hideNavbar(collapse)">
            <a
              class="nav-link colorLink"
              [routerLink]="['/rates']"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              >{{ 'Tarifs' | translate }}</a
            >
          </li>
          <li class="nav-item" (click)="hideNavbar(collapse)">
            <a
              class="nav-link colorLink"
              [routerLink]="['/become-mentor']"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              >{{ 'DevenirMentor' | translate }}</a
            >
          </li>
          <li class="nav-item" (click)="hideNavbar(collapse)">
            <a
              class="nav-link colorLink"
              href="https://blog.pi-link.fr/"
              >{{ 'Blog' | translate }}</a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle colorLink"
              href="#"
              id="navbarDropdownMenuApropos"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ 'Apropos' | translate }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuApropos">
              <li (click)="hideNavbar(collapse)">
                <a
                  class="dropdown-item colorLink"
                  [routerLink]="['/about-us']"
                  [routerLinkActive]="['text-purple', 'fw-bold']"
                  >{{ 'AproposDe' | translate }} PILINK</a
                >
              </li>
              <li (click)="hideNavbar(collapse)">
                <a
                  class="dropdown-item colorLink"
                  [routerLink]="['/contact-us']"
                  [routerLinkActive]="['text-purple', 'fw-bold']"
                  >{{ 'ContactezNous' | translate }}</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item" (click)="hideNavbar(collapse)">
            <a
              class="nav-link colorLink"
              [routerLink]="['/faq']"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              >FAQ</a
            >
          </li>

          <li class="nav-item" (click)="hideNavbar(collapse)">
            <a
              class="nav-link colorLink"
              href="https://youtube.com/playlist?list=PLFnZ5uy6YDnqXYuj5kOuR9oe6Ot7rxUfs&si=dTa0Hf3ABN0GYlPG"
              target="_blank"
              rel="noopener noreferrer"
              [routerLinkActive]="['text-purple', 'fw-bold']"
              >WEBINAIRES</a
            >
          </li>
        </ul>
        <div class="d-flex py-2 pe-lg-5">
          <a
            [routerLink]="['/authentication', 'login']"
            *ngIf="!userIsAuth"
            class="btn btn-login connexionBtn"
            >{{ 'Connexion' | translate }}</a
          >
          <a (click)="logout()" *ngIf="userIsAuth" class="btn btn-login connexionBtn">{{
            'Deconnexion' | translate
          }}</a>
        </div>
      </div>
    </div>
  </nav>
</div>
