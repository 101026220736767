<section *ngIf="resetLinkVerificationLoading" class="section section--cta">
  <h2 class="my-5 py-5 text-32 text-center" style="color: #6633cc">
    {{ 'ResetPwd.loadignText' | translate }}
  </h2>
</section>

<ng-container *ngIf="!resetLinkVerified && !resetLinkVerificationLoading">
  <div class="alert alert-danger w-75 mx-auto my-5 text-32 text-center">
    {{ 'ResetPwd.textError' | translate }}
  </div>
</ng-container>

<div *ngIf="resetLinkVerified">
  <div *ngIf="!validation" class="container-fluid main-resetpwd row justify-content-around">
    <form [formGroup]="resetForm" class="py-5" (ngSubmit)="resetPassword()">
      <div class="form-group row justify-content-around">
        <div class="col-4">
          <label style="font-size: 20px; text-align: center" class="textFormatLogin mt-5">
            <strong>{{ 'ResetPwd.labelNewPwdDirective' | translate }}</strong>
          </label>
          <br />
          <label for="inputPassword" class="mb-3 mt-3">{{
            'ResetPwd.labelNewPwdOfficial' | translate
          }}</label>
          <p class="form-label fw-light confirm_labek_type">
            {{ 'Register.form.CreatePwdTextDesc' | translate }} ($&+,:;=?@#|'>.-^*()%!£_)
          </p>
          <br />
          <mat-form-field class="w-100" appearance="outline">
            <input
              matInput
              formControlName="password"
              appShowHideInput
              type="password"
              id="inputPassword"
              required
            />
            <a class="changepwdstatus" (click)="toggleShowPwd()">
              <i
                [ngClass]="{
                  'bi bi-eye-fill': !showPwd,
                  'bi bi-eye-slash-fill': showPwd
                }"
              >
              </i>
            </a>
          </mat-form-field>
          <p *ngIf="submitted && f['password'].errors?.required" class="text-danger">
            {{ 'requiredFieldText' | translate }}
          </p>
          <p *ngIf="f['password'].errors?.pattern" class="text-danger">
            {{ 'PwdInvalidText' | translate }}
          </p>
        </div>
      </div>
      <br />
      <div class="pb-3 row justify-content-around form-group">
        <div class="col-4">
          <label class="textFormatLogin mb-3 mt-2">{{
            'ResetPwd.labelNewPwdConfirm' | translate
          }}</label>
          <br />
          <mat-form-field class="w-100" appearance="outline">
            <input
              matInput
              formControlName="newpassword"
              appShowHideInput
              type="password"
              required
            />
            <a class="changepwdstatus" (click)="toggleShowConfirmPwd()">
              <i
                [ngClass]="{
                  'bi bi-eye-fill': !showConfirmPwd,
                  'bi bi-eye-slash-fill': showConfirmPwd
                }"
              >
              </i>
            </a>
          </mat-form-field>
          <div class="form-control-feedback" *ngIf="submitted && f['newpassword'].dirty">
            <p *ngIf="resetForm.hasError('required', 'newpassword')" class="text-danger">
              {{ 'requiredFieldText' | translate }}
            </p>
            <p *ngIf="resetForm.hasError('notSame')" class="text-danger">
              {{ 'PwdDontMatchText' | translate }}
            </p>
          </div>
        </div>
      </div>
      <br />
      <div class="row justify-content-around">
        <div class="col-4">
          <button
            type="submit"
            [disabled]="loaderBtn"
            class="textFormatLogin btn bg-violet text-white"
          >
            {{ 'ConfirmText' | translate }} &nbsp;<span
              *ngIf="loaderBtn"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="validation">
    <section class="section sectionResetPasswordComponent section--cta">
      <img src="assets/img/Group 33.png" height="100" alt="Pilink" />
      <label class="mb-2 pt-3 mt-3" style="font-size: 28px; text-align: center"
        ><strong>Mot de passe réinitialisé avec succès</strong></label
      >
      <p style="width: 350px; text-align: center">
        Vous pouvez retourner sur la page de connexion pour vous connecter à nouveau
      </p>
      <a
        class="textFormatLogin p-10 btn bg-violet text-white btn-lg active"
        style="border-radius: 0"
        href="#"
        role="button"
        aria-pressed="true"
      >
        {{ 'DoLogin' | translate }}
      </a>
    </section>
  </div>
</div>
