<section class="container-fluid banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-8">
                <p class="banner-label text-60">
                    {{ 'HomeBannerLabel' | translate }}
                </p>
                <div class="row">
                    <div class="col-lg-10">
                        <p class="banner-welcome">
                            {{ 'HomeBannerWelcome' | translate }}
                        </p>
                    </div>
                </div>

                <div class="banner-actions">
                    <a [routerLink]="['/authentication', 'login']" class="btn btn-purple">{{ 'DoLogin' | translate }}</a> &nbsp; &nbsp;
                    <a [routerLink]="['/authentication', 'register']" class="btn btn-warning text-dark">{{ 'Register.text' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</section>
