<div class="container-fluid containerFluidEspacePi">
  <div class="container text-center pt-5">
    <p class="text-welcome text-60">{{ 'EspacePiTextWelcome' | translate }}</p>
    <div class="text-page-about-space">
      <p class="text-page-about">
        {{ 'EspacePiTextAbout' | translate }}
      </p>
    </div>
    <img src="assets/img/heroEspacePi.png" class="d-block mx-auto img-fluid w-25 pt-5" alt=""/>
  </div>
</div>
<div class="container-fluid tabEspacePi">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" [@.disabled]="true" mat-align-tabs="center" dynamicHeight>
    <a
      mat-tab-link
      *ngFor="let link of links"
      [routerLink]="[link.path]"
      (click)="activeLink = link.path"
      [active]="activeLink === link.path"
    >
      {{ link.text | translate }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <div class="container py-5">
    <router-outlet></router-outlet>
  </div>
  <div class="space-condition">
    <div class="let-start text-center mb-2">
      <p class="text-go text-60">{{ 'EspacePiTextGo' | translate }}</p>
      <p class="go-condition mb-5">{{ 'EspacePiGoCondition' | translate }}</p>
      <a [routerLink]="['/authentication', 'login']" class="btn btn-purple">{{
        'Subscribe' | translate
        }}</a>
    </div>
  </div>
</div>
